import { FC, useMemo } from "react";
import { Axis, ChartContext } from "@okopok/axes_context";
import { observer } from "mobx-react";

import { Loader } from "elements/loader";
import { useTechForecastModel } from "features/techForecast/useTechForecastModel";

import { META } from "../../../models/well/wellTechChartChannelsMeta";

import { LineBounds } from "./bounds/lineBounds";
import { Line } from "./elements/line";
import { LineDataModel } from "./elements/lineDataModel";

import cn from "./unusualChart.module.less";

const useAccessor = (fact: LineDataModel | null | undefined): ((v: number) => number) => {
  return useMemo(() => {
    const result = (v: number) => (fact?.x as number[])[v];
    result.invert = (v: number) => {
      const x = fact?.x as number[] | undefined;
      let i = 0;
      if (x === undefined) {
        return i;
      }
      while (i < x.length - 1 && Math.abs(x[i] - v) >= Math.abs(x[i + 1] - v)) {
        ++i;
      }
      return i;
    };
    return result;
  }, [fact]);
};

type UnusualChartProps = {
  curve: "lnVNF" | "recovery";
};

const LINES_MAP = {
  lnVNF: {
    x: "accumOilProdT",
    y: "lnVnfVol",
  },
  recovery: {
    x: "recoveryRate",
    y: "waterCutVol",
  },
} as const;

const UnusualChart: FC<UnusualChartProps> = observer(({ curve }) => {
  const model = useTechForecastModel();
  const chart = model.currentForecast.chart;
  const { x, y } = chart[`${curve}Domains`];
  const { fact, forecast } = chart[`${curve}Lines`];
  const { x: xLine, y: yLine } = LINES_MAP[curve];
  const axes = useMemo(
    () => [
      new Axis(META[LINES_MAP[curve].y].axis, "left", y, `${META[yLine].title}${curve === "recovery" ? ", %" : ""}`),
      new Axis("x", "bottom", x, `${META[xLine].title}, ${curve === "recovery" ? "%" : META[xLine].axis}`),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [model.currentForecast.status === "loading"]
  );
  const accessor = useAccessor(fact);

  return (
    <Loader wrapperClassName={cn.loader} tip={model.currentForecast.loaderTip} spinning={model.currentForecast.status === "loading"}>
      <ChartContext className={cn.chart} axes={axes}>
        {[fact, forecast].map((line) => line && <Line key={line.key} lineInfo={line} />)}
        {fact && <LineBounds xScale={axes[1].scale} yScale={axes[0].scale} accessor={accessor} lineInfo={fact} metric="waterCut" />}
      </ChartContext>
    </Loader>
  );
});

export { UnusualChart };
