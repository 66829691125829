import { FC, useMemo } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { TableContextProvider, TableModel, TableNode } from "@okopok/components/Table";
import { ColumnRaw } from "@okopok/components/Table/models/columns/store";
import { Table } from "@okopok/components/Table/Table";
import { Checkbox, DatePicker, Select } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { Dayjs } from "dayjs";
import dayjs from "dayjs";
import { observer } from "mobx-react";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { CsvSaver } from "elements/csvSaver/csvSaver";
import { debugColumns } from "elements/debugColumn/debugColumn";
import { Ellipsis } from "elements/ellipsis/ellipsis";
import { Format } from "elements/format/format";
import { DATE_FORMAT, formatDate, NUMBER_FORMAT } from "elements/format/format";
import { InputNumber } from "elements/inputs/inputNumber/inputNumber";
import { SelectStorable } from "elements/inputs/selectStorable/selectStorable";
import { ToolbarButton } from "elements/toolbarButton/toolbarButton";
import { ForecastMethod } from "features/techForecast/models/well/methods";
import { global } from "models/global";
import { useFact } from "models/project/fact/fact";
import { useForecast } from "models/project/fact/forecast/forecast";
import type { DRow, EventNode } from "models/project/fact/forecast/techPrediction/techPrediction";
import { Debet as DebetStore } from "models/project/fact/forecast/techPrediction/techPrediction";
import { useProject } from "models/project/project";
import { useTableSettings } from "models/tableSettings";
import { useDebetContext } from "pages/project/tech/debet/context";
import { getUserPermission } from "services/back/roles";
import { CURVES_TR } from "services/back/techForecast/request";

import { FilterDetails, StaticFilter } from "./filters/types";

import cn from "./debet.module.less";

const TECH_PREDICTION_DEBET_STATIC_FILTERS: StaticFilter<DRow, EventNode>[] = [
  {
    title: "Только строки без прогноза",
    predicate: (node) => node.forecastProduction === null,
  },
  {
    title: "Только строки с текущей добычей",
    predicate: (node) => (node.byStratums.oilRate ?? 0) > 0,
  },
];

const TECH_PREDICTION_DEBET_FILTER_MAP = {
  wellTitle: {
    type: "stringer",
    predicateFactory: (condition, value) => (eventNode) => {
      value = value.toLowerCase();
      const contains = eventNode.well.title.toLowerCase().includes(value);
      return condition === "contains" ? contains : !contains;
    },
  } as FilterDetails<"stringer", DRow, EventNode, string>,
  eventTitle: {
    type: "equal",
    predicateFactory: (condition, value) => (eventNode) => {
      const interventionTitleMatch = eventNode.intervention?.data.gtmTypeId === value;
      const baseProdMatch = eventNode.well.fond === "Base" && value === -1;
      const newProdMatch = eventNode.well.fond === "New" && value === -2;
      const isEqual = interventionTitleMatch || (eventNode.intervention === null && (baseProdMatch || newProdMatch));
      return condition === "eq" ? isEqual : !isEqual;
    },
    InputNode: ({ value, onChange }) => {
      return (
        <Select
          value={value}
          options={[
            ...(global.interventionsTypes.selector ?? []),
            { label: "Базовая добыча", value: -1 },
            { label: "Эксплуатационное бурение", value: -2 },
          ]}
          onSelect={(value) => onChange(value)}
          variant="borderless"
          popupMatchSelectWidth={false}
          labelRender={(label) => (
            <Ellipsis limit={20} position="after">
              {label.label}
            </Ellipsis>
          )}
        />
      );
    },
  } as FilterDetails<"equal", DRow, EventNode, number>,
  factIdleMonths: {
    type: "ordering",
    predicateFactory: (condition, value) => (eventNode) => {
      const idleMonths = eventNode.factualProductionFields.factIdleMonths;
      if (idleMonths === undefined || idleMonths === null || value === null) {
        return false;
      }
      switch (condition) {
        case "eq":
          return idleMonths === value;
        case "ne":
          return idleMonths !== value;
        case "ge":
          return idleMonths >= value;
        case "gt":
          return idleMonths > value;
        case "le":
          return idleMonths <= value;
        case "lt":
          return idleMonths < value;
      }
    },
    InputNode: ({ value, onChange }) => {
      return <InputNumber bordered value={value} onUpdate={onChange} />;
    },
  } as FilterDetails<"ordering", DRow, EventNode, number | null>,
  date: {
    type: "ordering",
    predicateFactory: (condition, value) => (eventNode) => {
      const date = eventNode.date;
      if (date === null) {
        return false;
      }
      switch (condition) {
        case "eq":
          return date === value;
        case "ne":
          return date !== value;
        case "ge":
          return date >= value;
        case "gt":
          return date > value;
        case "le":
          return date <= value;
        case "lt":
          return date < value;
      }
    },
    InputNode: ({ value, onChange }) => {
      return <DatePicker value={value} onChange={onChange} />;
    },
  } as FilterDetails<"ordering", DRow, EventNode, Dayjs>,
  wellType: {
    type: "equal",
    predicateFactory: (condition, value) => (eventNode) => {
      const isEqual = eventNode.well.data.wellTypeId === value;
      return condition === "eq" ? isEqual : !isEqual;
    },
    InputNode: ({ value, onChange }) => {
      return <Select value={value} options={global.wellTypes.selector} onSelect={(value) => onChange(value)} variant="borderless" />;
    },
  } as FilterDetails<"equal", DRow, EventNode, number | undefined>,
  wellStatus: {
    type: "equal",
    predicateFactory: (condition, value) => (eventNode) => {
      const isEqual = eventNode.wellStatus === value;
      return condition === "eq" ? isEqual : !isEqual;
    },
    InputNode: ({ value, onChange }) => {
      const options: Array<DRow["wellStatus"]> = ["Добывающая", "Нагнетательная", "Прочего назначения"];
      return (
        <Select value={value} options={options.map((v) => ({ label: v, value: v }))} onSelect={(value) => onChange(value)} variant="borderless" />
      );
    },
  } as FilterDetails<"equal", DRow, EventNode, DRow["wellStatus"]>,
  fond: {
    type: "equal",
    predicateFactory: (condition, value) => (eventNode) => {
      const isEqual = eventNode.well.fond === value;
      return condition === "eq" ? isEqual : !isEqual;
    },
    InputNode: ({ value, onChange }) => {
      return (
        <Select
          value={value}
          options={["Base", "New"].map((v) => ({ label: v === "Base" ? "Базовый" : "Новый", value: v }))}
          onSelect={(value) => onChange(value)}
          variant="borderless"
        />
      );
    },
  } as FilterDetails<"equal", DRow, EventNode, "Base" | "New">,
  licenseRegion: {
    type: "equal",
    predicateFactory: (condition, value) => (eventNode) => {
      const isEqual = eventNode.well.licenseRegionId === value;
      return condition === "eq" ? isEqual : !isEqual;
    },
    InputNode: ({ value, onChange }) => {
      const licenseRegions = useProject()!.licenseRegions;
      const options = licenseRegions.selector;
      return (
        <Select
          value={value}
          disabled={options === undefined}
          options={options}
          onSelect={(value) => onChange(value)}
          allowClear
          onClear={() => onChange(undefined)}
          variant="borderless"
        />
      );
    },
  } as FilterDetails<"equal", DRow, EventNode, number | undefined>,
  wellPad: {
    type: "equal",
    predicateFactory: (condition, value) => (eventNode) => {
      const isEqual = eventNode.well.data.mineId === value;
      return condition === "eq" ? isEqual : !isEqual;
    },
    InputNode: ({ value, onChange }) => {
      const pads = useFact()!.wellPads;
      const options = pads.selector;
      return (
        <Select
          value={value}
          disabled={options === undefined}
          options={options}
          onSelect={(value) => onChange(value)}
          allowClear
          onClear={() => onChange(undefined)}
          variant="borderless"
        />
      );
    },
  } as FilterDetails<"equal", DRow, EventNode, number | undefined>,
  stratum: {
    type: "equal",
    predicateFactory: (condition, value) => (eventNode) => {
      const isEqual = eventNode.well.data.stratumId === value;
      return condition === "eq" ? isEqual : !isEqual;
    },
    InputNode: ({ value, onChange }) => {
      const stratums = useFact()!.stratums;
      const options = stratums.selector;
      return (
        <Select
          value={value}
          disabled={options === undefined}
          options={options}
          onSelect={(value) => onChange(value)}
          allowClear
          onClear={() => onChange(undefined)}
          variant="borderless"
        />
      );
    },
  } as FilterDetails<"equal", DRow, EventNode, number | undefined>,
  producingObject: {
    type: "equal",
    predicateFactory: (condition, value) => (eventNode) => {
      const isEqual = eventNode.producingObject?.id === value;
      return condition === "eq" ? isEqual : !isEqual;
    },
    InputNode: ({ value, onChange }) => {
      const producingObjects = useFact()!.producingObjects;
      return <SelectStorable values={[value, undefined]} store={producingObjects} onChange={onChange} variant="outlined" />;
    },
  } as FilterDetails<"equal", DRow, EventNode, number | null>,
};

const DeleteButton: FC<{ store: DebetStore }> = observer(({ store }) => {
  const forecast = useForecast()!;

  const project = useProject()!;
  const edit = getUserPermission(project)["tech"];
  if (!edit) {
    return null;
  }

  const isNotSelected = store.selectedEvents.length === 0;
  const isNotTechParameters = !store.selectedEvents.some(({ forecastSettings, forecastProduction }) => forecastSettings || forecastProduction);

  const confirm = async () => {
    const wellIdsInfo = store.selectedEvents.map(({ well: { id }, stratumId, intervention }) => ({
      wellId: id,
      gtmId: intervention?.id ?? null,
      stratumId,
    }));
    forecast.techForecastSettings.delete(wellIdsInfo);
  };

  const tooltip = isNotSelected
    ? "Ни одна скважина/ГТМ не выбрана"
    : isNotTechParameters
    ? "Ни для одной из выбранных скважин/ГТМ нет прогноза"
    : "Удалить прогноз у выбранных скважин/ГТМ";

  return (
    <ToolbarButton
      tooltip={{ title: tooltip }}
      popconfirm={{
        title: "Удаление настроек",
        description: "Удалить прогноз для выбранных скважин/ГТМ?",
        onConfirm: confirm,
        onCancel: () => "",
        okText: "Да",
        cancelText: "Нет",
      }}
      disabled={isNotSelected || isNotTechParameters}
      icon={<DeleteOutlined />}
      danger
    />
  );
});

const COLUMNS: ColumnRaw<DRow>[] = [
  ...debugColumns<DRow>([
    {
      dataKey: "wellId",
      title: "ID скважины",
      width: 80,
    },
    {
      dataKey: "gtmId",
      title: "ID ГТМ",
      width: 80,
    },
  ]),
  {
    dataKey: "wellTitle",
    title: "Скважина",
    isSticky: true,
    width: 160,
  },
  {
    dataKey: "eventTitle",
    title: "Мероприятие",
    isSticky: true,
    width: 260,
  },
  {
    dataKey: "date",
    title: "Дата мероприятия",
    width: 160,
    render: (value: Dayjs | null | undefined) => value && <Format>{value}</Format>,
    renderToString: (value: Dayjs | null | undefined) => {
      if (!value) return null;
      return formatDate(dayjs(value), DATE_FORMAT.generic);
    },
  },
  {
    dataKey: "wellType",
    title: "Тип заканчивания",
    width: 160,
  },
  {
    dataKey: "wellStatus",
    title: "Назначение",
    width: 160,
    render: (value: string | undefined, { expand }) => (expand === undefined ? <Format>{value}</Format> : null),
    renderToString: (value) => value ?? null,
  },
  {
    dataKey: "factIdleMonths",
    title: "Кол-во месяцев в простое",
    width: 160,
    render: (value: number | null | undefined, { expand }) => (expand === undefined ? <Format>{value}</Format> : null),
    renderToString: (value) => value ?? null,
  },
  {
    dataKey: "wellPad",
    title: "Куст",
    width: 160,
  },
  {
    dataKey: "fond",
    title: "Фонд",
    width: 160,
  },
  {
    dataKey: "licenseRegion",
    title: "ЛУ",
    width: 160,
  },
  {
    dataKey: "producingObject",
    title: "Объект разработки",
    width: { min: 160, max: 440, competitiveness: 1 },
  },
  {
    title: "Залежь",
    dataKey: "stratum",
    width: { min: 200, max: 300, competitiveness: 1 },
    onCell: () => ({ className: cn.remove }),
    onHeaderCell: () => ({ className: cn.tableHeader }),
    render: (value: string) => {
      if (value === undefined) {
        return null;
      }

      return (
        <Ellipsis limit={30} position="mid">
          <Format>{value}</Format>
        </Ellipsis>
      );
    },
    renderToString: (value: string) => value ?? null,
  },
  // {
  //   dataKey: "stopCriterion",
  //   title: "Достигнутый критерий остановки",
  //   width: 260,
  //   render: (value) => value && <AnnotatedValue {...value} />
  // },
  {
    dataKey: "operationCoef",
    title: "Коэф-т эксплуатации",
    width: 160,
    render: (coef: number | null | undefined, { expand }) => (expand === undefined ? <Format>{coef}</Format> : null),
    renderToString: (coef: any | null | undefined) => coef ?? null,
  },
  {
    dataKey: "liquidDebitMethod",
    title: "Способ расчета дебита жидкости",
    width: 260,
    render: (method: ForecastMethod | null | undefined, { expand }) =>
      expand === undefined ? method ? CURVES_TR[method] : <Format>{method}</Format> : null,
  },
  {
    dataKey: "oilDebitMethod",
    title: "Способ расчета дебита нефти",
    width: 260,
    render: (method: ForecastMethod | null | undefined, { expand }) =>
      expand === undefined ? method ? CURVES_TR[method] : <Format>{method}</Format> : null,
  },
  {
    dataKey: "liquidRate",
    title: "Стартовый дебит жид-ти, м³/сут",
    width: 260,
    render: (value) => value && <Format>{value}</Format>,
    renderToString: (value) => {
      if (!value) return null;

      return NUMBER_FORMAT.real_3.format(value);
    },
  },
  {
    dataKey: "oilRate",
    title: "Стартовый дебит нефти, т",
    width: 260,
    render: (value) => value && <Format>{value}</Format>,
    renderToString: (value) => {
      if (!value) return null;

      return NUMBER_FORMAT.real_3.format(value);
    },
  },
  {
    dataKey: "waterCut",
    title: "Стартовая обводненность, %",
    width: 260,
    render: (value) => value && <Format>{value}</Format>,
    renderToString: (value) => {
      if (!value) return null;

      return NUMBER_FORMAT.real_3.format(value);
    },
  },
  {
    dataKey: "accumLiquid",
    title: "Накопленная добыча жид-ти, тыс. м³",
    width: 270,
    render: (value: number | null | undefined, { expand }) => (expand === undefined ? <Format>{value}</Format> : null),
    renderToString: (value) => {
      if (!value) return null;

      return NUMBER_FORMAT.real_2.format(value);
    },
  },
  {
    dataKey: "accumOil",
    title: "Накопленная добыча нефти, тыс. т",
    width: 260,
    render: (value: number | null | undefined, { expand }) => (expand === undefined ? <Format>{value}</Format> : null),
    renderToString: (value) => {
      if (!value) return null;

      return NUMBER_FORMAT.real_2.format(value);
    },
  },
  {
    dataKey: "recoverableResourcesStart",
    title: "Нач. извлекаемые запасы, тыс т",
    width: 260,
    render: (value: number | null | undefined, { expand }) => (expand === undefined ? <Format>{value}</Format> : null),
    renderToString: (value) => {
      if (!value) return null;

      return NUMBER_FORMAT.real_2.format(value);
    },
  },
  {
    dataKey: "recoverableResourcesEnd",
    title: "Ост. извлекаемые запасы, тыс т",
    width: 260,
    render: (value: number | null | undefined, { expand }) => (expand === undefined ? <Format>{value}</Format> : null),
    renderToString: (value) => {
      if (!value) return null;

      return NUMBER_FORMAT.real_2.format(value);
    },
    onCell: ({ value }) => ({ style: { backgroundColor: (value?.recoverableResourcesEnd ?? 0) < 0 ? "pink" : undefined } }),
  },
  {
    dataKey: "recoverableResourcesRatio",
    title: "Отбор от НИЗ, %",
    width: 260,
    render: (value: number | null | undefined, { expand }) => (expand === undefined ? <Format>{value}</Format> : null),
    renderToString: (value) => {
      if (!value) return null;

      return NUMBER_FORMAT.real_2.format(value);
    },
  },
];

const SelectAll = observer(({ store }: { store: TableNode<DRow, any> }) => {
  const { selectManager } = store;
  const onChange = (event: CheckboxChangeEvent) => {
    if (event.target.checked) {
      selectManager?.propagateSelected();
    } else {
      selectManager?.propagateDeselected();
    }
  };
  return <Checkbox indeterminate={selectManager?.isPartiallySelected} checked={selectManager?.isSelected} onChange={onChange} />;
});

const Debet = observer(() => {
  const store = useDebetContext();

  const model = useMemo(() => {
    const cols = [
      {
        key: "selectInfo",
        title: <SelectAll store={store} />,
        width: 34,
        isSticky: true,
        isExported: false,
        render: (_, { select }) => {
          if (select === undefined) return undefined;
          const onChange = (event: CheckboxChangeEvent) => {
            if (event.target.checked) {
              select.onSelect();
            } else {
              select.onDeselect();
            }
          };
          return <Checkbox indeterminate={select.status === "partiallySelected"} checked={select.status === "selected"} onChange={onChange} />;
        },
      } as ColumnRaw<DRow>,
      ...COLUMNS,
    ];
    return new TableModel(
      cols,
      store,
      {
        onRow: ({ indexPath, expand }) => ({
          className: expand === undefined ? cn.tableRowPlain : indexPath.length === 1 ? cn.tableRowPrimary : cn.tableRowSecondary,
        }),
      },
      {
        headerHeight: 39,
        rowHeight: 33,
        borderColor: "#f0f0f0",
      }
    );
  }, [store]);
  useTableSettings(model, "well_debet");

  return (
    <CsvSaver filename="Прогноз добычи" exportArray={() => model.export()}>
      <div className={cn.root}>
        <TableContextProvider value={model}>
          <PageFrameTitlePortal filter={store.filterManager} onFilterChange={store.applyFilters}>
            <DeleteButton store={store} />
          </PageFrameTitlePortal>
          <Table headerClassName={cn.tableHeader} className={cn.table} />
        </TableContextProvider>
      </div>
    </CsvSaver>
  );
});

export { Debet, TECH_PREDICTION_DEBET_FILTER_MAP, TECH_PREDICTION_DEBET_STATIC_FILTERS };
