/* eslint-disable no-console */
import { FC } from "react";
import { Button, Checkbox, Typography } from "antd";
import { observer } from "mobx-react";

import { Format } from "elements/format/format";
import { Icon } from "elements/icon/icon";
import { Loader } from "elements/loader";
import { useTechForecastModel } from "features/techForecast/useTechForecastModel";

import { ReactComponent as AllChecks } from "../../icons/allChecks.svg";
import { ReactComponent as CheckList } from "../../icons/checkList.svg";
import { ValidationInput } from "../legacyInputs/validationInput";

import { useAnalogSelector } from "./useWellsAnalogSelector";

import cn from "./wellsAnalog.module.less";

const WellsAnalog: FC<{ mode: "oil" | "liquid" }> = observer(({ mode }) => {
  console.assert(
    mode === "oil" || mode === "liquid",
    `Для скважин-аналогов система построения запроса реализована только для нефти и жидкости ${mode}`
  );
  const model = useTechForecastModel();
  const { analogs } = model.currentForecast.settings[mode];
  const { amount, selectedAmount } = analogs;
  const selectAnalogs = useAnalogSelector(analogs);
  return (
    <Loader spinning={analogs.isLoading}>
      <div className={cn.buttons}>
        <Button icon={<Icon viewBox="0 0 16 16" content={<CheckList />} />} size="small" onClick={selectAnalogs}>
          Выбрать аналоги
        </Button>
        <Button
          icon={<Icon viewBox="0 0 16 16" content={<AllChecks />} />}
          size="small"
          onClick={analogs.selectAllHolder}
          disabled={amount === selectedAmount}
        >
          Прогноз по всем аналогам
        </Button>
      </div>
      <Typography.Text className={cn.amount}>
        Выбрано <Format>{analogs.selectedAmount}</Format> из <Format>{analogs.amount}</Format> скважин аналогов
      </Typography.Text>
      <div className={cn.grid}>
        <Typography.Text className={cn.durationTitle}>Минимальная длительность работы скважины на объекте разработки</Typography.Text>
        <ValidationInput value={analogs.prodMonthDuration} onSave={analogs.holder("prodMonthDuration")} min={0} max={999} />
        <Typography.Text>
          Учитывать темп падения q<sub>н</sub>
        </Typography.Text>
        <ValidationInput value={analogs.oilRatio} onSave={analogs.holder("oilRatio")} min={0} max={999} />
        <ValidationInput value={analogs.oilRatioDiv} onSave={analogs.holder("oilRatioDiv")} min={0} max={999} />
        <Typography.Text>
          Учитывать темп падения q<sub>ж</sub>
        </Typography.Text>
        <ValidationInput value={analogs.liquidRatio} onSave={analogs.holder("liquidRatio")} min={0} max={999} />
        <ValidationInput value={analogs.liquidRatioDiv} onSave={analogs.holder("liquidRatioDiv")} min={0} max={999} />
      </div>
      <Checkbox checked={analogs.isApplyConstruction} onChange={({ target }) => analogs.applyConstructionHolder(target.checked)}>
        Учитывать тип конструкции скважины
      </Checkbox>
    </Loader>
  );
});

export { WellsAnalog };
