import { createContext, ReactNode, useContext } from "react";

import { CursorTooltipModel } from "./cursorTooltip/cursorTooltipModel";
import { WrapTooltipModel } from "./wrapTooltip/wrapTooltipModel";
abstract class TooltipModel {
  abstract content: ReactNode | null;
  lastId: number | null = null;
  coords: [["left" | "right", number], ["top" | "bottom", number]] | undefined;

  abstract onContentUpdate: (
    content: ReactNode | null,
    coords: [["left" | "right", number], ["top" | "bottom", number]]
  ) => void;
}

const TooltipContext = createContext<WrapTooltipModel | CursorTooltipModel | null>(null);

const useTooltipContext = (): WrapTooltipModel | CursorTooltipModel | null => {
  const context = useContext(TooltipContext);
  return context;
};

export { TooltipContext, TooltipModel, useTooltipContext };
