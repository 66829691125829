import { FC, useMemo } from "react";
import { useMatches } from "react-router";
import { CopyOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { TableContextProvider, TableModel, Widget } from "@okopok/components/Table";
import type { ColumnRaw } from "@okopok/components/Table/models/columns/store";
import { ExpandButton } from "@okopok/components/Table/widgets/ExpandButton/ExpandButton";
import { Button, Input, Tooltip, UploadFile } from "antd";
import { DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/ru_RU";
import dayjs from "dayjs";
import { runInAction } from "mobx";
import { observer } from "mobx-react";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { CsvSaver } from "elements/csvSaver/csvSaver";
import { debugColumns } from "elements/debugColumn/debugColumn";
import { DeleteButton } from "elements/deleteButton/deleteButton";
import { Ellipsis } from "elements/ellipsis/ellipsis";
import { EmptyScreen } from "elements/emptyScreen";
import { Format } from "elements/format/format";
import { DATE_FORMAT, formatDate, NUMBER_FORMAT } from "elements/format/format";
import { Icon } from "elements/icon/icon";
import { Plus } from "elements/icons/plus";
import { useFallBack } from "elements/importModalContent/useFallBack";
import { EditableFallbackDisplay } from "elements/inputs/editableFallbackDisplay/editableFallbackDisplay";
import { InputNumber } from "elements/inputs/inputNumber/inputNumber";
import { SelectStorable } from "elements/inputs/selectStorable/selectStorable";
import { ModeSelector } from "elements/modeSelector/modeSelector";
import { ModeSelectorModel } from "elements/modeSelector/modeSelectorModel";
import { global } from "models/global";
import { useFact } from "models/project/fact/fact";
import { useForecast } from "models/project/fact/forecast/forecast";
import type { DRow } from "models/project/fact/well/list/listFlat";
import { WellsList as WellsListModel } from "models/project/fact/well/list/listFlat";
import { Well } from "models/project/fact/well/well";
import { useTableSettings } from "models/tableSettings";
import { conditionallyArr } from "utils/conditionally";

import "dayjs/locale/ru";

import { ReactComponent as UploadIcon } from "./icons/uploadIcon.svg";
import { useImportWellsModal } from "./importWellsModal";

import cn from "./wellsList.module.less";

const WellTitleWarningSuffix: FC<{ isPreview: boolean; rowData: DRow }> = ({ isPreview, rowData }) => {
  let tooltip: string | undefined;
  if (isPreview && typeof rowData?.wellId === "number") {
    tooltip = "Скважина уже существует в проекте. Данные не будут импортированы до её удаления";
  } else if (rowData.isDuplicatedWell) {
    tooltip = `Скважина с таким названием уже упоминается ранее. Данные не будут ${isPreview ? "импортированы" : "сохранены"}`;
  }
  return tooltip ? (
    <Tooltip title={tooltip}>
      <ExclamationCircleOutlined style={{ color: "#faad14" }} />
    </Tooltip>
  ) : null;
};

const useColumns = (editable: boolean = false, isPreviewColumns: boolean = false, modeModel: ModeSelectorModel | null = null): ColumnRaw<DRow>[] => {
  const fact = useFact()!;
  const forecast = useForecast() ?? null;
  const getFallback = useFallBack(forecast, fact);

  return useMemo(
    (): ColumnRaw<DRow>[] => [
      ...debugColumns<DRow>([
        {
          dataKey: "wellId",
          title: "ID",
          width: 50,
        },
      ]),
      {
        key: "index",
        title: "No.пп",
        width: 52,
        isSticky: true,
        render: (_, { absoluteIndex, indexPath }) => (indexPath[0] === -1 ? <div /> : <div>{absoluteIndex ?? indexPath[0] + 1 ?? null}</div>),
      },
      {
        key: "expand",
        title: <div />,
        isSticky: true,
        isExported: false,
        width: { min: 34, max: 34, competitiveness: 1 },
        render: (_, tableItem) => <ExpandButton expand={tableItem.expand} />,
      },
      {
        title: "Скважина",
        dataKey: "title",
        isSticky: true,
        width: { min: 250, max: 350, competitiveness: 1 },
        onCell: () => (isPreviewColumns ? { style: { padding: 0 } } : {}),
        render: (title, { update, expand, indexPath, value: wellData }) => {
          if (expand !== undefined || !editable || indexPath[0] === -1) {
            return <Format>{title}</Format>;
          }
          return (
            <Input
              value={title}
              onChange={(event) => update?.("title", event.currentTarget.value)}
              width="100%"
              variant={"borderless"}
              suffix={wellData && <WellTitleWarningSuffix isPreview={isPreviewColumns} rowData={wellData} />}
              allowClear={false}
            />
          );
        },
        renderToString(title) {
          return typeof title === "number" ? NUMBER_FORMAT.real_4.format(title) : title;
        },
      },
      {
        title: "Куст",
        dataKey: "mine",
        width: { min: 120, max: 180, competitiveness: 1 },
        onCell: () => (isPreviewColumns ? { style: { padding: 0 } } : {}),
        render: (value: [number | null, string?] | undefined, { update }) => {
          if (value === undefined) {
            return null;
          }
          const [id, title] = value;
          const wellPads = (forecast ?? fact).wellPads;
          if (!editable) {
            return id !== null ? wellPads.at(id)?.title ?? null : null;
          }
          return (
            <SelectStorable
              values={[id, title]}
              notFoundContent={
                <Button
                  block
                  onClick={() => {
                    if (!title) {
                      return;
                    }
                    const newPad = wellPads.createPad(title);
                    newPad && update?.("mine", [newPad.id, newPad.title]);
                  }}
                >
                  Создать
                </Button>
              }
              store={wellPads}
              setValues={(id, title) => {
                update?.("mine", [id, title]);
                if (id) {
                  const lzFinder = ({ licenseRegionId, mineId }: Well) => mineId === id && licenseRegionId !== null && licenseRegionId !== undefined;
                  const lzSelected = fact.wells.wells.find(lzFinder) ?? forecast?.wells.wells.find(lzFinder);
                  if (lzSelected) {
                    update?.("licenseZone", [lzSelected.licenseRegionId, lzSelected.licenseRegion?.title]);
                  }
                }
              }}
            />
          );
        },
        renderToString(value: [number | null, string?] | undefined) {
          if (!value) return null;

          const [id] = value;
          const wellPads = (forecast ?? fact).wellPads;
          const padTitle = id !== null ? wellPads.at(id)?.title ?? null : null;

          return typeof padTitle === "number" ? NUMBER_FORMAT.real_4.format(padTitle) : padTitle;
        },
      },
      {
        title: "Объект разработки",
        dataKey: "producingObject",
        width: { min: 160, max: 300, competitiveness: 1 },
        onCell: () => (isPreviewColumns ? { style: { padding: 0 } } : {}),
        render: (value: [number | null, string?] | undefined, { update }) => {
          if (value === undefined) {
            return null;
          }
          const [id, title] = value;
          const prodObj = id ? fact.producingObjects.at(id) : null;
          if (!editable) {
            return (
              <Ellipsis limit={30} position="mid">
                <Format>{id !== null ? prodObj?.title : null}</Format>
              </Ellipsis>
            );
          }
          return (
            <EditableFallbackDisplay isWrapper={!id} isImport={isPreviewColumns} {...getFallback(id || title, "producingObject")}>
              <SelectStorable
                values={[id, title]}
                store={fact.producingObjects}
                setValues={(id: number | null, title?: string) => update?.("producingObject", [id, title])}
              />
            </EditableFallbackDisplay>
          );
        },
        renderToString: (value: [number | null, string?] | undefined) => {
          if (!value) return null;

          const [id] = value;
          const prodObj = id ? fact.producingObjects.at(id) : null;
          const prodObjTitle = id !== null ? prodObj?.title : null;

          return prodObjTitle || null;
        },
      },
      ...conditionallyArr<ColumnRaw<DRow>>(!isPreviewColumns, {
        title: "Залежь",
        dataKey: "stratum",
        width: { min: 200, max: 300, competitiveness: 1 },
        render: (value: [number | null, string?] | undefined, { update }) => {
          if (value === undefined) {
            return null;
          }
          const [id, title] = value;
          const stratum = id ? fact.stratums.at(id) : null;
          if (!editable) {
            return (
              <Ellipsis limit={30} position="mid">
                <Format>{id !== null ? stratum?.title : null}</Format>
              </Ellipsis>
            );
          }
          return (
            <SelectStorable
              disabled
              values={[id, title]}
              store={fact.stratums}
              setValues={(id: number | null, title?: string) => update?.("stratum", [id, title])}
            />
          );
        },
        renderToString: (value: [number | null, string?] | undefined) => {
          if (!value) return null;

          const [id] = value;
          const stratum = id ? fact.stratums.at(id) : null;

          return stratum?.title || null;
        },
      }),
      {
        title: "ЛУ",
        dataKey: "licenseZone",
        width: { min: 180, max: 300, competitiveness: 1 },
        render: (val: [number | null, string?] | undefined, { value, update }) => {
          if (value === undefined || value.mine === undefined) {
            return null;
          }
          const [mineId] = value.mine;
          if (mineId === null) {
            return null;
          }
          const mine = (forecast ?? fact).wellPads.at(mineId);
          if (!mine || val === undefined) {
            return null;
          }
          const [lrId, lrTitle] = val;
          const lr = lrId === null ? null : global.licenseRegions.at(lrId);
          if (!editable) {
            return lr?.title ?? null;
          }
          return (
            <SelectStorable
              values={[lr?.id ?? null, lrTitle]}
              disabled={!mine.isUnsaved}
              store={global.licenseRegions}
              setValues={(id) =>
                id &&
                mine.isUnsaved &&
                runInAction(() => {
                  update?.("licenseZone", [id, global.licenseRegions.at(id)?.title]);
                })
              }
            />
          );
        },
        renderToString: (val: [number | null, string?] | undefined) => {
          if (!val) return null;

          const [lrId] = val;
          const lr = lrId === null ? null : global.licenseRegions.at(lrId);

          return lr?.title ?? null;
        },
      },
      {
        title: "Тип заканчивания",
        dataKey: "type",
        width: { min: 160, max: 250, competitiveness: 1 },
        onCell: () => (isPreviewColumns ? { style: { padding: 0 } } : {}),
        render: (value, { update }) => {
          const title = value ? global.wellTypes.at(value[0]!)?.title : null;
          const type = value ? (value[0] ? value[0] : value[1]) : null;
          if (value === undefined) {
            return null;
          }
          if (!editable) {
            return value[0] !== null ? title : null;
          }
          return (
            <EditableFallbackDisplay isImport={isPreviewColumns} {...getFallback(type, "type")} isWrapper={!value[0]}>
              <SelectStorable values={value} store={global.wellTypes} setValues={(id, title) => update?.("type", [id, title])} />
            </EditableFallbackDisplay>
          );
        },
        renderToString: (value) => {
          if (!value) return null;
          const title = value ? global.wellTypes.at(value[0]!)?.title : null;
          return title || null;
        },
      },
      {
        title: `Устье X, м`,
        dataKey: "topX",
        width: 100,
        onCell: () => ({ className: cn.tableCellAlignRight, ...(isPreviewColumns ? { style: { padding: 0 } } : {}) }),
        render: (value, { update, indexPath }) =>
          indexPath[0] !== -1 ? (
            editable ? (
              <EditableFallbackDisplay isImport={isPreviewColumns} {...getFallback(value)}>
                <InputNumber variant="borderless" min={0} value={value} onUpdate={(value) => update?.("topX", value)} />
              </EditableFallbackDisplay>
            ) : (
              <Format>{value}</Format>
            )
          ) : null,
        renderToString: (value) => {
          if (!value) return null;

          return NUMBER_FORMAT.real_2.format(value);
        },
      },
      {
        title: `Устье Y, м`,
        dataKey: "topY",
        width: 100,
        onCell: () => ({ className: cn.tableCellAlignRight, ...(isPreviewColumns ? { style: { padding: 0 } } : {}) }),
        render: (value, { update, indexPath }) =>
          indexPath[0] !== -1 ? (
            editable ? (
              <EditableFallbackDisplay isImport={isPreviewColumns} {...getFallback(value)}>
                <InputNumber variant="borderless" min={0} value={value} onUpdate={(value) => update?.("topY", value)} />
              </EditableFallbackDisplay>
            ) : (
              <Format>{value}</Format>
            )
          ) : null,
        renderToString: (value) => {
          if (!value) return null;

          return NUMBER_FORMAT.real_2.format(value);
        },
      },
      {
        title: `Забой X, м`,
        dataKey: "botX",
        width: 100,
        onCell: () => ({ className: cn.tableCellAlignRight, ...(isPreviewColumns ? { style: { padding: 0 } } : {}) }),
        render: (value, { update, indexPath }) =>
          indexPath[0] !== -1 ? (
            editable ? (
              <EditableFallbackDisplay isImport={isPreviewColumns} {...getFallback(value)}>
                <InputNumber variant="borderless" min={0} value={value} onUpdate={(value) => update?.("botX", value)} />
              </EditableFallbackDisplay>
            ) : (
              <Format>{value}</Format>
            )
          ) : null,
        renderToString: (value) => {
          if (!value) return null;

          return NUMBER_FORMAT.real_2.format(value);
        },
      },
      {
        title: `Забой Y, м`,
        dataKey: "botY",
        width: 100,
        onCell: () => ({ className: cn.tableCellAlignRight, ...(isPreviewColumns ? { style: { padding: 0 } } : {}) }),
        render: (value, { update, indexPath }) =>
          indexPath[0] !== -1 ? (
            editable ? (
              <EditableFallbackDisplay isImport={isPreviewColumns} {...getFallback(value)}>
                <InputNumber variant="borderless" min={0} value={value} onUpdate={(value) => update?.("botY", value)} />
              </EditableFallbackDisplay>
            ) : (
              <Format>{value}</Format>
            )
          ) : null,
        renderToString: (value) => {
          if (!value) return null;

          return NUMBER_FORMAT.real_2.format(value);
        },
      },
      {
        title: `Стартовый дебит жид-ти, м³/сут`,
        dataKey: "liquidRate",
        width: 160,
        onCell: () => ({ className: cn.tableCellAlignRight, ...(isPreviewColumns ? { style: { padding: 0 } } : {}) }),
        render: (liquidRate, { update, indexPath, value }) =>
          editable && indexPath[0] !== -1 ? (
            <EditableFallbackDisplay isImport={isPreviewColumns} {...getFallback(liquidRate)}>
              <InputNumber
                disabled={"liquidRate" === modeModel?.mode || typeof value?.producingObject?.[0] !== "number"}
                variant="borderless"
                min={0}
                value={liquidRate}
                onUpdate={(value) => update?.("liquidRate", value)}
              />
            </EditableFallbackDisplay>
          ) : (
            <Format>{liquidRate}</Format>
          ),
        renderToString: (liquidRate) => {
          if (!liquidRate) return null;

          return NUMBER_FORMAT.real_3.format(liquidRate);
        },
      },
      {
        title: `Стартовый дебит нефти, т/сут`,
        dataKey: "oilRate",
        width: 160,
        onCell: () => ({ className: cn.tableCellAlignRight, ...(isPreviewColumns ? { style: { padding: 0 } } : {}) }),
        render: (oilRate, { update, indexPath, value }) =>
          editable && indexPath[0] !== -1 ? (
            <EditableFallbackDisplay isImport={isPreviewColumns} {...getFallback(oilRate)}>
              <InputNumber
                disabled={"oilRate" === modeModel?.mode || typeof value?.producingObject?.[0] !== "number"}
                variant="borderless"
                min={0}
                value={oilRate}
                onUpdate={(value) => update?.("oilRate", value)}
              />
            </EditableFallbackDisplay>
          ) : (
            <Format>{oilRate}</Format>
          ),
        renderToString: (oilRate) => {
          if (!oilRate) return null;

          return NUMBER_FORMAT.real_3.format(oilRate);
        },
      },
      {
        title: !editable ? "ОИЗ, тыс т" : "Извлекаемые запасы, тыс т",
        dataKey: "recoverableResources",
        width: 160,
        onCell: () => ({ className: cn.tableCellAlignRight, ...(isPreviewColumns ? { style: { padding: 0 } } : {}) }),
        render: (value, { update, indexPath }) =>
          indexPath[0] !== -1 ? (
            <EditableFallbackDisplay isImport={isPreviewColumns} {...getFallback(value)}>
              <InputNumber variant="borderless" min={0} value={value} onUpdate={(value) => update?.("recoverableResources", value)} />
            </EditableFallbackDisplay>
          ) : (
            <Format>{value}</Format>
          ),
        renderToString: (value) => {
          if (!value) return null;

          return NUMBER_FORMAT.real_3.format(value);
        },
      },
      ...conditionallyArr<ColumnRaw<DRow>>(!isPreviewColumns, {
        title: "Обводненность, %",
        dataKey: "waterCut",
        width: 160,
        onCell: () => ({ className: cn.tableCellAlignRight, ...(isPreviewColumns ? { style: { padding: 0 } } : {}) }),
        render: (waterCut, { update, indexPath, value }) =>
          editable && indexPath[0] !== -1 ? (
            <EditableFallbackDisplay isImport={isPreviewColumns} {...getFallback(waterCut, "waterCut")}>
              <InputNumber
                disabled={"waterCut" === modeModel?.mode || typeof value?.producingObject?.[0] !== "number"}
                variant="borderless"
                min={0}
                max={100}
                value={waterCut}
                onUpdate={(value) => update?.("waterCut", value)}
              />
            </EditableFallbackDisplay>
          ) : (
            <Format>{waterCut}</Format>
          ),
        renderToString: (waterCut) => {
          if (!waterCut) return null;

          return NUMBER_FORMAT.real_2.format(waterCut);
        },
      }),
      {
        title: "Проходка, м",
        dataKey: "md",
        width: 120,
        onCell: () => ({ className: cn.tableCellAlignRight, ...(isPreviewColumns ? { style: { padding: 0 } } : {}) }),
        render: (value, { update, indexPath }) =>
          editable && indexPath[0] !== -1 ? (
            <EditableFallbackDisplay isImport={isPreviewColumns} {...getFallback(value)}>
              <InputNumber style={{ width: "100%" }} variant="borderless" min={0} value={value} onUpdate={(value) => update?.("md", value)} />
            </EditableFallbackDisplay>
          ) : (
            <Format>{value}</Format>
          ),
        renderToString: (value) => {
          if (!value) return null;

          return NUMBER_FORMAT.real_2.format(value);
        },
      },
      {
        title: "Дата ввода",
        dataKey: "date",
        width: 150,
        onCell: () => (isPreviewColumns ? { style: { padding: 0 } } : {}),
        render: (datestring, { update }) => {
          const fallback = {
            ...getFallback(datestring, "date"),
            value: datestring ? dayjs(datestring).format("YYYY-MM-DD") : null,
          };
          return datestring !== undefined ? (
            <EditableFallbackDisplay isImport={isPreviewColumns} {...fallback} isWrapper={false}>
              <DatePicker
                disabled={!editable}
                locale={locale}
                variant="borderless"
                value={datestring && dayjs(datestring)}
                onChange={(date) => update?.("date", date?.format("YYYY-MM-DD") ?? null)}
                format="DD.MM.YYYY"
              />
            </EditableFallbackDisplay>
          ) : null;
        },
        renderToString: (datestring) => {
          if (!datestring) return null;

          return formatDate(dayjs(datestring), DATE_FORMAT.generic);
        },
      },
      ...conditionallyArr<ColumnRaw<DRow>>(editable, {
        title: "",
        key: "sticky",
        width: 55,
        isExported: false,
        render: (_, { expand, value }) => {
          if (expand !== undefined) {
            return null;
          }
          return (
            <>
              {value?.remove && <DeleteButton onClick={value?.remove} />}
              {value?.copy && <Button type="link" icon={<CopyOutlined />} onClick={value?.copy} />}
            </>
          );
        },
        onHeaderCell: () => ({ className: cn.tableRightSticky }),
        onCell: () => ({ className: cn.tableRightSticky }),
      }),
    ],
    [editable, isPreviewColumns, forecast, fact, getFallback, modeModel]
  );
};

const WellsList = observer(() => {
  const fact = useFact()!;
  const forecast = useForecast();

  const importWellsModal = forecast ? useImportWellsModal() : undefined;

  const tab = useMatches().at(-1)!.pathname.split("/")[4] ?? "base";
  const modeModel = useMemo(() => new ModeSelectorModel(), []);
  const store = useMemo(
    () => (tab === "base" ? new WellsListModel(fact, null, modeModel) : new WellsListModel(fact, forecast, modeModel)),
    [fact, forecast, modeModel, tab]
  );
  const columns = useColumns(tab !== "base", false, modeModel);

  const model = useMemo(() => {
    const model = new TableModel(
      columns,
      store,
      {
        onRow: ({ indexPath, expand }) => ({
          className:
            indexPath[0] + 1 === 0
              ? cn.summaryRow
              : expand === undefined
              ? cn.tableRowPlain
              : indexPath.length === 1
              ? cn.tableRowPrimary
              : cn.tableRowSecondary,
        }),
      },
      {
        headerHeight: 39,
        rowHeight: 33,
        borderColor: "#f0f0f0",
      }
    );
    model.topRowsCount = 1;
    return model;
  }, [store, columns]);

  useTableSettings(model, `wells_list_${tab}`);

  const handleUpload = (file: UploadFile) => {
    importWellsModal?.(file).then((data) => {
      if (data?.newRows && data.replace !== undefined) {
        store.importWells(data.newRows, data.replace);
      }
    });
  };

  const addExcelData = () => {
    importWellsModal?.(null).then((data) => {
      if (data?.newRows && data.replace !== undefined) {
        store.importWells(data.newRows, data.replace);
      }
    });
  };

  return (
    <TableContextProvider value={model}>
      <CsvSaver
        filename={`Список скважин (${tab !== "base" ? "новый фонд" : "базовый фонд"})`}
        exportArray={() => {
          return model.export();
        }}
      >
        <PageFrameTitlePortal model={model} onSave={store.submit} permissionSection="tech">
          {tab !== "base" ? (
            <>
              <ModeSelector onUpdate={modeModel.onChangeMode} value={modeModel.mode} />
              <Button type="text" icon={<Plus />} onClick={() => store.addEmptyWell()} />
              {forecast && (
                <Tooltip title="Импортировать из excel-файла">
                  <Button
                    data-testid="upload"
                    type="text"
                    loading={model.isLoading}
                    onClick={addExcelData}
                    icon={<Icon width="24" height="24" content={<UploadIcon />} viewBox="0 0 16 16" />}
                  />
                </Tooltip>
              )}
            </>
          ) : null}
        </PageFrameTitlePortal>
        {!store.childrenStore?.length ? (
          <EmptyScreen text="Загрузите список скважин" onUpload={handleUpload} />
        ) : (
          <Widget headerClassName={cn.tableHeader} />
        )}
      </CsvSaver>
    </TableContextProvider>
  );
});

export { useColumns, WellsList };
