import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { Dayjs } from "dayjs";
import { action, computed, makeObservable, observable, runInAction } from "mobx";

import { calculateDistance } from "features/infrastructure/infrastructureMap/utils";
import { Infrastructure } from "models/project/fact/infrastructure/infrastructure";
import { InfrastructureCatalog } from "models/project/fact/infrastructure/infrastructureCatalog";
import { Pipes as InfastructurePipes, PipeType } from "models/project/fact/infrastructure/pipes";
import { SegmentType } from "services/back/infrastructure/catalog";

import { findMatch } from "../../../utils";

type DRow = {
  uuid: string;
  title: string;
  kind?: [id: number | null, title?: string];
  category?: string;
  diameter?: number;
  thickness?: number;
  roughness?: number;
  startedAt: Dayjs;
  finishedAt?: Dayjs | null;
  diameterOuter?: number;
  firstNode?: { title: string; coords: { x: number; y: number }; altitude: number };
  secondNode?: { title: string; coords: { x: number; y: number }; altitude: number };
  cost?: number;
  isReconstruction?: boolean;
  unitConstructionCost: number;
  unitReconstructionCost: number;
  costReconstruction?: number;
  periodReconstruction?: number;
  length: number;
  frictionCorrectionFactor: number;

  remove?: () => void;
};

class Pipe extends TableNode<DRow> {
  public asDRow = (): DRow => ({
    ...this.data,
    category: this.data.category || "",
    firstNode: this.getRelatedNode(this.data.from),
    secondNode: this.getRelatedNode(this.data.to),
    cost: this.unitConstructionCost * this.segmentLength,
    costReconstruction: this.unitReconstructionCost * this.segmentLength,
    unitReconstructionCost: this.unitReconstructionCost,
    periodReconstruction: this.periodReconstruction,
    unitConstructionCost: this.unitConstructionCost,
    length: this.segmentLength,

    remove: this.remove,
  });

  public data: { kind: [id: number | null, title?: string]; isReconstruction?: boolean } & PipeType;
  constructor(private parent: Pipes, data: PipeType, private infrastructure: Infrastructure) {
    super(parent);

    makeObservable(this, {
      data: observable,
      updateValue: action,
      setParent: action,
      segmentLength: computed,
    });

    this.data = { ...data, kind: this.findKind(data), isReconstruction: !!data.reconstruction?.isActive };
  }

  get unitConstructionCost() {
    return this.data.construction?.totalCostPerUnit || 0;
  }
  get unitReconstructionCost() {
    return this.data.reconstruction?.totalCostPerUnit || 0;
  }
  get periodReconstruction() {
    return this.data.reconstruction?.period || 0;
  }

  get segmentLength() {
    const getNode = (uuid: string) => this.parent.infrastructure.nodes.at(uuid);
    const from = { x: getNode(this.data.from)?.x || 0, y: getNode(this.data.from)?.y || 0 };
    const to = { x: getNode(this.data.to)?.x || 0, y: getNode(this.data.to)?.y || 0 };
    return calculateDistance({ from, to, unit: "km" });
  }

  private getRelatedNode(uuid: string) {
    return {
      title: this.infrastructure.nodes.at(uuid)?.title || "Узел",
      coords: { x: this.infrastructure.nodes.at(uuid)?.x || 0, y: this.infrastructure.nodes.at(uuid)?.y || 0 },
      altitude: this.infrastructure.nodes.at(uuid)?.altitude || 0,
    };
  }

  findKind(data: PipeType): [id: number | null, title?: string | undefined] {
    const pipeAttributes = InfastructurePipes.getPipesAttributes(data);
    const pipes = this.parent.catalog.pipes(data.segmentType) || [];
    if (!pipes || !data.category) {
      return [null, undefined];
    }
    const matchingStation = findMatch<SegmentType>(pipeAttributes, pipes);
    return matchingStation ? [matchingStation.id, undefined] : [null, undefined];
  }

  updateValue(key: any, newValue: any): [prevValue: any, currValue: any] {
    const [k, value] = [key as keyof PipeType, newValue as never];
    const pipes = this.parent.catalog[this.data.segmentType === "prod" ? "oilPipesSelector" : "waterPipesSelector"];
    const updateData = (data: any) => this.infrastructure.pipes.update({ ...this.data, ...data });
    if (key === "isReconstruction") {
      value !== null && updateData({ reconstruction: { ...this.data.reconstruction!, isActive: value } });
    }
    if (key === "title" && value) {
      updateData({ [key]: value });
    }
    if (key === "finishedAt" || key === "startedAt") {
      updateData({ [key]: value });
    }
    if (key === "isReconstruction" || key === "startedAt" || key === "finishedAt" || key === "title") {
      const prev = this.data[k];
      this.data[k] = value;
      return [prev, value];
    }
    if (key === "kind") {
      const attributes = InfastructurePipes.getPipesAttributes(pipes?.at(value[0]) ?? undefined);
      updateData({ ...attributes, category: value[0] === null ? null : "Новый объект" });
      this.parent.move(this, value[0] !== null);
      this.data! = { ...this.data, ...attributes, [k]: value, category: value[0] === null ? null : "Новый объект" };
      return [pipes?.at(value[0])?.id, undefined];
    }
    return [undefined, undefined];
  }

  public setParent = (parent: Pipes) => {
    this.parent = parent;
  };

  public remove = () => {
    if (this.index === undefined) {
      console.error("attempt to remove infrastructure node without id");
      return;
    }
    this.infrastructure.pipes.remove([this.data.uuid]);
    this.parent.childrenStore?.splice(this.index, 1);
  };
}

class Pipes extends TableNode<DRow, Pipe> {
  public asDRow = () =>
    ({
      title: this.name ?? "Без характеристик",
    } as DRow);

  constructor(
    public parent: PipesModel,
    public name: string | null,
    public pipes: PipeType[],
    public infrastructure: Infrastructure,
    public catalog: InfrastructureCatalog
  ) {
    super(parent, { isExpandedChildren: true });

    makeObservable<Pipes, "init">(this, {
      init: action,
      move: action,
    });

    this.init();
  }

  public init = () => {
    const data = this.pipes.filter(({ segmentType }) => segmentType === this.parent.mode);
    this.childrenStore = new ChildrenStoreArray(
      this,
      data.map((data) => new Pipe(this, data, this.infrastructure))
    );
  };

  public move(pipe: Pipe, isNew?: boolean) {
    if (pipe.index === undefined) {
      return;
    }
    this.childrenStore?.splice(pipe.index, 1);
    (isNew ? this.parent.newPipes : this.parent.withoutEquipmentPipes)?.childrenStore?.push(pipe);
    pipe.setParent(isNew ? this.parent.newPipes! : this.parent.withoutEquipmentPipes!);
  }
}

class PipesModel extends TableNode<DRow, Pipes> {
  public newPipes?: Pipes;
  public withoutEquipmentPipes?: Pipes;
  constructor(public infrastructure: Infrastructure, public mode: "prod" | "inj") {
    super();

    makeObservable(this, {
      newPipes: observable,
      withoutEquipmentPipes: observable,
      isValid: computed,
    });

    this.initChildren();
  }

  get isValid() {
    const withoutEquipmentPipes = this.withoutEquipmentPipes?.childrenStore?.children as any[];
    const newPipes = this.newPipes?.childrenStore?.children as any[];
    return ![...withoutEquipmentPipes, ...newPipes].some((el) => el.data.startedAt === null);
  }

  public initChildren() {
    const pipes = this.infrastructure.pipes.data || [];
    const newPipes = pipes.filter(({ category }) => category === "Новый объект");
    const withoutEquipmentPipes = pipes.filter(({ category }) => category === null);

    this.newPipes = new Pipes(this, "Новый объект", newPipes, this.infrastructure, this.infrastructure.catalog);
    this.withoutEquipmentPipes = new Pipes(
      this,
      null,
      withoutEquipmentPipes,
      this.infrastructure,
      this.infrastructure.catalog
    );

    runInAction(() => {
      this.childrenStore = new ChildrenStoreArray(this, [this.withoutEquipmentPipes!, this.newPipes!]);
    });
  }
}

export { type DRow, Pipes, PipesModel };
