import { FC, useMemo } from "react";
import { Widget } from "@okopok/components/Table";
import { observer } from "mobx-react";

import { FullScreenLoader } from "elements/fullScreen/fullScreen";
import { Column, SimpleTableContext } from "features/tableDebug/simpleTable";
import { global } from "models/global";
import { LoggerStore, LogNote } from "models/logger";
import { useProject } from "models/project/project";

const ACTION_MAP: Record<string, string> = {
  "post:productions/forecast": "Сохранение прогноза",
  "post:gtms": "Создание ГТМ",
  "delete:gtms": "Удаление ГТМ",
  "post:wells/": "Создание скважины",
  "get:ranking_invest:storage": "Получение ранжирования",
  "post:ranking_invest:storage": "Сохранение критерия ранжирования",
  "post:ranking_settings:storage": "Сохранение настроек ранжирования",
  "post:ranking_sequential:storage": "Сохранение ручного ранжирования",
  "post:scenarios": "Создание сценария",
  "post:scenarios/copy": "Копирования сценария",
  "post:pipe_boundary_condition": "Сохранение инфраструктуры",
  "post:calculation/infrastructure": "Расчет инфраструктуры",
  "demo/schemas/new_calculation?scenario_id=": "Экономический расчет",
};

const tr = (title: string) => {
  if (title in ACTION_MAP) {
    return ACTION_MAP[title];
  }
  for (const [key, value] of Object.entries(ACTION_MAP)) {
    if (title.startsWith(key)) {
      return `${value} ${title.slice(key.length)}`;
    }
  }
  return title;
};

const useColumns = (): Column[] => {
  return useMemo(
    (): Column[] => [
      {
        title: "Действие",
        dataKey: "title",
        type: "string",
        width: { min: 10, max: 1000, competitiveness: 10 },
      },
      {
        title: "Пользователь",
        dataKey: "user",
        type: "string",
        width: { min: 10, max: 1000, competitiveness: 10 },
      },
      {
        title: "Время",
        dataKey: "date",
        type: "string",
        width: { min: 10, max: 1000, competitiveness: 10 },
      },
      {
        title: "Сценарий",
        dataKey: "scenarioTitle",
        type: "string",
        width: { min: 10, max: 1000, competitiveness: 10 },
      },
    ],
    []
  );
};

const Logs: FC = observer(() => {
  const columns = useColumns();
  const { logger } = global;
  const project = useProject()!;

  const store = useMemo(() => new LoggerStore(logger.currentProjectLog, project), [logger.currentProjectLog, project]);

  const formattedData = () => {
    if (!store?.getLogs) return store;
    const copyLogs = JSON.parse(JSON.stringify(store.getLogs));

    const formattedLogs = copyLogs.map((log: LogNote) => ({
      ...log,
      title: tr(log.title),
      user: log.user.name,
    }));

    return new LoggerStore(formattedLogs, project);
  };

  const data = formattedData();

  return logger.isLoadingLog ? (
    <FullScreenLoader />
  ) : (
    <SimpleTableContext exportFileName="Логирование" columns={columns} data={data} hideExpandColumn showIndexColumn={false}>
      <Widget />
    </SimpleTableContext>
  );
});

export { Logs };
