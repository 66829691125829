import { Result, Schema } from "services/back/calculate/calculate";
import { Metric } from "services/finance/utils";
import { zip } from "utils/itertools";
import { Range } from "utils/range";

import { FACTORS } from "../factors";

type FactorValues = Record<"full" | "forecast", Record<number, number | null>>;

function getMetricByTitle(result: Result, title: string): Metric | null {
  for (const schemaName of Object.keys(result).filter((key) => key.startsWith("schema_") || key === "result")) {
    const metric = (result[schemaName as keyof Result] as Schema).schema?.find((item) => item.title === title);
    if (metric) {
      return metric;
    }
  }
  return null;
}

function extractFactorValues(result: Result, ranges: [fullRange: Range, forecastRange: Range]): FactorValues {
  const res: FactorValues = {
    forecast: {},
    full: {},
  };

  const [fullRange, forecastRange] = ranges;

  for (const factor of FACTORS) {
    if (factor.key === "prod_wells") {
      const [fcValue] = result.result.schema.find((item) => item.title === "prod_wells 1")?.values ?? [null];
      const [fullValue] = result.result.schema.find((item) => item.title === "prod_wells 2")?.values ?? [null];
      res.forecast[factor.value] = fcValue;
      res.full[factor.value] = fullValue;
      continue;
    }
    const metricValues = getMetricByTitle(result, factor.key)?.values;
    res.forecast[factor.value] = null;
    res.full[factor.value] = null;
    for (const [year, cur] of zip([...fullRange], metricValues ?? [])) {
      res.full[factor.value] = (res.full[factor.value] || cur) && (res.full[factor.value] ?? 0) + (cur ?? 0);
      if (forecastRange.includes(year)) {
        res.forecast[factor.value] =
          (res.forecast[factor.value] || cur) && (res.forecast[factor.value] ?? 0) + (cur ?? 0);
      }
    }
  }
  return res;
}

export { extractFactorValues, getMetricByTitle };
