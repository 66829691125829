import { when } from "mobx";

import { kindInference } from "features/preloader/preloader";
import { global } from "models/global";
import { Forecast } from "models/project/fact/forecast/forecast";
import { Well } from "models/project/fact/well/well";
import { TreeFilter } from "models/tree/filters/filters";
import { TreeRoot } from "models/tree/tree";

import { type ProjectData } from "./projectContext";

const generateEmptyWellTree = () => new TreeRoot<Well>(null, [], [], []);

const WELLS_TREE_GROUPING_FIELDS = [
  {
    key: "mine",
    title: "Куст",
    getter: (w: Well) => w.pad,
  },
  {
    key: "licenseRegion",
    title: "Лицензионный участок",
    getter: (w: Well) => w.licenseRegion,
  },
  {
    key: "fond",
    title: "Фонд",
    getter: (w: Well) => w.fond,
  },
  {
    key: "producingObject",
    title: "Объект разработки",
    getter: (w: Well) => w.producingObject,
  },
];

const createWellsTreeFilters = (forecast: Forecast | undefined | null): TreeFilter<Well, any>[] => {
  console.assert(global.wellTypes.isLoading === false, "Попытка отображения до завершения загрузки");

  if (!forecast) {
    return [];
  }

  return [
    {
      title: "Фонд",
      options: ["Базовый", "Новый"],
      predicateFactory: (selected) => (well: Well) =>
        selected.map((item) => (item === "Базовый" ? "Base" : "New")).includes(well.fond),
    },
    {
      title: "Лицензионные участки",
      options: forecast.licenseRegions.titles,
      predicateFactory: (selected) => (well: Well) => selected.includes(well.licenseRegion?.title ?? ""),
    },
    {
      title: "Назначение скважины",
      options: ["Добывающая", "Нагнетательная", "Нет истории работы", "Прочего назначения"],
      predicateFactory: (selected) => (well: Well) => {
        const status = forecast.production.wellStatus(well.id);
        if (selected.includes("Добывающая")) {
          if (status?.isMining) {
            return true;
          }
        }
        if (selected.includes("Нагнетательная")) {
          if (status?.isInjecting) {
            return true;
          }
        }
        if (selected.includes("Нет истории работы")) {
          if (status === undefined) {
            return true;
          }
        }
        if (selected.includes("Прочего назначения")) {
          return true;
        }
        return false;
      },
    },
  ];
};

const onUpdateForecast = (target: ProjectData, forecast: Forecast | null) => {
  target.wellsTree = generateEmptyWellTree();
  when(
    () => {
      const isLoading = (() => {
        if (kindInference(target.project?.fact?.wells)) return true;
        if (kindInference(target.project?.fact?.wellPads)) return true;
        if (kindInference(target.project?.fact?.stratums)) return true;
        if (kindInference(target.project?.fact?.producingObjects)) return true;
        if (kindInference(target.project?.fact?.licenseRegions)) return true;
        if (kindInference(target.forecast)) return true;
        if (target.forecast !== null && kindInference(target.forecast?.wells)) return true;
        return false;
      })();
      return !isLoading;
    },
    () => {
      let wells: Well[];
      if (target.forecast) {
        wells = target.forecast.wells.allWells;
      } else if (target.project?.fact) {
        wells = target.project.fact.wells.allWells;
      } else {
        wells = [];
      }
      const wellTreeFilters = createWellsTreeFilters(forecast);

      const wellTree = new TreeRoot("Все", wells, WELLS_TREE_GROUPING_FIELDS, wellTreeFilters);
      target.wellsTree = wellTree;
    }
  );
};

export { generateEmptyWellTree, onUpdateForecast };
