import type { ColumnRaw } from "@okopok/components/Table";

import { DebugZone } from "elements/debugZone/debugZone";
import { global } from "models/global";

import cn from "./debugColumn.module.less";

function debugColumns<T>(columns: Omit<ColumnRaw<T>, "onCell" | "onHeaderCell">[]): ColumnRaw<T>[] {
  if (!global.IS_DEBUG_ZONE) {
    return [];
  }

  return columns.map(({ render, title, isExported, ...rest }) => ({
    ...rest,
    title: <DebugZone tag="span">{title}</DebugZone>,
    render: (...args) => ((render || args[0]) !== undefined ? <DebugZone tag="span">{render?.(...args) ?? args[0]}</DebugZone> : <div />),
    onCell: () => ({ className: cn.debugColumnCell }),
    onHeaderCell: () => ({ className: cn.debugColumnCell }),
    isExported: false,
  }));
}

export { debugColumns };
