import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { runInAction } from "mobx";

import { byPairs, zip } from "utils/itertools";
import { Range } from "utils/range";

import { Production, ProductionDatum } from "../../production/production";
import { Well } from "../well";
import { Wells } from "../wells";

import { debitDiff, debitMean, TechDummyRow, wellPerformance } from "./helpers";
import { DRow, TechWells } from "./tech";

class InjectWell extends TableNode<DRow, TechDummyRow> {
  public asDRow = (): DRow => ({
    id: this.well.id,
    name: this.well.title,
    measure: null,
  });

  public get years(): number[] {
    return this.data.map(([year]) => year);
  }

  public data: [year: number, datum: ProductionDatum][];

  constructor(
    private parent: InjectWellsNode,
    public readonly well: Well,
    public readonly productionStore: Production
  ) {
    super(parent, { selectable: false, mutable: false, isExpandedChildren: true });

    let data;
    if (this.productionStore.forecast === null) {
      data = [...(this.productionStore.wellData(this.well.id)?.byYear() ?? [])];
    } else {
      data = [...this.productionStore.wholeWellData(this.well.id)];
    }
    this.data = data.map(([date, datum]) => [date.year(), datum]);

    runInAction(() => {
      this.childrenStore = new ChildrenStoreArray(this, [
        new TechDummyRow(this, this.years, "Состояние скважины", null, () =>
          this.isInjecting.map((v) => (v ? "Работает" : "Не работает"))
        ),
        new TechDummyRow(this, this.years, "Число дней работы скважины", [4, 1], () => this.injDays),
        new TechDummyRow(this, this.years, "Закачка воды", [7, 6], () => this.waterInj),
        new TechDummyRow(this, this.years, "Среднесуточная приемистость", [8, 1], () => this.injCapacity),
        new TechDummyRow(this, this.years, "Процент изменения приемистости", [3, 2], () => this.capacityDiff),
        new TechDummyRow(this, this.years, "Коэффициент эксплуатации", [3, 1], () => this.wellPerformance),
      ]);
    });
  }

  public get range(): Range {
    return this.parent.range;
  }

  public get isInjecting(): boolean[] {
    return this.injDays.map((d) => (d ?? 0) > 0);
  }

  public get injDays(): Array<number | null> {
    return this.data.map(([, d]) => d.inj_days);
  }

  public get waterInj(): Array<number | null> {
    return this.data.map(([, d]) => d.water_inj);
  }

  get injCapacity(): Array<number | null> {
    return Array.from(zip(this.waterInj, this.injDays), (pair) => debitMean(...pair));
  }

  get capacityDiff(): Array<number | null> {
    const result = Array.from(byPairs([...zip(this.waterInj, this.injDays)]), (pair) => debitDiff(...pair));
    return [null, ...result.slice(0, -1)];
  }

  get wellPerformance(): Array<number | null> {
    return this.injDays.map(wellPerformance);
  }
}

class InjectWellsNode extends TableNode<DRow, InjectWell> {
  public asDRow = (): DRow => ({
    name: "Данные по нагнетательным скважинам",
    measure: null,
  });

  constructor(src: Wells, private parent: TechWells) {
    super(parent, { selectable: false, mutable: false });
    runInAction(() => {
      this.childrenStore = new ChildrenStoreArray(
        this,
        src.supportWells!.map((well) => new InjectWell(this, well, this.parent.source.production))
      );
    });
  }

  public get range(): Range {
    return this.parent.range;
  }
}

export { InjectWell, InjectWellsNode };
