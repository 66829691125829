import React, { ChangeEvent, useEffect } from "react";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { useTableContext } from "@okopok/components/Table";
import { Button, Input, Radio, RadioChangeEvent, Select, Skeleton } from "antd";
import Checkbox from "antd/es/checkbox/Checkbox";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";

import { conditionOptionsMap } from "./conditionOptions";
import { FilterManager } from "./manager";
import { FilterDetails, FilterInfo, FilterType } from "./types";

import styles from "./filterWidget.module.less";

type FilterRowProps = {
  info: Partial<FilterInfo<FilterType>> | undefined;
  filterMap: Record<string, FilterDetails<FilterType, any, any>>;
  changeInfo: (info: Partial<FilterInfo<FilterType>> | undefined) => void;
};

const FilterRow = observer(({ info, filterMap, changeInfo }: FilterRowProps) => {
  const model = useTableContext();
  const columns = model.columns;
  const onChange =
    <T, G>(key: keyof FilterInfo<any>, valueMapping?: (value: T) => G) =>
    (value: T) => {
      const result = { ...info };
      if (key === "column") {
        result.column = value as string;
        result.condition = undefined;
        result.value = undefined;
      } else {
        result[key] = valueMapping?.(value) ?? value;
      }
      changeInfo(result);
    };
  const filterDetails = info?.column !== undefined ? filterMap[info.column] : undefined;
  const { type, InputNode } = filterDetails ?? {};
  return (
    <>
      <Select
        placeholder="Выберите колонку…"
        value={info?.column}
        options={columns.filter(({ dataKey }) => dataKey !== undefined && dataKey in filterMap).map((col) => ({ value: col.key, label: col.title }))}
        onChange={onChange("column")}
      />
      {type === undefined ? (
        <>
          <Skeleton.Input block style={{ backgroundColor: "#F3F4F6", border: "1px solid #E5E7EB" }} />
          <Skeleton.Input block style={{ backgroundColor: "#F3F4F6", border: "1px solid #E5E7EB" }} />
        </>
      ) : (
        <>
          <Radio.Group
            className={styles.radioGroup}
            value={info?.condition}
            onChange={onChange<RadioChangeEvent, string>("condition", (e) => e.target.value)}
            options={conditionOptionsMap[type]}
            optionType="button"
            buttonStyle="solid"
          />
          {InputNode !== undefined ? (
            <InputNode value={info?.value} onChange={onChange("value")} />
          ) : (
            <Input value={info?.value} onChange={onChange<ChangeEvent<HTMLInputElement>, string>("value", (e) => e.target.value)} />
          )}
        </>
      )}
    </>
  );
});

type FilterWidgetProps = {
  filterManager: FilterManager<any, any>;
  applyFilters: () => void;
};

const TechFilterWidget = observer(({ filterManager, applyFilters }: FilterWidgetProps) => {
  useEffect(() => {
    return () => {
      filterManager.resetState();
    };
  }, [filterManager]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <span>Фильтр</span>
        <Button
          type="primary"
          onClick={() => {
            applyFilters();
            filterManager.saveState();
          }}
        >
          Применить
        </Button>
      </div>
      <div className={styles.filterListStatic}>
        {filterManager.staticFilters.map((f, i) => {
          return (
            <Checkbox
              key={i}
              checked={filterManager.staticFiltersChecked[i]}
              onChange={(e) => runInAction(() => (filterManager.staticFiltersChecked[i] = e.target.checked))}
            >
              {f.title}
            </Checkbox>
          );
        })}
      </div>
      <div className={styles.filterList}>
        <p>Колонка</p>
        <p>Условие</p>
        <p>Значение</p>
      </div>
      <div className={styles.filterList}>
        {filterManager.filters.map((filter, i) => (
          <React.Fragment key={i}>
            <FilterRow info={filter} filterMap={filterManager.filterMap} changeInfo={(info: any) => filterManager.change(info, i)} />
            <Button onClick={() => filterManager.insertEmpty(i + 1)} icon={<PlusOutlined />} />
            <Button
              disabled={filterManager.removeDisabled}
              onClick={() => filterManager.delete(i)}
              icon={<CloseOutlined style={{ color: "firebrick" }} />}
            />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
});

export { TechFilterWidget };
