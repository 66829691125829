import type { FC, PropsWithChildren, ReactNode } from "react";
import { Typography } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Format } from "elements/format/format";
import { Fitting } from "services/back/techForecast/request";

import { ApplyForGroupButton } from "./applyForAllButton";

import cn from "./settingsFrame.module.less";

type SettingsFrameProps = PropsWithChildren<{
  icon?: ReactNode;
  title: ReactNode;
  className?: string;
  stopCriterion?: true;
  onApplyForAll?: () => void;
  fitting?: Fitting | null;
}>;

const SettingsFrame: FC<SettingsFrameProps> = observer(({ icon, title, children, className, stopCriterion, fitting }) => {
  return (
    <div className={classNames(className, cn.frame)}>
      <div className={cn.title}>
        {icon && <div className={cn.icon}>{icon}</div>}
        <Typography.Title level={3}>{title}</Typography.Title>
        {fitting && (
          <div className={cn.metrics}>
            <span className={cn.label}>R²</span>
            <span className={cn.value}>
              <Format>{fitting.r2}</Format>
            </span>
            <span className={cn.label}>a</span>
            <span className={cn.value}>
              <Format>{fitting.a}</Format>
            </span>
            <span className={cn.label}>MSE</span>
            <span className={cn.value}>
              <Format>{fitting.mse}</Format>
            </span>
            <span className={cn.label}>k</span>
            <span className={cn.value}>
              <Format>{fitting.k}</Format>
            </span>
          </div>
        )}
        {stopCriterion && <ApplyForGroupButton group="stopCriterion" />}
      </div>
      <div className={cn.content}>{children}</div>
    </div>
  );
});

export { SettingsFrame };
