import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { computed, makeObservable } from "mobx";

import { Forecast } from "models/project/fact/forecast/forecast";
import { Well } from "models/project/fact/well/well";
import { VoronoiGrid } from "services/back/injectionPrediction/voronoi";

type DRow = {
  id?: number; // debug column

  injWellTitle?: string;
  injMineTitle?: string;
  injProducingObjectTitle?: string;
  injCapacity?: number;

  prodWellTitle?: string;
  prodMineTitle?: string;
  liquidRate?: number;
  oilRate?: number;

  oilCoeff?: number;
};

class ProdNode extends TableNode<DRow> {
  asDRow(): DRow {
    if (!this.well) {
      return {};
    }
    return {
      id: this.well.id,
      prodWellTitle: this.well.title,
      prodMineTitle: this.mineTitle,
      oilRate: this.oilRate, // tonn/day
      liquidRate: this.liquidRate, // m3/day
      oilCoeff: this.oilCoeff,
    };
  }

  public readonly well: Well | null;
  public readonly mineTitle?: string;
  public readonly oilRate?: number;
  public readonly liquidRate?: number;

  constructor(public readonly parent: InjectionNode, public readonly wellId: number, public readonly oilCoeff: number) {
    super(parent);
    this.childrenStore = null;

    this.well = this.parent.parent.forecast.wells.at(this.wellId);

    if (this.well) {
      this.mineTitle = this.well.pad?.title;
      const prodDatum = this.parent.parent.forecast.production.wellData(this.well.id)?.getForMonth(...this.currentDate);
      if (prodDatum?.prod_days) {
        this.oilRate = ((prodDatum.oil_prod ?? 0) / prodDatum.prod_days) * 1000;
        this.liquidRate = ((prodDatum.liquid_prod ?? 0) / prodDatum.prod_days) * 1000;
      }
    }
  }

  public get currentDate(): [year: number, month: number] {
    return this.parent.currentDate;
  }
}

class InjectionNode extends TableNode<DRow, ProdNode> {
  asDRow(): DRow {
    if (!this.well) {
      return {};
    }
    return {
      id: this.well.id,
      injWellTitle: this.well.title,
      injMineTitle: this.mineTitle,
      injProducingObjectTitle: this.producingObjectTitle,
      injCapacity: this.injCapacity, // m3/day
    };
  }

  public readonly well: Well | null;
  public readonly mineTitle?: string;
  public readonly producingObjectTitle?: string;
  public readonly injCapacity?: number;

  public readonly prodNodes: ProdNode[];

  constructor(
    public readonly parent: InjectionSlice,
    public readonly wellId: number,
    public readonly prodWells: Array<[id: number, coef: number]>
  ) {
    super(parent, { isExpandedChildren: true });
    this.prodNodes = prodWells
      .map(([id, coef]) => new ProdNode(this, id, coef))
      .filter((node) => node.oilRate !== undefined);
    this.childrenStore = new ChildrenStoreArray(
      this,
      prodWells.map(([id, coef]) => new ProdNode(this, id, coef)).filter((node) => node.oilRate !== undefined)
    );

    this.well = this.parent.forecast.wells.at(this.wellId);

    if (this.well) {
      this.mineTitle = this.well.pad?.title;
      this.producingObjectTitle = this.well.producingObject?.title;
      const prodDatum = this.parent.forecast.production.wellData(this.well.id)?.getForMonth(...this.currentDate);
      this.injCapacity =
        (prodDatum?.inj_days && ((prodDatum?.water_inj ?? 0) / prodDatum.inj_days) * 1000) ?? undefined;
    }
  }

  public get currentDate(): [year: number, month: number] {
    return this.parent.currentDate;
  }
}

class InjectionSlice extends TableNode<DRow, InjectionNode> {
  public get currentDate(): [year: number, month: number] {
    const date = this.forecast.injectionResults.currentDate!;
    const month = date.month() + 1;
    const year = date.year();
    return [year, month];
  }

  public injectionNodes: InjectionNode[] = [];

  constructor(public readonly forecast: Forecast, private voronoi: VoronoiGrid) {
    super();
    makeObservable(this, {
      currentDate: computed,
    });

    const { coefMap } = voronoi;

    this.injectionNodes = [...Object.entries(coefMap)].map(
      ([injId, prods]) =>
        new InjectionNode(
          this,
          parseInt(injId),
          [...Object.entries(prods)].map(([prodId, coeffs]): [number, number] => [parseInt(prodId), coeffs.prod])
        )
    );
    this.childrenStore = new ChildrenStoreArray(this, this.injectionNodes);
    this.childrenStore.sort((a, b) =>
      (a.well?.title ?? "").localeCompare(b.well?.title ?? "", undefined, { numeric: true, sensitivity: "base" })
    );
  }
}

export { type DRow, InjectionSlice };
