import { Dayjs } from "dayjs";

import { UOM } from "elements/uom";
import { TechSummaryAxisData } from "features/techSummaryChart/techSummaryChart";
import { global } from "models/global";
import { aggregateByDate } from "models/project/fact/production/aggregate";
import { Production, ProductionDatum } from "models/project/fact/production/production";
import { Well } from "models/project/fact/well/well";
import { transposeArrayObject } from "utils/itertools";

import { defaultYearDatum, Param, PARAMS, YearDatum } from "./constants";

function aggregate(wells: Well[], productionStore: Production): { years: Dayjs[]; data: YearDatum[] } {
  const productions = wells.map((w) => productionStore.wholeWellData(w.id));

  const years = [];
  const data = [];
  const accum = {
    oil: 0,
    liquid: 0,
    waterInj: 0,
  };
  for (const [year, datum] of aggregateByDate(aggFunc, productions)) {
    years.push(year);

    accum.oil += datum.oilProd;
    accum.liquid += datum.liquidProd;
    accum.waterInj += datum.waterInj;

    datum.accumOilProd = accum.oil;
    datum.accumLiquidProd = accum.liquid;
    datum.accumWaterInj = accum.waterInj;

    datum.accumInjectCompensation = accum.liquid && accum.waterInj / accum.liquid;

    data.push(datum);
  }
  return { years, data };
}

function yearDatumsToAxes(datums: YearDatum[]): { lines: TechSummaryAxisData[]; bars: TechSummaryAxisData } {
  const transposed = transposeArrayObject(datums, defaultYearDatum())!;

  const by_unit = new Map<string, Param[]>();
  PARAMS.forEach((param) => {
    by_unit.get(param.measure.unit ?? "unknown")?.push(param) ?? by_unit.set(param.measure.unit ?? "unknown", [param]);
  });

  const BAR_UNIT = new UOM(12, 1, global.uomResolver).unit;

  const bars: TechSummaryAxisData = {
    unit: "Скважины, шт",
    side: "right",
    dataSet: (by_unit.get(BAR_UNIT ?? "скв") ?? []).map((param) => ({
      y: transposed[param.key],
      title: param.title,
    })),
  };
  by_unit.delete(BAR_UNIT ?? "скв");

  const lines: TechSummaryAxisData[] = [];
  for (const [unit, params] of by_unit) {
    lines.push({
      unit: unit,
      side: 2 * lines.length < by_unit.size ? "left" : "right",
      dataSet: params.map((param) => ({
        y: transposed[param.key],
        title: param.title,
      })),
    });
  }

  return { lines, bars };
}

const aggFunc = (datums: ProductionDatum[]): YearDatum => {
  const result = defaultYearDatum();

  for (const datum of datums) {
    result.oilProd += datum.oil_prod ?? 0;
    result.liquidProd += datum.liquid_prod ?? 0;
    result.waterInj += datum.water_inj ?? 0;
    result.prodDays += datum.prod_days ?? 0;
    result.injDays += datum.inj_days ?? 0;
    if ((datum.inj_days ?? 0) > 0 && (datum.water_inj ?? 0) > 0) {
      ++result.injectionFond;
    } else {
      result.miningFond += +((datum.prod_days ?? 0) > 0 && (datum.liquid_prod ?? 0) > 0);
    }
  }
  result.waterCut = (1 - result.oilProd / result.liquidProd) * 100 || 0;
  result.oilRate = (result.oilProd * 1000) / result.prodDays || 0;
  result.liquidRate = (result.liquidProd * 1000) / result.prodDays || 0;
  result.injectionCapacity = (result.waterInj * 1000) / result.injDays || 0;
  result.injectCompensation = result.liquidProd && result.waterInj / result.liquidProd;

  return result;
};

export { aggregate, yearDatumsToAxes };
