import { useMemo } from "react";
import { ColumnRaw, TableModel } from "@okopok/components/Table";
import { ExpandButton } from "@okopok/components/Table/widgets/ExpandButton/ExpandButton";
import { Input, Switch, Tooltip } from "antd";
import locale from "antd/es/date-picker/locale/ru_RU";
import dayjs from "dayjs";

import { DeleteButton } from "elements/deleteButton/deleteButton";
import { Format } from "elements/format/format";
import { LazyInputNumber } from "elements/inputs/lazyInputNumber/lazyInputNumber";
import { MonthPicker } from "elements/inputs/monthPicker";
import { SelectStorable } from "elements/inputs/selectStorable/selectStorable";
import { useInfrastructure } from "features/infrastructure/useInfrastructure";
import { setupAccuracy } from "utils/round";

import "dayjs/locale/ru";

import { DRow as DRowStations, StationsModel } from "../infrastructureTableManager/stations";

import cn from "../../infrastructureTable.module.less";
import localStyle from "./useInfrastructureStationsTable.module.less";

const useColumns = (): ColumnRaw<DRowStations>[] => {
  const { catalog, range } = useInfrastructure();
  return useMemo(
    (): ColumnRaw<DRowStations>[] => [
      {
        title: "№",
        width: { min: 40, max: 80, competitiveness: 1 },
        key: "index",
        onCell: () => ({ className: cn.tableCell }),
        render: (_, station) => station.absoluteIndex,
        isSticky: true,
      },
      {
        key: "expand",
        title: null,
        isSticky: true,
        width: { min: 30, max: 30, competitiveness: 1 },
        render: (_, { expand }) => (expand?.status !== undefined ? <ExpandButton expand={expand} /> : <></>),
        onCell: () => ({ className: cn.tableCell }),
      },
      {
        title: "Название",
        width: { min: 154, max: 200, competitiveness: 1 },
        dataKey: "title",
        onCell: () => ({ className: cn.tableCell }),
        isSticky: true,
        render: (value, station) => {
          if (station.expand?.status !== undefined) {
            return value;
          }
          return <Input variant="borderless" className={cn.title} value={value} onChange={({ target }) => station.update?.("title", target.value)} />;
        },
      },
      {
        title: "Вид",
        width: { min: 154, max: 200, competitiveness: 1 },
        dataKey: "kind",
        render: (value, station) => {
          if (station.expand?.status !== undefined) {
            return <></>;
          }
          return (
            <div className={cn.catalogType}>
              <SelectStorable
                values={value}
                store={catalog.stationsSelector}
                placeholder="Нет выбранной станции"
                setValues={(id, title) => station.update?.("kind", [id, title])}
              />
            </div>
          );
        },
      },
      {
        title: "Тип",
        width: { min: 58, max: 70, competitiveness: 1 },
        dataKey: "stationType",
        onCell: () => ({ className: cn.tableCell }),
      },
      {
        title: "Категория",
        width: { min: 132, max: 150, competitiveness: 1 },
        dataKey: "category",
        onCell: () => ({ className: cn.tableCell }),
      },
      {
        title: "Мощность, тыс м3/сут",
        width: { min: 120, max: 150, competitiveness: 1 },
        dataKey: "power",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
        render: (value, station) => (station.expand?.status === undefined ? value ? value : "-" : <></>),
      },
      {
        title: "Перепад давления, МПа",
        width: { min: 160, max: 180, competitiveness: 1 },
        dataKey: "deltaPressure",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
        render: (value, station) => (station.expand?.status === undefined ? value ? value : "-" : <></>),
      },
      {
        title: "Дата ввода",
        width: { min: 120, max: 120, competitiveness: 1 },
        dataKey: "startedAt",
        render: (value, station) => {
          if (station.expand?.status !== undefined) {
            return <></>;
          }
          return (
            <div className={cn.date}>
              <MonthPicker
                onChange={(date) => station.update?.("startedAt", date ?? null)}
                value={value ? value : undefined}
                placeholder="Выбрать..."
                variant="borderless"
                locale={locale}
                start={dayjs(range.from.toString())}
                end={dayjs(range.to.toString())}
              />
            </div>
          );
        },
      },
      {
        title: "Дата выбытия",
        width: { min: 120, max: 120, competitiveness: 1 },
        dataKey: "finishedAt",
        render: (value, station) => {
          if (station.expand?.status !== undefined) {
            return <></>;
          }
          return (
            <div className={cn.date}>
              <MonthPicker
                onChange={(date) => station.update?.("finishedAt", date ?? null)}
                value={value ? value : undefined}
                placeholder=""
                variant="borderless"
                locale={locale}
                start={station.value?.startedAt}
                end={dayjs(range.to.toString())}
              />
            </div>
          );
        },
      },
      {
        title: "Связанные участки",
        width: { min: 130, max: 150, competitiveness: 1 },
        key: "relatedPipes",
        onCell: () => ({ className: cn.tableCell }),
        render: (value: DRowStations["relatedPipes"], station) => {
          if (station.expand?.status !== undefined) {
            return <></>;
          }
          return (
            <Tooltip className={localStyle.relatedPipes} title={value?.join(", ")}>
              {value && value.length > 0 ? value?.join(", ") : "-"}
            </Tooltip>
          );
        },
      },
      {
        title: "Координаты, м",
        width: { min: 158, max: 170, competitiveness: 1 },
        dataKey: "coords",
        onCell: () => ({ className: cn.tableCell }),
        render: (_, station) => {
          if (station.value?.coords === undefined) {
            return <></>;
          }
          return (
            <span>
              X: <Format>{setupAccuracy(station.value?.coords.x, "round")}</Format> Y:{" "}
              <Format>{setupAccuracy(station.value?.coords.y, "round")}</Format>
            </span>
          );
        },
      },
      {
        title: "Альтитуда, м",
        width: { min: 110, max: 120, competitiveness: 1 },
        dataKey: "altitude",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
        render: (value, tableItem) => {
          if (tableItem.expand?.status !== undefined) {
            return <></>;
          }
          return <LazyInputNumber variant="borderless" value={value} onUpdate={(altitude) => tableItem.update?.("altitude", altitude)} />;
        },
      },
      {
        title: "Стоимость строительства, тыс руб / (тыс м3/сут)",
        width: { min: 220, max: 250, competitiveness: 1 },
        dataKey: "cost",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
        render: (value, station) => (station.expand?.status === undefined ? value ? value : "-" : <></>),
      },
      {
        title: "Учет реконструкции",
        width: { min: 130, max: 150, competitiveness: 1 },
        dataKey: "isReconstruction",
        onCell: () => ({ className: cn.tableCell }),
        render: (value, station) => {
          if (station.expand?.status !== undefined) {
            return <></>;
          }
          return (
            <Switch
              onChange={(el) => station.update?.("isReconstruction", el ?? null)}
              className={localStyle.isReconstruction}
              checkedChildren="Да"
              unCheckedChildren="Нет"
              value={value}
            />
          );
        },
      },
      {
        title: "Стоимость реконструкции, тыс руб / (тыс м3/сут)",
        width: { min: 220, max: 250, competitiveness: 1 },
        dataKey: "costReconstruction",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
        render: (value, station) => (station.expand?.status === undefined ? value ? value : "-" : <></>),
      },
      {
        title: <p />,
        key: "remove",
        width: 32,
        onCell: () => ({ className: cn.remove }),
        render: (_, station) => {
          if (station.expand?.status !== undefined) {
            return <></>;
          }
          return <DeleteButton onClick={station.value?.remove} />;
        },
      },
    ],
    [range, catalog]
  );
};

const useInfrastructureStationsTableModel = () => {
  const infrastructure = useInfrastructure();
  const columns = useColumns();
  const store = useMemo(() => new StationsModel(infrastructure), [infrastructure]);

  const model = useMemo(
    () =>
      new TableModel(columns, store, {
        onRow: ({ indexPath, expand }) => ({
          className:
            expand === undefined ? cn.tableRowPlain : indexPath.length === 1 ? `${cn.tableRowPrimary} ${localStyle.rowBorderNone}` : cn.tableRowPlain,
        }),
      }),
    [columns, store]
  );

  return { model, store };
};

export { useInfrastructureStationsTableModel };
