import { type FC, ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";
import Split from "react-split";
import { MenuProps } from "antd";
import { observer } from "mobx-react-lite";
import { useMainRouterParams } from "routing/authorizedRouter";

import { Loader } from "elements/loader";
import { global } from "models/global";
import { conditionallyArr } from "utils/conditionally";

import { ProjectFolder, SCENARIO } from "../authorized/assets/routing";
import { useCurrentFolder } from "../authorized/modesMenu/modeSwitch/modeSwitch";

import { MenuByItSelf } from "./menuByItSelf";
import { OnlyScenarioMenu } from "./onlyScenarioMenu";
import { ScenarioMenu, usePagePostfix } from "./scenarioMenu";

import cn from "./secondaryMenu.module.less";

const isReport = (folder: ProjectFolder) => {
  return folder.key.startsWith("report");
};

type MenuItemSubset = {
  label: ReactNode;
  key: string;
  icon?: ReactNode;
  disabled?: boolean;
  children?: MenuItemSubset[];
};

const useMenuItems = (): MenuItemSubset[] | null => {
  const folder = useCurrentFolder();
  if ("children" in folder) {
    return folder.children.map(({ key, title, element }) => ({
      key,
      label: title,
      disabled: element === null,
    }));
  }
  return null;
};

const SubMenu = () => {
  const [pageKey] = usePagePostfix();
  const navigate = useNavigate();
  const params = useMainRouterParams();
  const folder = useCurrentFolder();

  const title = isReport(folder) ? "Отчеты" : "Данные проекта";

  const onMainClick: MenuProps["onClick"] = ({ key }) => {
    const path = ["", params.project, ...conditionallyArr("scenario" in params, params.scenario), key];
    navigate(path.join("/"));
  };

  const [search, onSearchChange] = useState<string | undefined>(undefined);

  const items = useMenuItems();
  return (
    <MenuByItSelf
      title={title}
      flex={3}
      items={items}
      onNavigate={onMainClick}
      noData={<Loader />}
      selectedKey={pageKey}
      search={search}
      onSearchChange={onSearchChange}
    />
  );
};

const SecondaryMenu: FC = observer(() => {
  const { project } = useMainRouterParams();
  const folder = useCurrentFolder();

  const isNeedSubmenu = "children" in folder;
  const isNeedScenarios = SCENARIO.includes(folder);
  const isNeedOnlyScenarios = isReport(folder);

  if (global.isNarrowMenu || project === undefined) {
    return null;
  }

  const toRender = [];
  const parts = [];
  const keys = [];
  if (isNeedSubmenu) {
    toRender.push(<SubMenu key="SubMenu" />);
    keys.push("SubMenu");
    parts.push(1);
  }

  if (isNeedScenarios) {
    toRender.push(<ScenarioMenu key="ScenarioMenu" />);
    keys.push("ScenarioMenu");
    parts.push(1);
  }

  if (isNeedOnlyScenarios) {
    toRender.push(<OnlyScenarioMenu key="OnlyScenarioMenu" />);
    keys.push("OnlyScenarioMenu");
    parts.push(1);
  }

  const totalSize = parts.reduce((partialSum, a) => partialSum + a, 0);
  const sizes = parts.map((e) => (e / totalSize) * 100);

  if (toRender.length === 0) {
    return null;
  } else if (toRender.length === 1) {
    return toRender[0];
  } else {
    return (
      <Split key={keys.join("-")} sizes={sizes} className={cn.nav} direction="vertical">
        {toRender}
      </Split>
    );
  }
});

export { SecondaryMenu };
