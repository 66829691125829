import { action, computed, makeObservable, observable, runInAction, when } from "mobx";
import { useMainRouterParams } from "routing/authorizedRouter";

import { global } from "models/global";
import { Lazy } from "models/lazy";
import { Params, Params as ParamsModel } from "models/params/params";
import { Stratums } from "models/project/stratum/stratums";
import { CachedValues, CalculationVariant, postCalculation, provideRequest } from "services/back/calculate/calculate";
import { save } from "services/back/calculate/utils";
import { pathForecastTitle, scenarioCopy, ScenarioRaw } from "services/back/project";
import { TableType } from "services/back/ranking";
import { depositsForecastSource } from "services/finance/deposits";
import { investForecastSource, investUnitCostsSource } from "services/finance/invest";
import { operatingRevenueForecastSource } from "services/finance/operating/operating";
import { referenceForecastSource } from "services/finance/reference";
import { nddForecastSource, severanceForecastSource, wealthForecastSource } from "services/finance/tax/tax";
import { StorageKey } from "services/finance/utils";
import { fetchTax } from "services/forecast";
import { Range } from "utils/range";

import { LicenseRegions } from "../../licenseRegion/licenseRegions";
import { ECYStore } from "../ecyStore/ecyStore";
import { Fact, useFact } from "../fact";
import { Infrastructure } from "../infrastructure/infrastructure";
import { InvestParams } from "../investParams/investParams";
import { InvestUnitCosts } from "../investParams/unitCosts";
import { LicenseRegionsParamsMap } from "../licenseRegionParamsMap";
import { OperatingRevenue } from "../operatingParams/revenueTable";
import { OperatingUnitCosts } from "../operatingParams/unitCosts";
import { ProducingObjectsParams } from "../producingObjectsParams/producingObjectsParams";
import { Production } from "../production/production";
import { ReferenceParams } from "../referenceParams";
import { NDDTax } from "../tax/nddTax";
import { SeveranceTax } from "../tax/severanceTax";
import { WealthTax } from "../tax/wealthTax";
import { TaxDiscount } from "../taxDiscount";
import { Wells } from "../well/wells";
import { WellPads } from "../wellPad/wellPads";

import { CompensationCoefficients } from "./injPrediction/compensationCoeffs";
import { InjectionResults } from "./injPrediction/injectionResults";
import { Interventions } from "./interventions/interventions";
import { DecreaseCoefficients } from "./operatingGTM/decreaseCoefficients";
import { OperatingGTM } from "./operatingGTM/operatingGTM";
import { Ranking } from "./ranking/ranking";
import type { RankingDrilling } from "./ranking/rankingDrilling";
import type { RankingReconstruction } from "./ranking/rankingReconstruction";
import { RankingSettings } from "./ranking/settings";
import { TechForecastSettings } from "./techPrediction/techSettings";
import { constructResultModels, ForecastResultOperator, ForecastResultPack } from "./forecastResultOperator";

class Forecast {
  #investCosts = new Lazy(() => new InvestUnitCosts(this.range, investUnitCostsSource(this.storageKey)));
  public readonly investParams: LicenseRegionsParamsMap<InvestParams>;

  #operatingCosts = new Lazy(() => new OperatingUnitCosts(this));
  public readonly operatingRevenue: LicenseRegionsParamsMap<OperatingRevenue>;

  #severanceTax = new Lazy(() => new SeveranceTax(this.range, severanceForecastSource(this.storageKey)));
  public readonly wealthTax: LicenseRegionsParamsMap<WealthTax>;
  #nddTax = new Lazy(() => new NDDTax(this.range, nddForecastSource(this.storageKey), this.fact.project));

  public readonly referenceParams: LicenseRegionsParamsMap<ReferenceParams>;

  #producingObjectsParams = new Lazy(() => new ProducingObjectsParams(this.fact, this));
  #depositParams = new Lazy(() => new Params("Запасы", this.range, depositsForecastSource(this.storageKey)));

  #interventions = new Lazy(() => new Interventions(this));

  #wells = new Lazy(() => new Wells(this.fact, this));

  #production = new Lazy(() => new Production(this.fact, this));

  #techForecastSettings = new Lazy(() => new TechForecastSettings(this));

  #compensationCoefficients = new Lazy(() => new CompensationCoefficients(this));
  #injectionResults = new Lazy(() => new InjectionResults(this));

  #operatingGTM = new Lazy(() => new OperatingGTM(this));

  #decreaseCoefficients = new Lazy(() => new DecreaseCoefficients(this));

  #infrastructure = new Lazy(() => new Infrastructure(this.fact, this));

  #result = new Lazy(() => new ForecastResultOperator(this));

  get cached(): null | undefined | CachedValues {
    return this.#result.value.cached;
  }

  get result(): null | undefined | Map<number, ForecastResultPack> {
    return this.#result.value.calculated === null ? this.#result.value.saved : this.#result.value.calculated;
  }

  get calculatedResult(): null | undefined | Map<number, ForecastResultPack> {
    return this.#result.value.calculated;
  }

  get savedResultTS(): null | undefined | Date {
    return this.#result.value.savedTS;
  }

  get savedResult(): null | undefined | Map<number, ForecastResultPack> {
    return this.#result.value.saved;
  }

  get anyResult(): undefined | Map<number, ForecastResultPack> {
    if (this.savedResult !== null) {
      return this.savedResult;
    }
    if (this.calculatedResult === null) {
      this.calculate();
      return undefined;
    }
    return this.calculatedResult;
  }

  #ranking = new Lazy(
    () =>
      new Ranking(
        "ranking",
        this,
        this.calculatePreload().then(() => this.operatingCosts.optionedScalars!.autofill())
      )
  );

  #rankingSequential = new Lazy(
    () =>
      new Ranking(
        "sequential",
        this,
        this.calculatePreload().then(() => this.operatingCosts.optionedScalars!.autofill())
      )
  );

  #rankingSettings = new Lazy(() => new RankingSettings(this));

  get storageKey(): StorageKey {
    return { fact: this.fact.projectId, forecast: this.id };
  }

  public taxDiscount?: TaxDiscount;

  public discount?: number;

  constructor(readonly fact: Fact, public title: string, public id: number, isFetchTax: boolean = true) {
    makeObservable(this, {
      taxDiscount: observable,
      title: observable,

      result: computed,
      calculatedResult: computed,
      savedResult: computed,
      // anyResult: computed,
      isComplete: computed,
      wells: computed,
      severanceTax: computed,
      ecyStore: computed,
      production: computed,
      licenseRegions: computed,
      investYear: computed,

      factRange: computed,
      wholeRange: computed,

      submitTitle: action,
    });

    if (isFetchTax) {
      fetchTax(this.id).then((resp) => {
        const { taxDiscount, discount } = resp;
        this.taxDiscount = new TaxDiscount(taxDiscount);
        this.discount = discount;
      });
    }

    this.wealthTax = new LicenseRegionsParamsMap(WealthTax, wealthForecastSource, this.fact, this);
    this.investParams = new LicenseRegionsParamsMap(InvestParams, investForecastSource, this.fact, this);
    this.referenceParams = new LicenseRegionsParamsMap(ReferenceParams, referenceForecastSource, this.fact, this);
    this.operatingRevenue = new LicenseRegionsParamsMap(
      OperatingRevenue,
      operatingRevenueForecastSource,
      this.fact,
      this
    );
  }

  static fromRaw(fact: Fact): (datum: ScenarioRaw) => Forecast {
    return (datum: ScenarioRaw) => new Forecast(fact, datum.title, datum.id);
  }

  public submitTitle = async (newTitle: string) => {
    try {
      const res = await pathForecastTitle(this.id, newTitle);
      runInAction(() => {
        this.title = res.title;
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  async calculatePreload() {
    await when(() => !global.licenseRegions.isLoading && !this.wellPads.isLoading);
    return when(
      () =>
        !this.fact.producingObjects.isLoading &&
        !this.fact.producingObjectsParams.isLoading &&
        !this.producingObjectsParams.isLoading &&
        !this.fact.wells.isLoading &&
        !this.wells.isLoading &&
        !this.interventions.isLoading &&
        !this.infrastructure.isLoading &&
        !this.fact.production.isLoading &&
        !this.production.isLoading &&
        // invest_schema
        !this.fact.investParams.isLoading &&
        !this.investParams.isLoading &&
        // usc_schema
        !this.ecyStore.isLoading &&
        // operating_schema
        !this.operatingRevenue.isLoading &&
        !this.fact.operatingRevenue.isLoading &&
        !this.fact.operatingParams.isLoading &&
        // operating_schema tax
        !this.wealthTax.isLoading &&
        !this.fact.wealthTax.isLoading &&
        !this.nddTax.isLoading &&
        !this.severanceTax.isLoading &&
        !this.depositParams.isLoading &&
        !this.referenceParams.isLoading &&
        !this.fact.referenceParams.isLoading &&
        !this.fact.nddTax.isLoading &&
        !this.fact.severanceTax.isLoading &&
        !this.fact.depositParams.isLoading &&
        // constants
        !this.investCosts.isLoading &&
        !this.operatingCosts.isLoading
    );
  }

  rankingDrilling(type: TableType): RankingDrilling {
    if (type === "ranking") {
      return this.#ranking.value.drilling;
    }
    return this.#rankingSequential.value.drilling;
  }

  rankingReconstruction(type: TableType): RankingReconstruction {
    if (type === "ranking") {
      return this.#ranking.value.reconstruction;
    }
    return this.#rankingSequential.value.reconstruction;
  }

  calculate = async (variant: CalculationVariant = "classic"): Promise<void> => {
    runInAction(() => {
      this.#result.value.calculated = undefined;
    });
    await this.calculatePreload();
    await this.operatingCosts.optionedScalars!.autofill();
    const response = await postCalculation(this, variant);
    const resultModels = constructResultModels(this, response);
    runInAction(() => {
      this.#result.value.calculated = resultModels;
    });
  };

  prepareForReportRequest = async (): Promise<void> => {
    await this.calculatePreload();
    await this.operatingCosts.optionedScalars!.autofill();
  };

  dropCalculation = () => {
    this.#result.value.drop();
  };

  puskachDownload = async () => {
    await this.calculatePreload();
    const result = provideRequest(this);
    save(result);
  };

  get ranking() {
    return this.#ranking.value;
  }

  get rankingSequential() {
    return this.#rankingSequential.value;
  }

  get rankingSettings() {
    return this.#rankingSettings.value;
  }

  get investYear() {
    return this.fact.investYear;
  }

  get taxDiscountTitle() {
    return this.taxDiscount?.title;
  }

  get isComplete(): boolean {
    return true;
  }

  public get wells(): Wells {
    return this.#wells.value;
  }

  public get stratums(): Stratums {
    return this.fact.stratums;
  }

  public get wellPads(): WellPads {
    return this.fact.wellPads;
  }

  public get production(): Production {
    return this.#production.value;
  }

  public get interventions(): Interventions {
    return this.#interventions.value;
  }

  public get infrastructure(): Infrastructure {
    return this.#infrastructure.value;
  }

  public get operatingGTM(): OperatingGTM {
    return this.#operatingGTM.value;
  }

  public get decreaseCoefficients(): DecreaseCoefficients {
    return this.#decreaseCoefficients.value;
  }

  public get investCosts(): ParamsModel {
    return this.#investCosts.value;
  }

  public get operatingCosts(): OperatingUnitCosts {
    return this.#operatingCosts.value;
  }

  public get ecyStore(): ECYStore {
    return this.fact.ecyStore;
  }

  public get severanceTax(): SeveranceTax {
    return this.#severanceTax.value;
  }

  public get nddTax(): SeveranceTax {
    return this.#nddTax.value;
  }

  public get techForecastSettings(): TechForecastSettings {
    return this.#techForecastSettings.value;
  }

  public get compensationCoefficients(): CompensationCoefficients {
    return this.#compensationCoefficients.value;
  }

  public get injectionResults(): InjectionResults {
    return this.#injectionResults.value;
  }

  public get factRange() {
    return this.fact.factRange;
  }

  get range(): Range {
    return this.fact.forecastRange;
  }

  public get wholeRange() {
    return this.fact.wholeRange;
  }

  public get producingObjectsParams(): ProducingObjectsParams {
    return this.#producingObjectsParams.value;
  }

  public get depositParams(): Params {
    return this.#depositParams.value;
  }

  public get licenseRegions(): LicenseRegions {
    return this.fact.licenseRegions;
  }

  public async copy(newName: string, description: string): Promise<Forecast> {
    return Forecast.fromRaw(this.fact)(await scenarioCopy(this.id, newName, description));
  }

  nameInference(transformer: (title: string, id: number | null) => string): string {
    let id: null | number = null;

    while (true) {
      const title = transformer(this.title, id);
      if (this.fact.forecasts.find((fc) => title === fc.title) === null) {
        return title;
      }
      id = (id ?? 1) + 1;
    }
  }
}

const useForecast: () => Forecast | undefined | null = () => {
  const state = useFact();
  const params = useMainRouterParams();
  if (state === undefined) {
    return undefined;
  }
  if (state === null || params.scenario === undefined) {
    return null;
  }
  return state.forecasts.at(params.scenario);
};

export { constructResultModels, Forecast, type ForecastResultPack, useForecast };
