import { FC, PropsWithChildren, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RedditOutlined, UserOutlined } from "@ant-design/icons";
import { Menu, MenuProps, Tooltip } from "antd";
import { useMainRouterParams } from "routing/authorizedRouter";

import { DebugZone } from "elements/debugZone/debugZone";
import { Icon } from "elements/icon/icon";
import { global } from "models/global";
import { ifDebugZoneArr } from "utils/conditionally";

import { useAboutModal } from "./aboutModal/aboutModal";
import { ReactComponent as AboutIcon } from "./bottomIcons/about.svg";
import { ReactComponent as LogIcon } from "./bottomIcons/log.svg";
import { ReactComponent as LogoutIcon } from "./bottomIcons/logout.svg";
import { ReactComponent as ProjectsIcon } from "./bottomIcons/projects.svg";
import { ReactComponent as SettingsIcon } from "./bottomIcons/settings.svg";
import { ReactComponent as UserIcon } from "./bottomIcons/user.svg";
import { ModeSwitch } from "./modeSwitch/modeSwitch";

import cn from "./modeMenu.module.less";

const Label: FC<{ title: string } & PropsWithChildren> = ({ title, children }) => (
  <Tooltip placement="right" title={title}>
    <Icon content={children} />
  </Tooltip>
);

const MENU: MenuProps["items"] = [
  {
    key: "/",
    icon: (
      <Label title="Дашборд проектов">
        <ProjectsIcon />
      </Label>
    ),
  },
  {
    key: "log",
    disabled: true,
    icon: (
      <Label title="Журнал изменений">
        <LogIcon />
      </Label>
    ),
  },
  {
    key: "submenu",
    icon: (
      <Label title="">
        <SettingsIcon />
      </Label>
    ),
    children: [
      {
        key: "setup",
        icon: <Icon width="16px" height="16px" content={<SettingsIcon />} />,
        label: "Настройки системы",
      },
      {
        key: "user",
        icon: <Icon width="16px" height="16px" content={<UserIcon />} />,
        label: "Профиль",
        disabled: true,
      },
      {
        key: "about",
        icon: <Icon width="16px" height="16px" viewBox="0 0 16 16" content={<AboutIcon />} />,
        label: "О программе",
      },
      {
        key: "users",
        icon: <UserOutlined />,
        label: "Пользователи системы",
      },
      ...ifDebugZoneArr({
        key: "table",
        icon: <RedditOutlined />,
        label: <DebugZone>Отладочная таблица</DebugZone>,
      }),
      {
        key: "logout",
        icon: <Icon width="16px" height="16px" viewBox="0 0 16 16" content={<LogoutIcon />} />,
        label: "Выйти",
        onClick: global.logout,
        danger: true,
      },
    ],
  },
];

const useCurrentKey = () => {
  const { pathname } = useLocation();
  let expectedKey = pathname.split("/")[2] ?? "/";
  if (expectedKey !== "about") {
    return expectedKey ?? "";
  }
  return "";
};

const ModesMenu = () => {
  const { project } = useMainRouterParams();
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const navigate = useNavigate();
  const aboutModal = useAboutModal();
  const currentKey = useCurrentKey();

  useEffect(() => {
    setSelectedKeys([currentKey]);
  }, [currentKey]);

  const handleClick: MenuProps["onClick"] = ({ key }) => {
    if (!(key === "about" || key === "logout")) {
      navigate(key);
      setSelectedKeys([key]);
    }
    key === "about" && aboutModal();
  };

  return (
    <div className={cn.header} id="menuId">
      <div className={cn.tophead}>{project && <ModeSwitch className={cn.button} />}</div>
      <div className={cn.bottomhead}>
        <Menu items={MENU} onClick={handleClick} selectedKeys={selectedKeys} triggerSubMenuAction="click" mode="vertical" />
      </div>
    </div>
  );
};

export { ModesMenu };
