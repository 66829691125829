import { ChangeEvent, ReactElement } from "react";
import { DeleteOutlined, FilterFilled, FilterOutlined } from "@ant-design/icons";
import { IChildrenStore, TableNode } from "@okopok/components/Table";
import { Button, Checkbox, CheckboxOptionType, DatePicker, Input, Popconfirm, PopconfirmProps } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { makeAutoObservable, runInAction } from "mobx";
import { observer } from "mobx-react";

import { formatData, FormatDataAdditionalProps } from "elements/format/formatData";
import { type GenericTableRow } from "services/back/genericTable/genegicTableService";

import { Column, GenericTableData } from "./simpleTable";

type FilterOperation = "string_has" | "number_gte" | "number_lte" | "set_has" | "date_gte" | "date_lte";

const getValueFromGenericTableData = (e: GenericTableData, columnKey: string) => {
  if (columnKey && e.asTableItem.value && columnKey in e.asTableItem.value) {
    return e.asTableItem.value[columnKey];
  }
  return undefined;
};

type CompareFuncResult = boolean;
type CompareFuncInput = unknown;
type CompareFunc = ((tableValue: CompareFuncInput) => CompareFuncResult) | undefined;
type CompareFuncsDict = Partial<Record<FilterOperation, CompareFunc | undefined>>;

const generateCompareFuncion = (operation: FilterOperation | string, filterValue: unknown): CompareFunc => {
  if (operation.startsWith("string")) {
    if (typeof filterValue === "string") {
      const d: CompareFuncsDict = {
        string_has: (tableValue: CompareFuncInput) =>
          typeof tableValue === "string" ? tableValue.toLowerCase().includes(filterValue.toLowerCase()) : false,
      };
      return d[operation as FilterOperation];
    } else {
      return () => true;
    }
  } else if (operation.startsWith("number")) {
    if (typeof filterValue === "number") {
      const d: CompareFuncsDict = {
        number_gte: (tableValue: CompareFuncInput) => (typeof tableValue === "number" ? tableValue >= filterValue : false),
        number_lte: (tableValue: CompareFuncInput) => (typeof tableValue === "number" ? tableValue <= filterValue : false),
      };
      return d[operation as FilterOperation];
    } else {
      return () => true;
    }
  } else if (operation.startsWith("set")) {
    if (Array.isArray(filterValue)) {
      const fValue = filterValue.map((v) => (v === "null" ? null : v));
      const d: CompareFuncsDict = {
        set_has: (tableValue: CompareFuncInput) => fValue.includes(tableValue),
      };
      return d[operation as FilterOperation];
    } else {
      return () => true;
    }
  } else if (operation.startsWith("date")) {
    if (dayjs.isDayjs(filterValue)) {
      const d: CompareFuncsDict = {
        date_gte: (tableValue: CompareFuncInput) =>
          dayjs.isDayjs(tableValue) ? tableValue.isSame(filterValue, "day") || tableValue.isAfter(filterValue, "day") : false,
        date_lte: (tableValue: CompareFuncInput) =>
          dayjs.isDayjs(tableValue) ? tableValue.isSame(filterValue, "day") || tableValue.isBefore(filterValue, "day") : false,
      };
      return d[operation as FilterOperation];
    } else {
      return () => true;
    }
  }

  return undefined;
};

const filterFunctionWrapper = (predict: (e: GenericTableData) => boolean) => (child: GenericTableData) => {
  let isVisible = false;

  if ("childrenStore" in child && child?.childrenStore) {
    child.childrenStore.filter(filterFunctionWrapper(predict));
    if (child.childrenStore.visibleChildrenLength > 0) {
      isVisible = true;
    }
  }
  if (!isVisible) {
    isVisible = predict(child);
  }

  return isVisible;
};

class SimpleTableFilter {
  private inputData: Record<string, any> = {};
  private submittedData: Record<string, any> = {};

  constructor(public readonly formatDataOptions: FormatDataAdditionalProps) {
    makeAutoObservable(this);
  }

  private getKey(columnKey: string, key: string, operation: FilterOperation) {
    return columnKey + ">" + operation + ">" + key;
  }

  private getColumnKeyPrefix(columnKey: string) {
    return columnKey + ">";
  }

  isColumnHasSubmittedFilterData(columnKey: string) {
    for (const key of Object.keys(this.submittedData)) {
      if (key.startsWith(this.getColumnKeyPrefix(columnKey))) {
        return true;
      }
    }
    return false;
  }

  isColumnHasInputFilterData(columnKey: string) {
    for (const key of Object.keys(this.inputData)) {
      if (key.startsWith(this.getColumnKeyPrefix(columnKey))) {
        return true;
      }
    }
    return false;
  }

  clearInputDataForColumn(columnKey: string) {
    for (const key of Object.keys(this.inputData)) {
      if (key.startsWith(this.getColumnKeyPrefix(columnKey))) {
        delete this.inputData[key];
      }
    }
  }

  getFilterDataInput(columnKey: string, key: string, operation: FilterOperation) {
    return this.inputData[this.getKey(columnKey, key, operation)];
  }

  setFilterDataInput(columnKey: string, key: string, operation: FilterOperation, value: any) {
    this.inputData[this.getKey(columnKey, key, operation)] = value;
  }
  removeFilterDataInput(columnKey: string, key: string, operation: FilterOperation) {
    delete this.inputData[this.getKey(columnKey, key, operation)];
  }

  submitInputs(tableNode: TableNode<GenericTableRow, GenericTableData>) {
    this.submittedData = { ...this.inputData };

    const toFilter: { columnKey: string; cmpFunc: (sourceValue: any) => boolean | undefined }[] = [];

    runInAction(() => {
      resetAllFilters(tableNode.childrenStore);

      for (const [filterKey, value] of Object.entries(this.submittedData)) {
        const [columnKey, operation] = filterKey.split(">");
        if (!(typeof operation === "string")) {
          continue;
        }
        const cmpFunc = generateCompareFuncion(operation, value);
        if (cmpFunc === undefined) {
          continue;
        }
        toFilter.push({ cmpFunc, columnKey });

        tableNode.childrenStore?.filter(
          filterFunctionWrapper((e) => {
            for (const { cmpFunc, columnKey } of toFilter) {
              if (!cmpFunc(getValueFromGenericTableData(e, columnKey))) {
                return false;
              }
            }
            return true;
          })
        );
      }
    });
  }

  resetInputs() {
    this.inputData = { ...this.submittedData };
  }
}

const resetAllFilters = (childrenStore: IChildrenStore<GenericTableRow, GenericTableData> | null | undefined) => {
  if (!childrenStore) {
    return;
  }
  for (const child of childrenStore.children) {
    resetAllFilters(child.childrenStore);
  }
  childrenStore.reset();
};

const hendleTextOnChange =
  (tableFilter: SimpleTableFilter, columnKey: string, key: string, operation: FilterOperation) => (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (!value) {
      tableFilter.removeFilterDataInput(columnKey, key, operation);
    } else {
      tableFilter.setFilterDataInput(columnKey, key, operation, value);
    }
  };

const hendleNumberOnChange =
  (tableFilter: SimpleTableFilter, columnKey: string, key: string, operation: FilterOperation) => (e: ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value);
    if (!value || !isFinite(value)) {
      tableFilter.removeFilterDataInput(columnKey, key, operation);
    } else {
      tableFilter.setFilterDataInput(columnKey, key, operation, value);
    }
  };

const hendleSetOnChange = (tableFilter: SimpleTableFilter, columnKey: string, key: string, operation: FilterOperation) => (value: any[]) => {
  if (!value || value.length === 0) {
    tableFilter.removeFilterDataInput(columnKey, key, operation);
  } else {
    tableFilter.setFilterDataInput(columnKey, key, operation, value);
  }
};

const hendleDateOnChange = (tableFilter: SimpleTableFilter, columnKey: string, key: string, operation: FilterOperation) => (value: any) => {
  if (!value || !dayjs.isDayjs(value)) {
    tableFilter.removeFilterDataInput(columnKey, key, operation);
  } else {
    tableFilter.setFilterDataInput(columnKey, key, operation, value);
  }
};

const getSetValuesFromTable = (tableData: TableNode<GenericTableRow, GenericTableData> | null | undefined, columnKey: string): Set<any> => {
  const aggregated = new Set();

  if (!tableData) {
    return aggregated;
  }
  if (tableData.asTableItem.value && columnKey in tableData.asTableItem.value) {
    aggregated.add(tableData.asTableItem.value[columnKey]);
  }

  if (!tableData.childrenStore) {
    return aggregated;
  }

  for (const child of tableData.childrenStore.children) {
    for (const v of getSetValuesFromTable(child, columnKey)) {
      aggregated.add(v);
    }
  }

  return aggregated;
};

const InputListWrapper = ({ children }: { children: ReactElement | ReactElement[] }) => {
  return <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>{children}</div>;
};

const ColumnFilter = observer(
  ({
    tableData,
    tableFilter,
    column,
  }: {
    tableData: TableNode<GenericTableRow, GenericTableData>;
    tableFilter: SimpleTableFilter;
    column: Column;
  }): ReactElement | null => {
    let description: PopconfirmProps["description"] = null;

    const columnKey = column.key ?? column.dataKey ?? "unknown";
    if (column.type === "string") {
      description = (
        <InputListWrapper>
          <Input
            prefix="Содержит текст: "
            size="small"
            placeholder="Введите текст"
            onChange={hendleTextOnChange(tableFilter, columnKey, "input", "string_has")}
            value={tableFilter.getFilterDataInput(columnKey, "input", "string_has")}
          ></Input>
        </InputListWrapper>
      );
    } else if (column.type === "number") {
      description = (
        <InputListWrapper>
          <Input
            prefix="Больше или равно: "
            size="small"
            placeholder="Введите число"
            type="number"
            onChange={hendleNumberOnChange(tableFilter, columnKey, "input_gte", "number_gte")}
            value={tableFilter.getFilterDataInput(columnKey, "input_gte", "number_gte")}
          ></Input>
          <Input
            prefix="Меньше или равно: "
            size="small"
            placeholder="Введите число"
            type="number"
            onChange={hendleNumberOnChange(tableFilter, columnKey, "input_lte", "number_lte")}
            value={tableFilter.getFilterDataInput(columnKey, "input_lte", "number_lte")}
          ></Input>
        </InputListWrapper>
      );
    } else if (column.type === "set") {
      const selectOptions: CheckboxOptionType[] = [];
      const values = getSetValuesFromTable(tableData, columnKey);

      for (const value of values) {
        if (value == null) {
          continue;
        }
        selectOptions.push({
          label: formatData(value, tableFilter.formatDataOptions),
          value: value,
        });
      }

      if (values.has(null)) {
        selectOptions.push({
          label: "Нет данных",
          value: "null", // null - Некоректное значение для value чекбокса
        });
      }

      description = (
        <Checkbox.Group
          style={{ display: "flex", flexDirection: "column", gap: "2px", minWidth: 300 }}
          value={tableFilter.getFilterDataInput(columnKey, "input", "set_has")}
          onChange={hendleSetOnChange(tableFilter, columnKey, "input", "set_has")}
          options={selectOptions}
        />
      );
    } else if (column.type === "date") {
      const today = dayjs();

      const startValue = tableFilter.getFilterDataInput(columnKey, "input_gte", "date_gte");
      const endValue = tableFilter.getFilterDataInput(columnKey, "input_lte", "date_lte");

      const DatePredefinedButton = ({ children, start, end }: { children: ReactElement | string; start: Dayjs | null; end: Dayjs | null }) => {
        return (
          <Button
            size="small"
            onClick={() => {
              hendleDateOnChange(tableFilter, columnKey, "input_gte", "date_gte")(start);
              hendleDateOnChange(tableFilter, columnKey, "input_lte", "date_lte")(end);
            }}
            type={start && startValue && start.isSame(startValue, "day") && end && endValue && end.isSame(endValue, "day") ? "primary" : "default"}
          >
            {children}
          </Button>
        );
      };
      description = (
        <InputListWrapper>
          <DatePicker
            placeholder="Начальная дата"
            onChange={hendleDateOnChange(tableFilter, columnKey, "input_gte", "date_gte")}
            value={startValue}
            format="DD.MM.YYYY"
          />
          <DatePicker
            placeholder="Конечная дата"
            onChange={hendleDateOnChange(tableFilter, columnKey, "input_lte", "date_lte")}
            value={endValue}
            format="DD.MM.YYYY"
          />
          <DatePredefinedButton start={today} end={today}>
            Сегодня
          </DatePredefinedButton>
          <DatePredefinedButton start={today.subtract(7, "days")} end={today}>
            Посление семь дней
          </DatePredefinedButton>
          <DatePredefinedButton start={today.startOf("week").add(1, "days")} end={today.endOf("week").add(1, "days")}>
            Текущая неделя
          </DatePredefinedButton>
          <DatePredefinedButton start={today.startOf("month")} end={today.endOf("month")}>
            Текущий месяц
          </DatePredefinedButton>
          <DatePredefinedButton start={today.startOf("year")} end={today.endOf("year")}>
            Текущий год
          </DatePredefinedButton>
        </InputListWrapper>
      );
    }

    if (description !== null) {
      return (
        <Popconfirm
          afterOpenChange={(visible) => {
            if (!visible) {
              tableFilter.resetInputs();
            }
          }}
          onConfirm={() => {
            tableFilter.submitInputs(tableData);
          }}
          placement="bottom"
          title={
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", height: "24px" }}>
              Фильтр: {column.title ?? column.key}{" "}
              {tableFilter.isColumnHasInputFilterData(columnKey) && (
                <Button
                  onClick={() => {
                    tableFilter.clearInputDataForColumn(columnKey);
                  }}
                  type="text"
                  size="small"
                  icon={<DeleteOutlined />}
                />
              )}
            </div>
          }
          description={description}
          icon={<FilterFilled style={{ color: "inherit" }} />}
          okText="Применить"
          cancelText="Отмена"
        >
          <Button type="text" size="small" icon={tableFilter.isColumnHasSubmittedFilterData(columnKey) ? <FilterFilled /> : <FilterOutlined />} />
        </Popconfirm>
      );
    }

    return null;
  }
);

export { ColumnFilter, filterFunctionWrapper, resetAllFilters, SimpleTableFilter };
