import { FC } from "react";
import { observer } from "mobx-react";
import { PageFrame, TabItem } from "routing/pageFrame/pageFrame";

import { Preloader } from "features/preloader/preloader";
import { useECYStore } from "features/useMetrics/useECYStore";
import type { State } from "models/project/fact/fact";
import { useForecasts } from "models/project/fact/forecast/forecasts";

import { CompareScenario } from "./compareScenario/compareScenario";
import { FactorAnalysis } from "./factorAnalysis/factorAnalysis";
import { Infrastructure } from "./infrastructure/infrastructure";

const TABS: TabItem[] = [
  {
    key: "compareScenario",
    label: "Сравнение сценариев",
    children: <CompareScenario />,
  },
  {
    key: "factorAnalysis",
    label: "Факторный анализ",
    default: true,
    children: <FactorAnalysis />,
  },
  {
    key: "compareInfrastructure",
    label: "Инфраструктура",
    children: <Infrastructure />,
  },
];

const Comparison: FC<{ fact: State }> = observer(() => {
  return (
    <Preloader hooks={[useForecasts, useECYStore]}>
      <PageFrame title="Сравнение сценариев" tabs={TABS} tabsWidthFix={600} />
    </Preloader>
  );
});

export { Comparison };
