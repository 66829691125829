import { FC } from "react";
import { observer } from "mobx-react";

import { useTechForecastModel } from "features/techForecast/useTechForecastModel";
import { AllApproximations, ForecastMode, isVectorApproximation, NEED_K } from "services/back/techForecast/request";

import { UploadChart } from "../legacyInputs/uploadChart";

import { DropCurveCoefficientInput } from "./dropCurveCoefficientInput";
import { WellsAnalog } from "./wellsAnalog";

type MethodParamsProps = {
  method: AllApproximations | "displacementCharacteristics" | "wellsAnalog";
  mode: ForecastMode;
};

const MethodParams: FC<MethodParamsProps> = observer(({ method, mode }) => {
  const model = useTechForecastModel();
  if (method === "wellsAnalog" && (mode === "liquid" || mode === "oil")) {
    return <WellsAnalog mode={mode} />;
  }

  if (isVectorApproximation(method)) {
    if (model.currentGroup === "base") {
      return null;
    }
    return (
      <>
        <UploadChart onSave={model.currentForecast.settings[mode].vectorHolder}>Загрузить ХВ</UploadChart>
        <a style={{ textAlign: "right" }} href="/static/XB.xlsx" download="XB.xlsx">
          Скачать шаблон файла с ХВ
        </a>
      </>
    );
  }
  return (
    <>
      {<DropCurveCoefficientInput param="a" mode={mode} />}
      {mode !== "waterCut" && method === NEED_K && <DropCurveCoefficientInput param="k" mode={mode} />}
    </>
  );
});

export { MethodParams };
