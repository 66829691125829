import { type FC, useMemo } from "react";
import { Button, Dropdown, Tooltip } from "antd";
import { observer } from "mobx-react";
import { ItemType } from "rc-menu/lib/interface";

import { useForecast } from "models/project/fact/forecast/forecast";
import type { CalculationVariant } from "services/back/calculate/calculate";

type CalculateModeOption = ItemType & {
  key: CalculationVariant;
};

type ButtonMenuItem = { type: "group"; children: CalculateModeOption[] } | CalculateModeOption;

type CalculateButtonProps = {
  edit?: boolean;
};

const CalculateButton: FC<CalculateButtonProps> = observer(({ edit = true }) => {
  const forecast = useForecast()!;
  const disabled = forecast.infrastructure.infrastructureValidator.cannotCalculate;

  const items = useMemo(
    (): ButtonMenuItem[] => [
      { key: "classic", label: "без учета инфраструктуры" },
      {
        type: "group",
        label: <Tooltip title={disabled ? "Инфраструктура еще не построена" : undefined}>с учетом инфраструктуры</Tooltip>,
        children: [
          { key: "infrastructure-total-only", label: "без учета в поскважинной экономике", disabled },
          { key: "infrastructure-by-well", label: "с учетом в поскважинной экономике", disabled },
        ],
      },
    ],
    [disabled]
  );

  const calculate = (e: { key: string }) => {
    forecast.calculate(e.key as CalculationVariant);
  };

  return (
    <Tooltip title={!edit && "Для выполнения действия недостаточно полномочий вашей роли в проекте"}>
      <Dropdown disabled={!edit} menu={{ items, onClick: calculate }}>
        <Button>{forecast.savedResultTS !== null ? "Пересчитать" : "Рассчитать"}</Button>
      </Dropdown>
    </Tooltip>
  );
});

export { CalculateButton };
