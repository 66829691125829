import { FC, MouseEventHandler, useCallback, useMemo, useState } from "react";
import { Virtuoso } from "react-virtuoso";
import { Axis, ChartContext } from "@okopok/axes_context";
import { Button, ButtonProps, Checkbox, Typography } from "antd";
import classNames from "classnames";
import { computed, makeObservable, observable, ObservableSet, runInAction, transaction } from "mobx";
import { observer } from "mobx-react";

import { Format } from "elements/format/format";
import { ModelContentComponentType } from "elements/modal/modal";
import { type Analogs } from "features/techForecast/models/well/analogs";
import { axisInference, META } from "features/techForecast/models/well/wellTechChartChannelsMeta";
import { useForecast } from "models/project/fact/forecast/forecast";
import { Wells } from "models/project/fact/well/wells";
import { AnalogsResult, getAnalogs, itemKey } from "services/back/techForecast/request";
import { Production } from "services/back/techForecast/techForecast";
import { randomColor } from "utils/random";

import { Line } from "../results/chart/elements/line";
import { LineDataModel } from "../results/chart/elements/lineDataModel";

import cn from "./wellsAnalogsModal.module.less";

function capitalizeFirstLetter(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

class Chart {
  readonly axes: Axis[];
  lines: Record<string, LineDataModel>;
  current: string = "";
  hidden: ObservableSet<string>;
  updated: Pick<AnalogsResult, "liquidRateM3Fitted" | "oilRateTFitted"> | null = null;
  updatedHidden: string;

  constructor(private readonly analogs: Analogs) {
    const currentAnalogs = analogs.currentAnalogs!;
    const curveKey = ({ liquid: "liquidRateM3", oil: "oilRateT" } as const)[analogs.mode];
    this.hidden = observable.set([...analogs.hidden.values()]);
    this.updatedHidden = JSON.stringify([...analogs.hidden.values()].sort());
    this.axes = [
      new Axis("y", "left", { min: 0, max: 1 }, [META[curveKey].title, axisInference(curveKey)].filter(Boolean).join(", ")),
      new Axis("x", "bottom", { min: 0, max: currentAnalogs.items[0].factProduction[curveKey].length }, "Месяц"),
    ];
    this.lines = Object.fromEntries(
      currentAnalogs.items.map((item) => {
        const key = itemKey(item);
        return [
          key,
          new LineDataModel({
            y: item.factProduction[`normalized${capitalizeFirstLetter(curveKey)}` as keyof Production] as number[],
            x: this.axes[1].domain,
            axisKey: "y",
            key,
            color: randomColor(),
            className: cn.analog,
          }),
        ] as const;
      })
    );
    this.lines.fitting = new LineDataModel({
      y: currentAnalogs[`${curveKey}Fitted`].y,
      x: this.axes[1].domain,
      axisKey: "y",
      key: "fitting",
      color: "green",
      className: cn.fitting,
    });

    this.lines.rawFitting = new LineDataModel({
      y: currentAnalogs[`${curveKey}Fitted`].yRaw!,
      x: this.axes[1].domain,
      axisKey: "y",
      key: "rawFitting",
      color: "green",
      className: cn.rawFitting,
    });

    makeObservable(this, {
      current: observable,
      updatedHidden: observable,
      currentLine: computed,
    });
  }

  updateFittings = async () => {
    const result = await getAnalogs({
      ...this.analogs.analogsRequest,
      wellIds: this.analogs.currentAnalogs?.items.filter((v) => !this.hidden.has(itemKey(v))).map((v) => v.wellId) ?? null,
    });

    const curveKey = ({ liquid: "liquidRateM3", oil: "oilRateT" } as const)[this.analogs.mode];
    const fitting = result[`${curveKey}Fitted`];
    this.updated = {
      liquidRateM3Fitted: result.liquidRateM3Fitted,
      oilRateTFitted: result.oilRateTFitted,
    };
    runInAction(() =>
      transaction(() => {
        this.updatedHidden = JSON.stringify([...this.hidden.values()].sort());
        this.lines.fitting.y = fitting.y;
        this.lines.rawFitting.y = fitting.yRaw!;
      })
    );
  };

  get isUpdated(): boolean {
    return this.updatedHidden === JSON.stringify([...this.hidden.values()].sort());
  }

  readonly toggleHidden = (key: string) => () => {
    runInAction(() => {
      this.hidden[this.hidden.has(key) ? "delete" : "add"](key);
    });
  };

  get selectedAmount(): number | null {
    let counter = 0;
    const { currentAnalogs } = this.analogs;
    if (currentAnalogs === undefined) {
      return null;
    }
    const { hidden } = this;
    if (hidden.size === 0) {
      return currentAnalogs.items.length;
    }
    for (const item of currentAnalogs.items)
      if (hidden.has(itemKey(item))) {
        counter += 1;
      }
    return currentAnalogs.items.length - counter;
  }

  get amount(): number | null {
    return this.analogs.amount;
  }

  get currentLine(): LineDataModel | undefined {
    return this.lines[this.current];
  }

  currentHolder(key: string) {
    return () => runInAction(() => (this.current = key));
  }
}

const Item: FC<{
  wells: Wells;
  data: AnalogsResult["items"][number];
  chart: Chart;
}> = observer(({ data, wells, chart }) => {
  const key = itemKey(data);
  return (
    <Checkbox
      className={classNames(cn.checkbox, chart.hidden.has(key) && cn.hidden)}
      onMouseEnter={chart.currentHolder(key)}
      onChange={chart.toggleHidden(key)}
      checked={!chart.hidden.has(key)}
    >
      {wells.at(data.wellId)?.title}
    </Checkbox>
  );
});

const ITEM_CONTENT = (_: number, data: AnalogsResult["items"][number], { wells, chart }: { wells: Wells; chart: Chart }) => {
  return <Item data={data} wells={wells} chart={chart} />;
};

const AwaitButton: FC<ButtonProps> = ({ onClick, ...props }) => {
  const [isLoading, setLoading] = useState(false);
  const finalOnClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      const clc = onClick?.(e) as any;
      if (clc instanceof Promise) {
        setLoading(true);
        clc.finally(() => setLoading(false));
      }
    },
    [onClick, setLoading]
  );
  return <Button loading={isLoading} onClick={finalOnClick} {...props} />;
};

const List: FC<{ chart: Chart; items: any }> = observer(({ items, chart }) => {
  const wells = useForecast()!.wells;
  return (
    <div className={cn.list}>
      <Typography.Title level={4}>
        Скважины аналоги
        <div className={cn.counters}>
          <Format>{chart.selectedAmount}</Format>/<Format>{chart.amount}</Format>
        </div>
      </Typography.Title>
      <div className={cn.items}>
        <Virtuoso itemContent={ITEM_CONTENT} data={items} context={{ wells, chart }} />
      </div>
      <div className={cn.update}>
        <AwaitButton disabled={chart.isUpdated} onClick={chart.updateFittings}>
          Перестроить тренд
        </AwaitButton>
      </div>
    </div>
  );
});

const HidableLine: FC<{ chart: Chart; lineInfo: LineDataModel; className?: string }> = observer(({ chart, lineInfo, className }) => {
  if (chart.hidden.has(lineInfo.key)) {
    return null;
  }
  return <Line lineInfo={lineInfo} className={className} />;
});

const WellsAnalogsModal: ModelContentComponentType<void, Analogs> = observer(({ dataRef, setLoading, setOnAccept }) => {
  const analogs = dataRef.current;
  const items = analogs.currentAnalogs?.items ?? [];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const chart = useMemo(() => new Chart(analogs), []);

  setOnAccept(async () => {
    if (!chart.isUpdated) {
      setLoading(true);
      await chart.updateFittings();
      setLoading(false);
    }
    if (chart.updated !== null) {
      analogs.applyModal(chart.hidden, chart.updated);
    }
    return { result: true };
  });
  return (
    <div className={cn.layout}>
      <ChartContext className={cn.chart} axes={chart.axes}>
        {Object.values(chart.lines).map((lineInfo) => (
          <HidableLine key={lineInfo.key} lineInfo={lineInfo} chart={chart} />
        ))}
        {chart.currentLine && <HidableLine chart={chart} lineInfo={chart.currentLine} className={cn.current} />}
      </ChartContext>
      <List chart={chart} items={items} />
    </div>
  );
});

export { WellsAnalogsModal };
