import { FC } from "react";
import { Button, ButtonProps, Tooltip } from "antd";

import { useProject } from "models/project/project";
import { getUserPermission, Sections } from "services/back/roles";

type PermissionButtonProps = ButtonProps & {
  section: Sections;
};

const PermissionButton: FC<PermissionButtonProps> = ({ section, disabled, ...props }) => {
  const project = useProject()!;
  const edit = getUserPermission(project)[section];
  return (
    <Tooltip title={edit ? "" : "Для выполнения действия недостаточно полномочий вашей роли в проекте"}>
      <Button disabled={disabled || !edit} {...props} />
    </Tooltip>
  );
};

export { PermissionButton };
