import { ResultValidationChecker, ResultValidationMessage } from "routing/outlines/result/resultInformer";

import { Forecast } from "models/project/fact/forecast/forecast";

const getWellsBaseTechValidator = (forecast: Forecast | null | undefined) => {
  return new ResultValidationChecker(() => {
    // base fond
    const result: ResultValidationMessage[] = [];

    if (!forecast) {
      result.push(
        new ResultValidationMessage(
          "error",
          "Прогноз не выбран или не загружен, невозможно проверить скважины базового фонда",
          ["techForecast"]
        )
      );
      return result;
    }

    const fact = forecast.fact;

    const debugWellsWithoutProductionFactData = [];
    const prodWellsWithoutProduction = [];
    const injWellsWithoutInjection = [];

    for (const well of fact.wells.wells) {
      const wellData = fact.production.wellData(well.id);
      if (wellData === undefined) {
        debugWellsWithoutProductionFactData.push(well.title);
        continue;
      }
      const wellFcData = forecast.production.wellData(well.id);

      const lastPeriods = [...wellData.dataByStratums.values()]
        .filter((arr) => arr.length > 0) // filter empty stratum data if present
        .map((arr) => arr[arr.length - 1]) // get last stratum period
        .filter(
          (period) => period.status !== "inj" && period.range.indexDate(fact.project.actualStateDate) !== undefined
        ); // period includes last fact month and not 'idle'

      for (const period of lastPeriods) {
        if (period.status === "prod") {
          // check forecast "prod" data
          if (wellFcData?.isMining !== true) {
            prodWellsWithoutProduction.push(well.title);
          }
        } else if (period.status === "inj") {
          // check forecast "inj" data
          if (wellFcData?.isInjecting !== true) {
            injWellsWithoutInjection.push(well.title);
          }
        }
      }
    }

    if (debugWellsWithoutProductionFactData.length > 0) {
      result.push(
        new ResultValidationMessage(
          "debug",
          `Для скважин базового фонда нет данных по добыче: ${debugWellsWithoutProductionFactData.join(", ")}`,
          ["techForecast"]
        )
      );
    }

    if (prodWellsWithoutProduction.length > 0) {
      result.push(
        new ResultValidationMessage(
          "warning",
          `Действующие добывающие скважины базового фонда без прогноза добычи: ${prodWellsWithoutProduction.join(
            ", "
          )}`,
          ["techForecast"]
        )
      );
    }
    if (injWellsWithoutInjection.length > 0) {
      result.push(
        new ResultValidationMessage(
          "warning",
          `Действующие нагнетательные скважины базового фонда без прогноза закачки: ${
            (injWellsWithoutInjection.join(", "), ["techForecast"])
          }`
        )
      );
    }

    return result;
  });
};

const getWellsForecastTechValidator = (forecast: Forecast | null | undefined) => {
  return new ResultValidationChecker(() => {
    // new fond
    const result: ResultValidationMessage[] = [];

    if (!forecast) {
      result.push(
        new ResultValidationMessage(
          "error",
          "Прогноз не выбран или не загружен, невозможно проверить скважины нового фонда",
          ["techForecast"]
        )
      );
      return result;
    }

    const wellsWithoutForecasts = [];
    for (const well of forecast.wells.wells) {
      const wellFcData = forecast.production.wellData(well.id);

      if (wellFcData === undefined) {
        wellsWithoutForecasts.push(well.title);
      }
    }

    if (wellsWithoutForecasts.length > 0) {
      result.push(
        new ResultValidationMessage(
          "warning",
          `Скважины нового фонда без прогноза технологических показателей: ${wellsWithoutForecasts.join(", ")}`,
          ["techForecast"]
        )
      );
    }

    return result;
  });
};

const getGtmTechValidator = (forecast: Forecast | null | undefined) => {
  return new ResultValidationChecker(() => {
    // gtms
    const result: ResultValidationMessage[] = [];

    if (!forecast) {
      result.push(
        new ResultValidationMessage("error", "Прогноз не выбран или не загружен, невозможно проверить ГТМ", [
          "techForecast",
        ])
      );
      return result;
    }

    type WellGtmsContainer = {
      wellTitle: string;
      gtmTypes: string[];
    };
    const gtmsWithoutForecasts = new Map<number, WellGtmsContainer>();
    for (const intervention of forecast.interventions.interventions) {
      const interventionFcData = forecast.production.interventionData(intervention.id);

      if (interventionFcData === undefined) {
        if (!gtmsWithoutForecasts.has(intervention.wellId)) {
          let wellTitle = intervention.well?.title;
          console.assert(wellTitle !== undefined);
          wellTitle ??= intervention.wellId.toString();
          gtmsWithoutForecasts.set(intervention.wellId, { wellTitle, gtmTypes: [] });
        }
        let typeName = intervention.typeName;
        console.assert(typeName !== undefined && typeName !== null);
        typeName ??= `${intervention.data.gtmTypeId}`;
        gtmsWithoutForecasts.get(intervention.wellId)!.gtmTypes.push(typeName);
      }
    }

    if (gtmsWithoutForecasts.size > 0) {
      const formatGtmLine = ({ wellTitle, gtmTypes }: WellGtmsContainer): string => {
        return `Скважина ${wellTitle}: ${gtmTypes.join(", ")}`;
      };
      const formatGTMsMap = (): string => {
        return [...gtmsWithoutForecasts.values()].map(formatGtmLine).join("\n");
      };
      result.push(
        new ResultValidationMessage("warning", `ГТМы без прогноза технологических показателей:\n${formatGTMsMap()}`, [
          "techForecast",
        ])
      );
    }

    return result;
  });
};

export { getGtmTechValidator, getWellsBaseTechValidator, getWellsForecastTechValidator };
