import { action, computed, makeObservable, observable, override, runInAction, transaction, when } from "mobx";

import { Lazy } from "models/lazy";
import {
  AllApproximations,
  Analogs as AnalogsType,
  BindingMode,
  ForecastMode,
  isScalarApproximation,
  isVectorApproximation,
  NEED_K,
  PARAM_META,
  SCALAR_APPROXIMATIONS,
  ScalarApproximations,
  VECTOR_APPROXIMATIONS,
  VectorApproximations,
} from "services/back/techForecast/request";
import { conditionally } from "utils/conditionally";

import { Analogs as AnalogsModel } from "./analogs";
import type { WellTechForecast } from "./wellTechForecast";

type ForecastMethod = AllApproximations | "wellsAnalog" | "displacementCharacteristics";

type Scalars = Record<
  ScalarApproximations,
  {
    a: number | null;
    k?: number | null;
  }
>;

type Vectors = Record<
  string,
  {
    x: number[] | null;
    y: number[] | null;
  }
>;

type ScalarDump = { fnType: ScalarApproximations; a: number | null; k?: number | null };
type VectorDump = { fnType: VectorApproximations; x: number[] | null; y: number[] | null };

const isScalarDump = (dump: ScalarDump | VectorDump | AnalogsType): dump is ScalarDump =>
  "fnType" in dump && isScalarApproximation(dump.fnType) && !("x" in dump);

const isWellsAnalogDump = (dump: ScalarDump | VectorDump | AnalogsType): dump is AnalogsType => !("fnType" in dump);

type MethodsSave = {
  method: ForecastMethod;
  factDomain: { min: number; max: number } | null;
  emptyDots: number[];
  binding: "extrapolate" | "last_fact" | number;
  scalars: Scalars;
  vectors: Vectors;
  analogs: AnalogsType | null;
};

class Methods {
  public method: ForecastMethod = "geometric_progression";
  // null если не прогноз факта или факт не грузился
  public factDomain: { min: number; max: number } | null | undefined;
  public readonly emptyDots = observable.set<number>();
  public binding: "extrapolate" | "last_fact" | number = "last_fact";

  private readonly scalars: Scalars;

  public readonly vectors: Vectors = Object.fromEntries(
    Array.from(VECTOR_APPROXIMATIONS.values(), (key) => [
      key,
      {
        x: null,
        y: null,
      },
    ])
  ) as Vectors;

  #analogs = new Lazy(() => (this.mode !== "waterCut" ? new AnalogsModel(this.mode, this.fc) : null));

  constructor(private readonly mode: ForecastMode, private readonly fc: WellTechForecast) {
    const { group } = fc;
    this.binding = group === "base" ? "last_fact" : fc.initialBinding(mode);
    this.scalars = Object.fromEntries(
      Array.from(SCALAR_APPROXIMATIONS.values(), (key) => [
        key,
        {
          a: group === "base" ? null : PARAM_META[key as ScalarApproximations].a.default[this.mode],
          ...conditionally(key === "hyperbolic", {
            k: group === "base" ? null : PARAM_META.hyperbolic.k.default[mode],
          }),
        },
      ])
    ) as Scalars;

    makeObservable<Methods, "scalars">(this, {
      factDomain: observable,
      method: observable,
      scalars: observable,
      vectors: observable,
      binding: observable,
      bindingValue: computed,
      bindingMode: computed,
      applyDump: action,
      toSave: computed,
      fromSave: action,
    });
  }

  static inheritedObservable = {
    factDomain: override,
    method: override,
    scalars: override,
    vectors: override,
    binding: override,
    bindingValue: override,
    bindingMode: override,
    applyDump: override,
    analogs: override,
    toSave: override,
    fromSave: override,
  };

  get bindingMode(): BindingMode {
    return typeof this.binding === "number" ? "manual" : this.binding;
  }

  bindingModeHolder = (value: BindingMode) =>
    runInAction(() => {
      if (value === "manual") {
        when(() => this.fc.fact.isLoading === false).then(() =>
          runInAction(() => {
            this.binding = this.fc.lastFactBinding(this.mode)!;
          })
        );
      } else {
        this.binding = value;
      }
    });

  get bindingValue(): number | null {
    return typeof this.binding === "number" ? this.binding : null;
  }

  bindingValueHolder = (value: number | null) => {
    if (value) {
      this.binding = value;
    }
  };

  get a(): number | null {
    if (!isScalarApproximation(this.method)) {
      console.error(`a getter requested. Method ${this.method} must be a scalar`);
      return null;
    }
    return this.scalars[this.method].a;
  }

  get k(): number | null {
    if (NEED_K !== this.method) {
      console.error(`k getter requested. Method ${this.method} must be a ${NEED_K}`);
      return null;
    }
    const result = this.scalars[this.method].k;
    console.assert(result !== undefined, `undefined значение для значения k методе ${this.method}`);
    return result as null | number;
  }

  aHolder = (value: number | null) => {
    runInAction(() => {
      if (!isScalarApproximation(this.method)) {
        console.error(`aHolder has no effect. Method ${this.method} must be a scalar`);
        return;
      }
      this.scalars[this.method].a = value;
    });
  };

  kHolder = (value: number | null) => {
    runInAction(() => {
      if (NEED_K !== this.method) {
        console.error(`aHolder has no effect. Method ${this.method} must be a ${NEED_K}`);
        return;
      }
      this.scalars[this.method].k = value;
    });
  };

  vectorHolder = (value: { x: number[]; y: number[] }) => {
    runInAction(() => {
      this.vectors[this.method] = value;
    });
  };

  methodSelectionHolder = (selection: any) =>
    runInAction(() => {
      this.method = selection as ForecastMethod;
    });

  get dump() {
    if (isScalarApproximation(this.method)) {
      console.assert(isScalarApproximation(this.method));
      const { a } = this;
      return {
        fnType: this.method,
        ...(this.method === NEED_K
          ? conditionally(a !== null && this.k !== null, { a, k: this.k })
          : conditionally(a !== null, { a })),
      } as ScalarDump;
    } else if (isVectorApproximation(this.method)) {
      // иначе это векторные параметры обводнённости или ручное задание прогноза
      return {
        fnType: this.method as any,
        ...this.vectors[this.method],
      } as VectorDump;
    }
    if (this.method === "wellsAnalog") {
      return this.analogs.dump;
    }
    console.error("critical state analyse fail");
    return null as never;
  }

  get toSave(): MethodsSave {
    return {
      method: this.method,
      factDomain: this.factDomain ?? null,
      emptyDots: [...this.emptyDots.values()],
      binding: this.binding,
      scalars: this.scalars,
      vectors: this.vectors,
      analogs: this.method === "wellsAnalog" ? this.analogs.save : null,
    };
  }

  get analogs(): AnalogsModel {
    console.assert(this.mode !== "waterCut", "Обращение к аналогам в режиме прогноза по жидкости");
    return this.#analogs.value!;
  }

  fromSave(data: MethodsSave) {
    transaction(() => {
      this.method = data.method;
      this.factDomain = data.factDomain;
      this.emptyDots.replace(observable.set(data.emptyDots));
      this.binding = data.binding;
      if (data.analogs !== null) {
        this.analogs.fromSave(data.analogs);
      }
      for (const [key, val] of Object.entries(data.scalars)) {
        this.scalars[key as ScalarApproximations] = val;
      }
      for (const [key, val] of Object.entries(data.vectors)) {
        this.vectors[key as VectorApproximations] = val;
      }
    });
  }

  applyDump(value: ScalarDump | VectorDump) {
    this.method = value.fnType;
    if (isScalarDump(value)) {
      this.aHolder(value.a);
      if (value.k) {
        this.kHolder(value.k);
      }
    } else {
      this.vectors[value.fnType] = {
        x: value.x,
        y: value.y,
      };
    }
  }

  get isValid(): boolean {
    const { dump } = this;
    if (dump === null) {
      return false;
    }
    if (this.fc.group === "base") {
      return true;
    }
    if (isScalarDump(dump)) {
      return typeof dump.a === "number" && (typeof dump.k === "number" || dump.fnType !== NEED_K);
    } else if (isWellsAnalogDump(dump)) {
      return true;
    } else {
      return Array.isArray(dump.x) && Array.isArray(dump.y);
    }
  }
}

export { type ForecastMethod, Methods, type MethodsSave, type ScalarDump };
