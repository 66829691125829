import { Result } from "services/back/calculate/calculate";
import { Metric, MetricTree } from "services/finance/utils";

import type { MetricsSources } from "../models/tableLayout";
import { INVEST_METRICS_SOURCES, OPERATING_METRICS_SOURCES } from "../models/tableLayout";

function* ofMetricsTreeLimited<T extends { children?: T[] }>(
  metrics: T[],
  maxLevel: number | undefined = undefined,
  level: number = 0
): Generator<T> {
  if (maxLevel !== undefined && level >= maxLevel) {
    return;
  }
  for (const metric of metrics) {
    yield metric;
    if (metric.children === undefined) {
      continue;
    }
    yield* ofMetricsTreeLimited(metric.children, maxLevel, level + 1);
  }
}

function extractFromMetricsTree(tree: MetricTree[], metricsSources: MetricsSources): Record<string, number | null> {
  const values: Record<string, number | null> = {};
  const flatten = [...ofMetricsTreeLimited(tree)];
  for (const [metricTitle, maxLevel] of Object.entries(metricsSources)) {
    const metricRoot = flatten.find((metric) => metric.title === metricTitle);
    if (!metricRoot) {
      continue;
    }
    for (const metric of ofMetricsTreeLimited([metricRoot], maxLevel)) {
      values[metric.title] =
        metric.values?.reduce((acc, cur) => {
          acc = (acc || cur) && (acc ?? 0) + (cur ?? 0); // null if both null, sum otherwise
          return acc;
        }, null) ?? null;
    }
  }
  return values;
}

function extractMainResults(schema: Metric[]): Record<string, number | null> {
  const values: Record<string, number | null> = {};
  for (const metric of schema) {
    let title = metric.title.trim();
    if (title.endsWith(" 1")) {
      title = `${title.slice(0, -2)} за прогнозный период`; // 1 - прогнозный период
    } else if (title.endsWith(" 2")) {
      title = title.slice(0, -2); // 2 - весь период
    }
    values[title] = metric.values?.[0] ?? null;
  }
  return values;
}

function extractComparisonValues(result: Result): Record<string, number | null> {
  let values: Record<string, number | null> = {};
  try {
    values = {
      ...extractMainResults(result.result.schema),
      ...extractFromMetricsTree(result.schemaOperation, OPERATING_METRICS_SOURCES),
      ...extractFromMetricsTree(result.schemaInvest, INVEST_METRICS_SOURCES),
    };
  } catch (error) {
    console.error(`Can't extract comparison values from result:\n ${error}`);
  }
  return values;
}

export { extractComparisonValues };
