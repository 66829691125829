import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import dayjs, { Dayjs } from "dayjs";
import { action, computed, makeObservable, observable, reaction } from "mobx";

import { PipeInfo, PipesModel } from "features/infrastructure/infrastructureTable/dinamicTable/tableManager/pipes";
import { Forecast } from "models/project/fact/forecast/forecast";
import { TreeRoot } from "models/tree/tree";

type MetricsByForecast = {
  [forecastId: number]: {
    value: number | null;
    delta: number | null;
  };
};
type DRow = MetricsByForecast & {
  param: string;
};

const compareParams: Record<number, string> = {
  4: "firstPressure",
  5: "secondPressure",
  6: "pressureDelta",
  7: "pressureGradient",
  8: "velocity",
  9: "fluidRateM3",
};

class InfrastructureTableStore extends TableNode<DRow, Datum> {
  public readonly root = this;
  public selectedForecasts: Forecast[] = [];

  public currentCompareValue: number | null;
  public showAllPipes = false;
  public favoriteScenario: number | null = null;
  public calculateStore: Map<
    number,
    { pipes: { uuid: string; title: string; params: PipeInfo[] }[]; ts: Dayjs } | null
  > = new Map();

  constructor(public readonly tree: TreeRoot<Forecast>, public readonly forecasts: Forecast[]) {
    super();

    this.currentCompareValue = null;

    makeObservable<InfrastructureTableStore, "onTreeSelect" | "init">(this, {
      selectedForecasts: observable.ref,
      favoriteScenario: observable,
      currentCompareValue: observable,
      calculateStore: observable,
      showAllPipes: observable,

      init: action,
      onTreeSelect: action,
      onFavoriteSelect: action,
      setCurrentCompareValue: action,
      onChangeShowAllPipes: action,

      commonPipes: computed,
      getRows: computed,
      allPipes: computed,
      pipes: computed,
    });

    reaction(
      () => tree.selectedItems,
      (selectedForecasts) => {
        this.onTreeSelect(selectedForecasts.map((node) => node.item).filter((id): id is Forecast => id !== null));
        this.childrenStore = new ChildrenStoreArray(this, this.getRows);
        if (
          this.favoriteScenario !== null &&
          this.selectedForecasts.find((fc) => fc.id === this.favoriteScenario) === undefined
        ) {
          this.favoriteScenario = null;
        }
      },
      { fireImmediately: true }
    );

    reaction(
      () => this.getRows,
      () => {
        this.childrenStore = new ChildrenStoreArray(this, this.getRows);
      },
      { fireImmediately: true }
    );

    this.init();
  }

  private async init() {
    this.forecasts.forEach(async (fc) => {
      const data = await fc.infrastructure.calculateStore.source?.getItem();
      if (data && data.data.values) {
        const infrastructure = fc.infrastructure;
        const allPipes = PipesModel.objectSegmentsValues(data.data.values, "prod", infrastructure.pipes.data ?? []);
        this.calculateStore.set(fc.id, { pipes: allPipes, ts: dayjs(data.data.ts) });
      } else {
        this.calculateStore.set(fc.id, null);
      }
    });
  }

  public onChangeShowAllPipes = () => {
    this.showAllPipes = !this.showAllPipes;
  };

  public get getRows(): Datum[] {
    const result: Datum[] = [
      new Datum(this, {
        title: "Общее количество новых участков, шт",
        rowId: 1,
      }),
      new Datum(this, {
        title: "Количество сравниваемых участков, шт",
        rowId: 2,
      }),
      ...(this.pipes ? [...this.pipes].map((item) => new Datum(this, { title: item })) : []),
    ];
    return result;
  }

  public setCurrentCompareValue = (value: number) => {
    this.currentCompareValue = value;
  };

  public get pipes() {
    return this.showAllPipes ? this.allPipes : this.commonPipes;
  }

  public get commonPipes() {
    const sets = this.selectedForecasts.map((fc) => {
      const infrastructure = fc.infrastructure;
      const pipes = infrastructure.pipes.data?.filter(({ category }) => category === "Новый объект");
      return new Set(pipes?.map((pipe) => pipe.title));
    });
    return sets.reduce((prev, curr) => new Set([...prev].filter((item) => curr.has(item))), sets[0]);
  }

  public get allPipes() {
    const sets = this.selectedForecasts.map((fc) => {
      const infrastructure = fc.infrastructure;
      const pipes = infrastructure.pipes.data?.filter(({ category }) => category === "Новый объект");
      return new Set(pipes?.map((pipe) => pipe.title));
    });
    return sets.reduce((prev, curr) => new Set([...prev, ...curr]), sets[0]);
  }

  public onFavoriteSelect = (id: number) => {
    if (this.favoriteScenario === id) {
      this.favoriteScenario = null;
    } else {
      this.favoriteScenario = id;
    }
  };

  private onTreeSelect(selectedForecastsIds: Forecast[]) {
    this.selectedForecasts = selectedForecastsIds;
  }
}

class Datum extends TableNode<DRow, Datum> {
  public get root(): InfrastructureTableStore {
    return this.parent.root;
  }

  constructor(
    private readonly parent: Datum | InfrastructureTableStore,
    private readonly row: {
      title: string;
      rowId?: number;
    }
  ) {
    super(parent, { isExpandedChildren: true });

    makeObservable(this, {
      rowData: computed,
      preparedRow: computed,
    });

    this.childrenStore = null;
  }

  public get rowData(): Map<number, number | null | undefined> {
    if (this.row.rowId === 1) {
      return new Map(
        this.root.forecasts.map((fc) => {
          const infrastructure = fc.infrastructure;
          const pipes = infrastructure.pipes.data
            ?.filter(({ category }) => category === "Новый объект")
            .map((pipe) => pipe.title);
          return [fc.id, pipes?.length];
        })
      );
    }
    if (this.row.rowId === 2) {
      return new Map(
        this.root.forecasts.map((fc) => {
          return [fc.id, [...this.root.pipes].length];
        })
      );
    }
    if (this.root.currentCompareValue === null) {
      return new Map(
        this.root.forecasts.map((fc) => {
          return [fc.id, null];
        })
      );
    }
    if (this.root.currentCompareValue === 1) {
      return new Map(
        this.root.forecasts.map((fc) => {
          const infrastructure = fc.infrastructure;
          const pipe = infrastructure.pipes.data
            ?.filter(({ category }) => category === "Новый объект")
            .find((pipe) => pipe.title === this.row.title);
          return [fc.id, pipe?.diameterOuter ?? null];
        })
      );
    }
    if (this.root.currentCompareValue === 2) {
      return new Map(
        this.root.forecasts.map((fc) => {
          const infrastructure = fc.infrastructure;
          const pipe = infrastructure.pipes.data
            ?.filter(({ category }) => category === "Новый объект")
            .find((pipe) => pipe.title === this.row.title);
          return [fc.id, pipe?.thickness ?? null];
        })
      );
    }
    if (this.root.currentCompareValue === 3) {
      return new Map(
        this.root.forecasts.map((fc) => {
          const infrastructure = fc.infrastructure;
          const pipe = infrastructure.pipes.data
            ?.filter(({ category }) => category === "Новый объект")
            .find((pipe) => pipe.title === this.row.title);
          return [fc.id, pipe?.length ?? null];
        })
      );
    }

    if ([4, 5, 6, 7, 8, 9].includes(this.root.currentCompareValue)) {
      try {
        return new Map(
          this.root.forecasts.map((fc) => {
            if (this.root.calculateStore.get(fc.id) === undefined) {
              return [fc.id, undefined];
            }
            if (this.root.calculateStore.get(fc.id) === null) {
              return [fc.id, null];
            }

            const value = this.root.calculateStore
              .get(fc.id)
              ?.pipes.find((pipe) => pipe.title === this.row.title)
              ?.params.map((param) => param[compareParams[this.root.currentCompareValue!] as keyof PipeInfo])
              .filter((item) => item !== undefined);
            if (value !== undefined) {
              return [fc.id, Math.max(...(value as number[]))];
            }

            return [fc.id, null];
          })
        );
      } catch (error) {
        console.error(error);
      }
    }

    return new Map();
  }

  public get preparedRow() {
    const value = this.root.favoriteScenario !== null ? this.rowData?.get(this.root.favoriteScenario) : null;
    return this.root.selectedForecasts.map((fc) => {
      return [
        fc.id,
        {
          value: this.rowData?.get(fc.id),
          delta:
            typeof this.rowData?.get(fc.id) === "number" && typeof value === "number"
              ? this.rowData?.get(fc.id)! - value
              : null,
        },
      ];
    });
  }

  asDRow = (): DRow => {
    return {
      param: this.row.title,
      ...Object.fromEntries(this.preparedRow),
    };
  };
}

export { InfrastructureTableStore };
export type { DRow };
