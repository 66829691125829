import { computed, makeObservable, observable, runInAction } from "mobx";

import { getTechForecast, type WellTechProduction } from "services/back/techForecast/request";
import { getRandomUid } from "utils/random";

class WellTechForecastResult {
  #data = observable.box<null | undefined | WellTechProduction>();
  readonly uuid = getRandomUid();
  public wasShown: boolean = false;
  public isSubmitted: boolean = false;

  constructor(dump: Parameters<typeof getTechForecast>[0]) {
    getTechForecast(dump)
      .then((result) =>
        runInAction(() => {
          if (result.length === 0) {
            this.#data.set(null);
          }
          this.#data.set(result[0]);
        })
      )
      .catch(() => {
        // @todo обработка ошибки запроса
        this.#data.set(null);
      });

    makeObservable(this, {
      data: computed,
      isLoading: computed,
      isNull: computed,
      wasShown: observable,
      isSubmitted: observable,
    });
  }

  setShown = () =>
    runInAction(() => {
      this.wasShown = true;
    });

  get data() {
    return this.#data.get();
  }

  get isLoading() {
    return this.data === undefined;
  }

  get isNull() {
    if (this.data === null) {
      return true;
    }

    if (this.data === undefined) {
      return false;
    }

    // если все массивы (включая обводнённость) длинной в один элемент и дебиты нулевые а обводнённость null то это считается неудавшимся прогнозом
    const waterCut = this.data.forecastProduction.waterCutVol;
    const [waterCutFirst] = waterCut;
    return waterCut.length <= 1 && (waterCutFirst === null || waterCutFirst === undefined);
  }
}

export { WellTechForecastResult };
