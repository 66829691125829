import { FC } from "react";
import { Button, ColorPicker } from "antd";
import { observer } from "mobx-react";

import { Eye } from "elements/icons/eye";
import { useTechForecastModel } from "features/techForecast/useTechForecastModel";

import cn from "./legend.module.less";

const Legend: FC = observer(() => {
  const model = useTechForecastModel();
  const chart = model.currentForecast.chart;
  return (
    <ul className={cn.items}>
      {chart.channelGroups.map(({ title, key, toggleVisible, isVisible, isPartiallyVisible, channels }) => (
        <li key={key}>
          <Button type="text" size="small" icon={<Eye isVisible={isVisible} isPartiallyVisible={isPartiallyVisible} />} onClick={toggleVisible}>
            {title}
          </Button>
          <ol className={cn.channels}>
            {channels.map(({ key, title, axis, color, isVisible, toggleVisible, setColor }) => (
              <li key={key}>
                <ColorPicker className={cn.colorPicker} size="small" value={color} onChange={setColor} />
                <Button size="small" type="text" icon={<Eye isVisible={isVisible} />} onClick={toggleVisible}>
                  {[title, axis].filter(Boolean).join(", ")}
                </Button>
              </li>
            ))}
          </ol>
        </li>
      ))}
    </ul>
  );
});

export { Legend };
