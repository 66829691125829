import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { computed, makeObservable, observable, runInAction, transaction } from "mobx";

import { FilterManager } from "features/techPrediction/filters/manager";
import { TECH_PARAMS_FILTER_MAP } from "features/wellsTech/wellsTech";
import { Range } from "utils/range";

import { Fact } from "../../fact";
import { Forecast } from "../../forecast/forecast";
import { Well } from "../well";

import { InjectWell, InjectWellsNode } from "./inject";
import { MiningWell, MiningWellsNode } from "./mining";
import { Summary } from "./summary";

type DRow = {
  id?: number; // debug column
  name: string;
  measure: string | null;
  absoluteIndex?: number;
  tooltip?: string[];
  [yearIdx: number]: number | boolean | string | null;
};

type FilterPredicate<T> = (child: T) => boolean;
function compileFilters<T>(filters: Array<FilterPredicate<T>>): FilterPredicate<T> {
  return (child) => {
    for (const filter of filters) {
      if (!filter(child)) {
        return false;
      }
    }
    return true;
  };
}

class TechWells extends TableNode<DRow, Summary | MiningWellsNode | InjectWellsNode> {
  public miningNode?: MiningWellsNode;
  public injectNode?: InjectWellsNode;

  public readonly filterManager = new FilterManager(TECH_PARAMS_FILTER_MAP, []);

  constructor(public readonly fact: Fact, public readonly forecast: Forecast | null = null) {
    super(null, { selectable: false, mutable: false });
    makeObservable(this, {
      miningNode: observable.ref,
      injectNode: observable.ref,
      miningWells: computed,
      supportWells: computed,
    });
    this.initChildren();
  }

  public get source(): Fact | Forecast {
    return this.forecast ?? this.fact;
  }

  public get range(): Range {
    return this.forecast?.wholeRange ?? this.fact.factRange;
  }

  public get miningWells(): Well[] {
    return [...(this.miningNode?.children ?? [])].map((n) => n.well);
  }

  public get supportWells(): Well[] {
    return [...(this.injectNode?.children ?? [])].map((n) => n.well);
  }

  private initChildren() {
    runInAction(() => {
      this.miningNode = new MiningWellsNode(this.source.wells, this);
      this.injectNode = new InjectWellsNode(this.source.wells, this);

      this.childrenStore = new ChildrenStoreArray(this, [new Summary(this), this.miningNode, this.injectNode]);
    });
  }

  public applyFilters = () => {
    const predicate = compileFilters<MiningWell | InjectWell>(this.filterManager.predicates);
    transaction(() => {
      this.miningNode?.childrenStore?.filter(predicate);
      this.injectNode?.childrenStore?.filter(predicate);
      this.childrenStore?.filter((node) => node.childrenStore?.visibleChildrenLength !== 0);
    });
  };
}

export { type DRow, TechWells };
