import { global } from "models/global";
import { Project } from "models/project/project";
import { reqCamel } from "utils/request";

type Role = {
  title: string;
  id: number;
  userIds: number[];
  projectIds: number[];
};

type ProjectRole = Omit<Role, "projectIds">;

type AssignRole = {
  roleId: number;
  projectId: number;
  userId: number;
};

type Permissions = Record<Sections, boolean>;
type Sections = "tech" | "economic" | "ranking" | "infrastructure" | "users" | "license" | "producingObject";

//Администратор системы - 1, Руководитель проекта - 2
const ADMIN_ROLES_IDS = [1, 2];

const ROLES_PERMISSIONS: Record<number, Permissions> = {
  //Администратор системы
  1: {
    tech: false,
    economic: false,
    ranking: false,
    infrastructure: false,
    users: true,
    license: false,
    producingObject: false,
  },
  //Руководитель проекта
  2: {
    tech: true,
    economic: true,
    ranking: true,
    infrastructure: true,
    users: true,
    license: true,
    producingObject: true,
  },
  //Специалист по разработке месторождений
  3: {
    tech: true,
    economic: false,
    ranking: true,
    infrastructure: false,
    users: false,
    license: false,
    producingObject: true,
  },
  //Специалист по инфраструктуре
  4: {
    tech: false,
    economic: false,
    ranking: false,
    infrastructure: true,
    users: false,
    license: false,
    producingObject: false,
  },
  //Специалист по экономике
  5: {
    tech: false,
    economic: true,
    ranking: true,
    infrastructure: false,
    users: false,
    license: true,
    producingObject: false,
  },
  //Эксперт по разработке месторождений
  6: {
    tech: false,
    economic: false,
    ranking: false,
    infrastructure: false,
    users: false,
    license: false,
    producingObject: false,
  },
  //Эксперт по инфраструктуре
  7: {
    tech: false,
    economic: false,
    ranking: false,
    infrastructure: false,
    users: false,
    license: false,
    producingObject: false,
  },
  //Эксперт по экономике
  8: {
    tech: false,
    economic: false,
    ranking: false,
    infrastructure: false,
    users: false,
    license: false,
    producingObject: false,
  },
  //Куратор проектов
  9: {
    tech: false,
    economic: false,
    ranking: false,
    infrastructure: false,
    users: false,
    license: false,
    producingObject: false,
  },
};

const getUserPermission = (project: Project): Permissions => {
  if (window.location.host.endsWith("develop.iprm.online") || process.env.REACT_APP_ADMIN) {
    return {
      license: true,
      producingObject: true,
      tech: true,
      economic: true,
      ranking: true,
      infrastructure: true,
      users: true,
    };
  }
  const userRoles = project.participants.getById(global.user?.id ?? 0).roles;
  const res: Permissions = {
    tech: false,
    economic: false,
    ranking: false,
    infrastructure: false,
    users: false,
    license: false,
    producingObject: false,
  };
  for (const role of userRoles) {
    Object.entries(ROLES_PERMISSIONS[role.id]).forEach(([section, permission]) => {
      if (permission) {
        res[section as Sections] = true;
      }
    });
  }
  return res;
};

const canManageRoles = async (projectId: number) => {
  const currentUserId = global.user?.id;
  if (!currentUserId) {
    return false;
  }
  const adminProjectRoles = (await getProjectRoles(projectId)).filter(({ id }) => ADMIN_ROLES_IDS.includes(id));
  for (const { userIds } of adminProjectRoles) {
    if (userIds.includes(currentUserId)) {
      return true;
    }
  }
  return false;
};

const getRoles = async (): Promise<Role[]> => reqCamel.get<Role[]>("roles");

const getProjectRoles = async (projectId: number): Promise<ProjectRole[]> =>
  reqCamel.get<ProjectRole[]>(`roles/projects/${projectId}`);

const assignRole = async (roleId: number, projectId: number, userId: number) => {
  if (await canManageRoles(projectId)) {
    return await reqCamel.post<AssignRole>("roles/assign_role", { roleId, projectId, userId });
  }
  console.warn("Недостаточно прав для назначения ролей");
};

const deleteRole = async (roleId: number, projectId: number, userId: number) => {
  if (await canManageRoles(projectId)) {
    return await reqCamel.delete<AssignRole>("roles/users", { roleId, projectId, userId });
  }
  console.warn("Недостаточно прав для удаления ролей");
};

const deleteUser = (roleIds: number[], projectId: number, userId: number) =>
  Promise.all(roleIds.map((roleId) => deleteRole(roleId, projectId, userId)));

export {
  ADMIN_ROLES_IDS,
  assignRole,
  deleteRole,
  deleteUser,
  getProjectRoles,
  getRoles,
  getUserPermission,
  ROLES_PERMISSIONS,
};
export type { ProjectRole, Sections };
