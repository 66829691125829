import { FC, useMemo } from "react";
import { ColumnRaw, TableContextProvider, TableModel, Widget } from "@okopok/components/Table";
import { Select, Typography } from "antd";
import { observer } from "mobx-react-lite";

import { Format } from "elements/format/format";
import { useProject } from "models/project/project";
import { getUserPermission } from "services/back/roles";

import { ProducingObjectRow, ProducingObjectTable } from "./model/producingObject";
import type { DRow } from "./model/stratum";
import { Tables } from "./model/tables";

import cn from "./producingObjects.module.less";

const useColumns = (producingObjects: ProducingObjectTable, edit = true): ColumnRaw<DRow>[] =>
  useMemo(
    () => [
      {
        key: "index",
        title: "No.пп",
        width: 60,
        render: (_, { absoluteIndex, indexPath }) => absoluteIndex ?? (indexPath.at(-1) ?? 0) + 1,
      },
      {
        dataKey: "title",
        title: "Название",
        width: { min: 350, max: 400, competitiveness: 2 },
      },
      {
        dataKey: "stratumType",
        title: "Тип залежи",
        width: { min: 250, max: 400, competitiveness: 1 },
        render: () => <Format>{null}</Format>,
      },
      {
        dataKey: "collectorType",
        title: "Тип коллектора",
        width: { min: 250, max: 400, competitiveness: 1 },
        render: () => <Format>{null}</Format>,
      },
      {
        dataKey: "producingObject",
        title: "Привязка к объекту разработки",
        width: { min: 350, max: 550, competitiveness: 2 },
        render: (po: ProducingObjectRow | null, { update }) => {
          const onChange = (title: string | -1) => {
            if (title === -1) {
              update?.("producingObject", null);
              return;
            }
            const po = producingObjects.findByTitle(title);
            update?.("producingObject", po ?? null);
          };
          return (
            <Select
              disabled={!edit}
              value={po?.data.title ?? -1}
              onChange={onChange}
              options={[
                { value: -1, label: "Нераспределенные залежи" },
                ...[...producingObjects.children!]
                  .filter(({ data: { title } }) => title.length > 0)
                  .map(({ data: { title } }) => ({ value: title as string, label: title })),
              ]}
              variant="borderless"
              style={{ width: "100%" }}
            />
          );
        },
      },
    ],
    [edit, producingObjects]
  );

const StratumsTable: FC<{ tables: Tables }> = observer(({ tables }) => {
  const project = useProject()!;
  const edit = getUserPermission(project)["producingObject"];

  const store = tables.stratums!;
  const columns = useColumns(tables.producingObjects!, edit);
  const model = useMemo(() => {
    return new TableModel(columns, store);
  }, [columns, store]);

  return (
    <div className={cn.tableContainer}>
      <Typography.Title>Залежи</Typography.Title>
      <TableContextProvider value={model}>
        <Widget headerClassName={cn.tableHeader} />
      </TableContextProvider>
    </div>
  );
});

export { StratumsTable };
