import { TableNode } from "@okopok/components/Table";
import dayjs from "dayjs";
import { action, computed, IObservableArray, makeAutoObservable, makeObservable, observable, reaction } from "mobx";
import { isPersisting, makePersistable } from "mobx-persist-store";

import { zip } from "utils/itertools";

import { FilterDetails, FilterInfo, FilterType, StaticFilter } from "./types";

class PersistantDataProviderForFilterManager {
  public filters?: Array<Partial<FilterInfo<any>> | undefined>;
  public staticFiltersChecked?: boolean[];
  constructor(private filterManager: FilterManager<any, any>, private id: string) {
    this.filters = undefined;
    this.staticFiltersChecked = undefined;

    makeAutoObservable(this);
    makePersistable(
      this,
      {
        name: `data_for_filter_manager_${id}`,
        properties: ["filters", "staticFiltersChecked"],
        storage: window.localStorage,
      },
      {}
    );

    reaction(
      () => JSON.stringify(this.filterManager.filters),
      () => {
        this.filters = this.filterManager.filters;
      }
    );

    reaction(
      () => JSON.stringify(this.filterManager.staticFiltersChecked),
      () => {
        this.staticFiltersChecked = this.filterManager.staticFiltersChecked;
      }
    );

    reaction(
      () => this.isPersisting,
      () => {
        if (this.filters) {
          const filterList = [];
          for (const f of this.filters) {
            if (f?.column === "date" && f.value) {
              filterList.push({ ...f, value: dayjs(f.value) });
            } else {
              filterList.push(f);
            }
          }
          this.filterManager.setInternalFilters(filterList);
        }
        if (this.staticFiltersChecked) {
          this.filterManager.setStaticFiltersChecked([...this.staticFiltersChecked]);
        }
        this.filterManager.saveState();
      }
    );
  }

  public get isPersisting() {
    return isPersisting(this);
  }
}

class FilterManager<DRow, TNode extends TableNode<DRow, any>> {
  #filters: IObservableArray<Partial<FilterInfo<any>> | undefined> = observable.array([undefined]);
  public staticFiltersChecked: IObservableArray<boolean>;
  public persistantManager: PersistantDataProviderForFilterManager | undefined;
  private savedState: {
    staticFiltersChecked: boolean[] | undefined;
    filters: Array<Partial<FilterInfo<any>> | undefined> | undefined;
  } = {
    staticFiltersChecked: undefined,
    filters: undefined,
  };

  constructor(
    public readonly filterMap: Record<string, FilterDetails<any, DRow, TNode>>,
    public readonly staticFilters: StaticFilter<DRow, TNode>[],
    persistantFilterManagerId?: string | undefined
  ) {
    this.staticFiltersChecked = observable.array(new Array(staticFilters.length).fill(false));

    if (persistantFilterManagerId) {
      this.persistantManager = new PersistantDataProviderForFilterManager(this, persistantFilterManagerId);
    }

    this.saveState();

    makeObservable(this, {
      staticFiltersChecked: observable,
      filters: computed,
      setInternalFilters: action,
      predicates: computed,
      purePredicates: computed,
      removeDisabled: computed,
      insertEmpty: action,
      delete: action,
      change: action,
      setStaticFiltersChecked: action,
      isModified: computed,
      saveState: action,
      resetState: action,
    });
  }

  public get isModified(): boolean {
    return this.staticFiltersChecked.some((e) => e === true) || this.#filters.filter((e) => !!e).length > 0;
  }

  public get predicates(): ReturnType<FilterDetails<any, DRow, TNode>["predicateFactory"]>[] {
    return [
      ...[...zip(this.staticFiltersChecked, this.staticFilters)]
        .filter(([checked]) => checked)
        .map(([_, filter]) => filter.predicate),
      ...this.purePredicates,
    ];
  }

  public get purePredicates(): ReturnType<FilterDetails<any, any, any>["predicateFactory"]>[] {
    return this.#filters
      .filter((filter) => {
        if (!filter) {
          return false;
        }
        const { column, condition } = filter;
        if ([column, condition].some((v) => v === undefined)) {
          return false;
        }
        return true;
      })
      .map((filter) => {
        const { column, condition, value } = filter as FilterInfo<any>;
        const factory = this.filterMap[column].predicateFactory;
        return factory(condition, value);
      });
  }

  public get filters() {
    return this.#filters;
  }

  public setInternalFilters(value: (Partial<FilterInfo<any>> | undefined)[]) {
    this.#filters.replace(value);
  }

  public setStaticFiltersChecked(value: boolean[]) {
    this.staticFiltersChecked.replace(value);
  }

  public insertEmpty(index: number) {
    // if undefined -> delete previous undefined
    this.#filters.splice(index, 0, undefined);
  }

  public change<T extends FilterType>(filter: Partial<FilterInfo<T>>, index: number) {
    this.#filters[index] = filter;
  }

  public delete(index: number) {
    this.#filters.splice(index, 1);
    if (this.#filters.length === 0) {
      this.#filters.push(undefined);
    }
  }

  public get removeDisabled(): boolean {
    return this.#filters.length === 1 && this.#filters[0] === undefined;
  }

  public saveState() {
    this.savedState.filters = [...this.#filters];
    this.savedState.staticFiltersChecked = [...this.staticFiltersChecked];
  }

  public resetState() {
    if (this.savedState.filters) {
      this.#filters.replace([...this.savedState.filters]);
    }
    if (this.savedState.staticFiltersChecked) {
      this.staticFiltersChecked.replace([...this.savedState.staticFiltersChecked]);
    }
  }
}

export { FilterManager };
