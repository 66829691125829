import { FC } from "react";
import { Button, Tooltip } from "antd";
import { observer } from "mobx-react-lite";

import { Icon } from "elements/icon/icon";

import { ReactComponent as SaveDisabledIcon } from "./notSaveIcon.svg";
import { ReactComponent as SaveIcon } from "./saveIcon.svg";

type SubmitButtonProps = {
  edit?: boolean;
  model?: {
    isUpdated?: boolean;
    isCompleted?: boolean;
    isValid?: boolean;
  };
  loading?: boolean;
  customTooltip?:
    | string
    | {
        onSave?: string;
        onNotUpdated?: string;
        onNotCompleted?: string;
        onNotValid?: string;
      };
  onSubmit?: () => void;
};

const SubmitButton: FC<SubmitButtonProps> = observer(({ model, loading, onSubmit, customTooltip, edit }) => {
  let isDisabled = false;
  let isWarn = false;
  let isErr = false;

  if (model !== undefined) {
    isDisabled = !(model?.isUpdated ?? false);
    isWarn = !(model?.isCompleted ?? true);
    isErr = !(model?.isValid ?? true);
  }

  const tooltip = typeof customTooltip === "string" ? { onSave: customTooltip } : customTooltip;

  let title: undefined | string = tooltip?.onSave ?? "Сохранить изменения";
  if (isDisabled) {
    title = tooltip?.onNotUpdated ?? "Сохранение не требуется";
  } else if (isWarn) {
    title = tooltip?.onNotCompleted ?? "Форма заполнена не полностью. Для запуска расчета введённых данных не достаточно";
  }
  if (isErr) {
    title = tooltip?.onNotValid ?? "Для сохранения необходимо заполнить обязательные поля";
  }
  if (edit === false) {
    title = "Для выполнения действия недостаточно полномочий вашей роли в проекте";
  }

  return (
    <Tooltip title={title}>
      <Button
        onClick={onSubmit}
        disabled={isDisabled || isErr || !onSubmit || !edit}
        danger={isWarn || isErr}
        loading={loading}
        icon={
          <Icon
            style={{ stroke: "none" }}
            width="17"
            height="16"
            viewBox={"0 0 16 18"}
            content={isDisabled ? <SaveDisabledIcon /> : <SaveIcon />}
          ></Icon>
        }
      />
    </Tooltip>
  );
});

export { SubmitButton, type SubmitButtonProps };
