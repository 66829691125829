import { FC } from "react";
import { ConfigProvider, Empty, Radio } from "antd";
import { observer } from "mobx-react";

import { DebugZone } from "elements/debugZone/debugZone";
import { ModeSelector } from "elements/modeSelector/modeSelector";
import { global } from "models/global";
import { useProject } from "models/project/project";
import { getUserPermission } from "services/back/roles";

import { Player } from "../player/player";
import { useTechForecastModel } from "../useTechForecastModel";

import { Theme } from "./customAntdRadioTheme";
import { WellTechForecastResult } from "./wellTechForecastResult";
import { WellTechForecastSettings } from "./wellTechForecastSettings";

import cn from "./wellTechForecast.module.less";

const WellTechForecast: FC = observer(() => {
  const model = useTechForecastModel();

  const project = useProject()!;
  const edit = getUserPermission(project)["tech"];

  return (
    <div className={cn.layout}>
      <Player count={model.selection.selectedEvents.length} onCurrentChange={model.selectionHolder} current={model.selectedIndex}>
        <ConfigProvider theme={Theme}>
          <ModeSelector
            onUpdate={model.currentForecast.settings.modeHolder}
            value={model.currentForecast.settings.mode}
            buttonStyle="solid"
            className={cn.inversion}
          />
        </ConfigProvider>
        <Radio.Group value={model.resultsDisplayMode} onChange={(e) => model.resultsDisplayModeHolder(e.target.value)}>
          <Radio.Button value="chart">График</Radio.Button>
          <Radio.Button value="lnvnf">ХВ (ln(ВНФ))</Radio.Button>
          <Radio.Button value="recovery">
            ХВ (W<sub>c</sub>)
          </Radio.Button>
          <Radio.Button value="table">Таблица</Radio.Button>
          {global.IS_DEBUG_ZONE && (
            <Radio.Button value="debug">
              <DebugZone>Отладка</DebugZone>
            </Radio.Button>
          )}
        </Radio.Group>
      </Player>
      <WellTechForecastResult className={cn.results} />
      {edit ? (
        <WellTechForecastSettings />
      ) : (
        <Empty className={cn.empty} description="Для выполнения действия недостаточно полномочий вашей роли в проекте" />
      )}
    </div>
  );
});

export { WellTechForecast };
