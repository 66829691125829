import { useEffect, useMemo, useState } from "react";
import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { Table } from "@okopok/components/Table/Table";
import { Button, message } from "antd";
import { MessageInstance } from "antd/es/message/interface";
import { makeAutoObservable, runInAction, transaction } from "mobx";
import { observer } from "mobx-react";
import objectHash from "object-hash";
import { usePagePostfix } from "routing/outlines/secondaryMenu/scenarioMenu";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { FullScreenLoader } from "elements/fullScreen/fullScreen";
import { SelectStorable } from "elements/inputs/selectStorable/selectStorable";
import { useSearchParamsStorage } from "elements/useSearchParamsStorage";
import { useEcyLists } from "features/ecyParameters/useEcyLists";
import { ForecastSelector } from "features/forecastSelector/forecastSelector";
import { type Column, SimpleTableContext } from "features/tableDebug/simpleTable";
import { useECYStore } from "features/useMetrics/useECYStore";
import { useProjectContext } from "models/project/context/projectContext";
import { ECY } from "models/project/fact/ecy";
import { useFact } from "models/project/fact/fact";
import { useForecast } from "models/project/fact/forecast/forecast";
import { Well } from "models/project/fact/well/well";
import { provideRequest } from "services/back/calculate/calculate";
import { replaceYearsRange } from "services/back/calculate/utils";
import { genericTableDataPostprocess, type GenericTableRow, getGenericTableData } from "services/back/genericTable/genegicTableService";

import cn from "./baseReport.module.less";

type ReportOptions = {
  chooseSingleLicenceZone?: boolean;
  chooseSingleLicenceZoneIsImportant?: boolean;
  chooseSingleUsc?: boolean;
  chooseSingleUscIsImportant?: boolean;
  useWellsFromTree?: boolean;
  useCalculationData?: boolean;
  useEcyLists?: boolean;
};

class Datum extends TableNode<GenericTableRow, Datum> {
  constructor(private readonly datum: GenericTableRow, parent: Datum | null = null) {
    super(parent, { isExpandedChildren: true });

    this.childrenStore = datum?.children
      ? new ChildrenStoreArray(
          this,
          datum.children.filter((child) => child !== undefined).map((child) => new Datum(child, this))
        )
      : null;
  }

  asDRow(): GenericTableRow {
    return this.datum;
  }
}

class ReportTableModel {
  loadedReportKey: string | undefined;
  reportKey: string | undefined;
  store: Datum | undefined;
  requestError: string | null = null;
  columns: Column[] = [];

  constructor(
    public readonly forecastId: number | undefined,
    public readonly apiPath: string,
    private readonly message: MessageInstance,
    private readonly storage: { getItem: CallableFunction },
    private readonly options?: ReportOptions
  ) {
    makeAutoObservable(this);

    this.store = new Datum({ children: [] });
    this.columns = [];
  }

  get isLoading() {
    return this.reportKey !== this.loadedReportKey;
  }

  get isUpdated() {
    return false;
  }

  get isCompleted() {
    return true;
  }

  get isValid() {
    return true;
  }

  private takeData(description: ReturnType<typeof genericTableDataPostprocess>) {
    if (description === null) {
      this.requestError = "Данные получены но структура данных не соответствует ожидаемой";
      return;
    }
    const { data, columns } = description;
    this.store = new Datum({ children: data });
    this.columns = columns.map((column) => {
      const { key, ...rest } = column;
      return { dataKey: key, ...rest };
    });
  }

  private getStorageParamsList() {
    const list = [];
    if (this.options?.chooseSingleLicenceZone) {
      if (this.storage.getItem("licence_zone_id") || this.options?.chooseSingleLicenceZoneIsImportant) list.push("licence_zone_id");
    }
    if (this.options?.chooseSingleUsc) {
      if (this.storage.getItem("usc_uuid") || this.options?.chooseSingleLicenceZoneIsImportant) list.push("usc_uuid");
    }

    return list;
  }

  private getPayload(well_ids: number[], calculationData: any, listECY: ECY[], listSystemECY: ECY[]): any {
    const payload: any = {};

    for (const param of this.getStorageParamsList()) {
      payload[param] = this.storage.getItem(param);
    }

    if (this.options?.useWellsFromTree) {
      payload.well_ids = well_ids;
    }
    if (this.forecastId) {
      payload.scenario_id = this.forecastId;
    } else {
      payload.scenatio_id = null;
    }
    if (calculationData) {
      payload.calculation_data = calculationData;
    }

    if (this.options?.useEcyLists) {
      payload.listECY = listECY.map((e) => ({ title: e.title, id: e.id }));
      payload.listSystemECY = listSystemECY.map((e) => ({ title: e.title, id: e.id }));
    }

    return payload;
  }

  private makeReportKey(path: string, payload: any) {
    return path + "-" + objectHash(payload);
  }

  public fetch(well_ids: number[], calculationData: any, listECY: ECY[], listSystemECY: ECY[]) {
    const payload = this.getPayload(well_ids, calculationData, listECY, listSystemECY);
    this.reportKey = this.makeReportKey(this.apiPath, payload);
    getGenericTableData(this.apiPath, this.message, "POST", payload)
      .then((description) =>
        runInAction(() =>
          transaction(() => {
            this.requestError = null;
            this.loadedReportKey = this.reportKey;
            this.takeData(description as any);
          })
        )
      )
      .catch((result) =>
        runInAction(() => {
          this.requestError = `Не удалось получить данные ${result.status} ${result.url}`;
        })
      );
  }
}

const SelectWrapper = observer(
  ({
    storage,
    store,
    dataKey,
    placeholder,
    multiple,
  }: {
    storage: { getItem: CallableFunction; setItem: CallableFunction };
    store: any;
    dataKey: string;
    placeholder: string;
    multiple?: boolean;
  }) => {
    return (
      <SelectStorable
        placeholder={placeholder}
        style={{ width: 300, overflow: "hidden" }}
        values={[storage.getItem(dataKey) === "" ? null : storage.getItem(dataKey), undefined]}
        onChange={(v) => {
          storage.setItem(dataKey, v == null ? "" : v);
        }}
        {...(multiple
          ? {
              maxTagCount: 1,
              mode: "multiple",
            }
          : {})}
        store={store}
      />
    );
  }
);

const BaseReport = observer(({ apiPath, title, options }: { apiPath: string; title: string; options?: ReportOptions }) => {
  const [messageApi] = message.useMessage();
  const state = useFact()!;
  const fc = useForecast();
  const projectContext = useProjectContext();
  const wellTree = projectContext.wellsTree;
  const pagePostfix = usePagePostfix().join("|");
  const [calculation, setCalculation] = useState<undefined | null | { data: any; forecastId: number }>(undefined);
  const ecyStore = useECYStore();
  const { listSystemECY, listECY } = useEcyLists(ecyStore);

  const storage = useSearchParamsStorage();

  // Загрузка данных расчета
  useEffect(() => {
    const load = async () => {
      setCalculation(null);
      if (fc) {
        await fc?.prepareForReportRequest();
        replaceYearsRange(fc.wholeRange);
        const data = provideRequest(fc);
        setCalculation({ data, forecastId: fc.id });
      }
    };
    if (options?.useCalculationData && (calculation === undefined || (calculation && calculation.forecastId !== fc?.id))) {
      load();
    }
  }, [calculation, fc, options?.useCalculationData]);

  const model = useMemo(
    () => new ReportTableModel(fc?.id, apiPath, messageApi, storage, options),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apiPath, messageApi, storage, fc, fc?.id, state, state.id, options]
  );

  useEffect(() => {
    if (options?.chooseSingleUsc) storage.setDefaultItem("usc_uuid", null);
    if (options?.chooseSingleLicenceZone) storage.setDefaultItem("licence_zone_id", null);
  }, [fc, fc?.id, storage, state, state.id, options]);

  const isScenarioChoosen = fc && fc.id != null;

  const canSubmit = (() => {
    if (options?.chooseSingleUscIsImportant && !storage.getItem("usc_uuid")) return false;
    if (options?.chooseSingleLicenceZoneIsImportant && !storage.getItem("licence_zone_id")) return false;

    return isScenarioChoosen;
  })();

  const isPrepareLoading = (() => {
    if (options?.useCalculationData && !calculation?.data) return true;
    return false;
  })();

  return (
    <ForecastSelector>
      <SimpleTableContext
        exportFileName={title}
        columns={model.columns}
        data={model.store}
        tableOptions={{
          onRow: ({ indexPath, expand }) => ({
            className: expand === undefined ? cn.tableRowPlain : indexPath.length === 1 ? cn.tableRowPrimary : cn.tableRowSecondary,
          }),
        }}
        tableSettingsId={`reports-${pagePostfix}`}
      >
        <PageFrameTitlePortal>
          {options?.chooseSingleLicenceZone && (
            <SelectWrapper storage={storage} store={state.licenseRegions} placeholder="Лицензионные участки" dataKey="licence_zone_id" />
          )}
          {options?.chooseSingleUsc && <SelectWrapper storage={storage} store={state.ecyStore} placeholder="ЕСУ" dataKey="usc_uuid" />}
          <Button
            onClick={() => {
              model.fetch(
                wellTree.selectedItems.filter((e) => e.item != null && e.item instanceof Well).map((e) => e.item!.id),
                calculation?.data,
                listECY,
                listSystemECY
              );
            }}
            type="primary"
            disabled={!canSubmit}
            loading={isPrepareLoading}
          >
            Сформировать отчет
          </Button>
        </PageFrameTitlePortal>
        <div style={{ display: "flex", height: "100%" }}>
          <div style={{ flexGrow: 1 }}>{model.isLoading ? <FullScreenLoader /> : model.columns.length === 0 ? null : <Table />}</div>
        </div>
      </SimpleTableContext>
    </ForecastSelector>
  );
});

export { BaseReport };
