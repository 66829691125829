import dayjs, { Dayjs } from "dayjs";
import { computed, makeObservable, observable, reaction, runInAction, when } from "mobx";
import { RangeValueType } from "rc-picker/lib/PickerInput/RangePicker";
import { debounce } from "throttle-debounce";

import { Lazy } from "models/lazy";
import { EventNode } from "models/project/fact/forecast/techPrediction/techPrediction";
import {
  Fitting,
  ForecastMode,
  isFittingKey,
  submitTechForecast,
  WellTechProduction,
} from "services/back/techForecast/request";
import { WellFactProduction } from "services/back/techForecast/techForecast";

import { ForecastGroup, GroupedEvents } from "../groupedEvents";
import type { TechForecastModel } from "../techForecastModel";

import { WellTechChart } from "./wellTechChart";
import { KNOWN_GROUPS } from "./wellTechChartChannelsMeta";
import { WellTechFact } from "./wellTechFact";
import { WellTechForecastResult } from "./wellTechForecastResult";
import { WellTechForecastSettings } from "./wellTechForecastSettings";

const DEBOUNCE_TIMEOUT = 1000;

type ForecastStatus = "changed" | "notChanged" | "notViewed" | "wrong" | "failed" | "ok" | "loading";

class WellTechForecast {
  readonly #results = observable.map<string, WellTechForecastResult>();
  public readonly settings = new WellTechForecastSettings(this);
  public readonly fact: WellTechFact;
  #chart = new Lazy(() => new WellTechChart(this));
  get chart() {
    return this.#chart.value;
  }
  readonly #manager: TechForecastModel;
  // когда был показан или был запущен общий расчет
  public wasTouched = false;

  private readonly lastComputedResult = observable.box<WellTechForecastResult | null>(null);

  // когда обёртка undefined это только ожидание загрузки факта, успешность загрузки и статус отслеживаются внутри обёрток
  get currentResult(): WellTechForecastResult | undefined {
    const fc = this.#results.get(JSON.stringify(this.settings.dump));
    if (fc !== undefined) {
      return fc;
    }
    // если lastComputedResult не задан то это мы ждём загрузки, возвращается признак загрузки
    return this.lastComputedResult.get() ?? undefined;
  }

  readonly #calculateImmediately = async () => {
    if (!this.wasTouched) {
      return;
    }
    const { dump } = this.settings;
    const key = JSON.stringify(dump);
    if (!this.#results.has(key)) {
      const result = new WellTechForecastResult(dump);
      this.#results.set(key, result);
      when(
        () => result.isLoading === false && result.isNull === false,
        () =>
          runInAction(() => {
            this.lastComputedResult.set(result);
          })
      );
    }
  };
  readonly #calculate = debounce(DEBOUNCE_TIMEOUT, this.#calculateImmediately, { atBegin: false });

  initialBinding(mode: ForecastMode): number {
    const src = this.group === "gtm" ? this.selection.intervention!.data : this.selection.well.data;
    return src[
      (
        {
          liquid: "liquidRate",
          oil: "oilRate",
          waterCut: "waterCut",
        } as const
      )[mode]
    ]!;
  }

  lastFactBinding(mode: ForecastMode): number | undefined {
    return (
      this.fact.data?.factProduction[
        (
          {
            liquid: "liquidRateM3",
            oil: "oilRateT",
            waterCut: "waterCutVol",
          } as const
        )[mode]
      ] as any
    ).findLast((v: number | null) => v !== 0 && v !== null);
  }

  constructor(private readonly selection: EventNode, readonly manager: TechForecastModel) {
    this.#manager = manager;
    this.fact = new WellTechFact(this);

    makeObservable(this, {
      isCurrent: computed,
      wellId: computed,
      stratumId: computed,
      scenarioId: computed,
      group: computed,
      currentResult: computed,
      wasTouched: observable,
    });

    reaction(() => this.settings.dump, this.#calculate);

    when(() => this.fact.isLoading === false).then(() =>
      runInAction(() => {
        if (this.fact.isNull) {
          this.#calculateImmediately();
          return;
        }
      })
    );

    when(
      () => this.isCurrent,
      () => runInAction(() => (this.wasTouched = true))
    );
    reaction(
      () => this.isCurrent && this.requestKeeper,
      () => {
        if (this.isCurrent === true) {
          this.requestKeeper?.setShown();
        }
      }
    );

    when(() => this.wasTouched).then(this.#calculateImmediately);
  }

  get loaderTip(): string {
    if (this.fact.isLoading) {
      return "Загрузка истории добычи";
    }
    const { currentResult } = this;
    if (currentResult === undefined || currentResult.isLoading) {
      return "Выполнение расчета";
    }

    return "Ошибка идентификации загрузки";
  }

  get key() {
    return {
      wellId: this.wellId,
      gtmId: this.gtmId,
      scenarioId: this.scenarioId,
      stratumId: this.stratumId,
    };
  }

  get requestKeeper() {
    // если факт не определён то фокаст даже не пытался грузиться
    if (this.fact.isLoading) {
      return undefined;
    }
    const { currentResult } = this;
    // если расчет оплошал (и прошлых расчетов нет) то показываем факт
    if (currentResult === null || currentResult?.isNull) {
      // даже если факт нулевой, придётся вернуть. Интерфейс должен быть к этому готов
      return this.fact;
    }
    if (currentResult === undefined || currentResult.isLoading) {
      return undefined;
    }
    return currentResult;
  }

  get channels() {
    const { requestKeeper } = this;
    if (requestKeeper === null || requestKeeper === undefined) {
      return requestKeeper;
    }
    console.assert(
      requestKeeper.isLoading === false && (requestKeeper.isNull === false || requestKeeper === this.fact),
      "Ошибочная трактовка состояния сторов"
    );
    return requestKeeper.data;
  }

  get wellId(): number {
    return this.selection.well.id;
  }

  get stratumId(): number | null {
    return this.selection.stratumId;
  }

  get scenarioId(): number {
    return this.selection.forecast.id;
  }

  get gtmId(): number | null {
    return this.selection.intervention?.id ?? null;
  }

  get producingObjectId(): number | null {
    return this.selection.producingObject?.id ?? null;
  }

  get wellTypeId() {
    return this.selection.well.data.wellTypeId;
  }

  get group(): ForecastGroup {
    return GroupedEvents.wellMode(this.selection);
  }

  get isCurrent(): boolean {
    return this === this.#manager.currentForecast;
  }

  stepByDate(mode: ForecastMode, index: number) {
    return this.fact.data!.factProduction?.steps[index];
  }

  intervalParamsDump(mode: ForecastMode) {
    const domain = this.settings[mode].factDomain;
    const factProduction = this.fact.data?.factProduction;
    // если домен задан и не совпадает с дефолтным (это позволяет избежать пересчета)
    if (
      domain === null ||
      domain === undefined ||
      factProduction === undefined ||
      (this.stepByDate(mode, domain.min) === factProduction.defaultStartStep &&
        this.stepByDate(mode, domain.max) === factProduction.defaultEndStep &&
        this.settings[mode].emptyDots.size === 0)
    ) {
      return {};
    }

    return {
      startStep: this.stepByDate(mode, domain.min),
      endStep: this.stepByDate(mode, domain.max),
      skippedSteps: Array.from(this.settings[mode].emptyDots.values(), (v) => this.stepByDate(mode, v)),
    };
  }

  factDateRange(mode: ForecastMode): RangeValueType<Dayjs> | null | undefined {
    const domain = this.settings[mode].factDomain;
    if (domain === null || domain === undefined) {
      return domain;
    }
    const accessor = this.chart.factDomainAccessor;
    if (accessor === null || accessor === undefined) {
      return accessor;
    }
    return [dayjs(accessor(domain.min)), dayjs(accessor(domain.max))];
  }

  factDateRangeHolder =
    (mode: ForecastMode) =>
    ([from, to]: RangeValueType<Dayjs>) =>
      runInAction(() => {
        const accessor = this.chart.factDomainAccessor!;
        this.settings[mode].factDomain = {
          min: (accessor as any).invert(+from!),
          max: (accessor as any).invert(+to!),
        };
      });

  disallowedMothPredicate =
    (mode: ForecastMode) =>
    (date: Dayjs): boolean => {
      const accessor = this.chart.factDomainAccessor;
      if (accessor === null || accessor === undefined) {
        return true;
      }
      const index = Math.ceil((accessor as any).invert(+date));
      const dataKey = [...KNOWN_GROUPS[mode].items.values()][0] as keyof WellFactProduction["factProduction"];
      const data = this.fact.data!.factProduction[dataKey] as number[];
      return typeof data[index] !== "number";
    };

  getFitting(mode: ForecastMode): Fitting | null {
    const { channels } = this as { channels: WellTechProduction };
    if (channels === null || channels === undefined) {
      return null;
    }

    const fittingKey = (
      {
        oil: "oilRateTFitted",
        liquid: "liquidRateM3Fitted",
        waterCut: "waterCutVolumeFitted",
      } as const
    )[mode];

    return fittingKey && isFittingKey(fittingKey) ? channels[fittingKey] ?? null : null;
  }

  get isSaved(): boolean {
    return this.settings.isSaved && this.currentResult?.isSubmitted === true;
  }

  get status(): ForecastStatus {
    if (!this.wasTouched) {
      return this.settings.isSaved ? "notChanged" : "changed";
    }
    if (this.fact.isLoading) {
      return "loading";
    }
    const { currentResult } = this;
    if (currentResult === null || currentResult?.isNull) {
      return "failed";
    }
    if (currentResult === undefined || currentResult.isLoading) {
      return "loading";
    }
    if (this.settings.isSaved) {
      if (this.isSaved) {
        return "ok";
      }
      return "notChanged";
    }
    if (!this.settings.isValid) {
      return "wrong";
    }
    if (!this.requestKeeper?.wasShown) {
      return "notViewed";
    }
    return "changed";
  }

  async submit() {
    runInAction(() => {
      this.wasTouched = true;
    });
    await when(() => this.#results.get(JSON.stringify(this.settings.dump))?.isLoading === false);
    if (this.currentResult?.isNull) {
      return;
    }
    const submitResult = async () => {
      await submitTechForecast([
        {
          ...this.key,
          forecastProduction: this.currentResult!.data!.forecastProduction,
        },
      ]);
      runInAction(() => {
        this.currentResult!.isSubmitted = true;
      });
    };
    await Promise.all([submitResult(), this.settings.save()]);
  }
}

export { DEBOUNCE_TIMEOUT, type ForecastStatus, WellTechForecast };
