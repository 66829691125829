import { FC, HTMLAttributes, PropsWithChildren, ReactNode, useMemo } from "react";
import { ColumnRaw, TableContextProvider, TableItem, TableModel, TableNode } from "@okopok/components/Table";
import { Theme } from "@okopok/components/Table/models/theme";
import { ExpandButton } from "@okopok/components/Table/widgets/ExpandButton/ExpandButton";
import { Checkbox, Popconfirm, SelectProps, Switch, Tooltip } from "antd";
import { Dayjs } from "dayjs";
import { observer } from "mobx-react";

import { CsvSaver } from "elements/csvSaver/csvSaver";
import { DeleteButton } from "elements/deleteButton/deleteButton";
import { EditRowButton } from "elements/editRowButton/editRowButton";
import { Format } from "elements/format/format";
import { formatData, FormatDataAdditionalProps } from "elements/format/formatData";
import { LazyInput } from "elements/inputs/lazyInput/lazyInput";
import { LazyInputNumber } from "elements/inputs/lazyInputNumber/lazyInputNumber";
import { Select } from "elements/inputs/select";
import { SelectStorable, SelectStoreType } from "elements/inputs/selectStorable/selectStorable";
import { useTableSettings } from "models/tableSettings";
import { type GenericTableRow } from "services/back/genericTable/genegicTableService";
import { conditionallyArr } from "utils/conditionally";

import { ColumnFilter, SimpleTableFilter } from "./simpleTableFilters";

import cn from "./simpleTable.module.less";

type TableOptions<DRow> = {
  // TODO: Сейчас из общих компонентов это не экспортируется, надо будет импортировать существующий тип
  onRow?: (tableItem: TableItem<DRow>, rowIdx: number) => HTMLAttributes<HTMLDivElement>;
  expandRow?: (tableItem: TableItem<DRow>, index: number) => ReactNode;
  isRowEditable?: (tableItem: TableItem<DRow>, rowIdx: number) => boolean;
};

const withFirstColumnPadding = (
  columnIndex: number,
  onCell?: (tableItem: TableItem<any>, rowIndex: number) => HTMLAttributes<HTMLDivElement>,
  hasEditableCells?: boolean
) => {
  return columnIndex === 0
    ? (tableItem: TableItem<any>, rowIndex: number) => {
        const derived = onCell?.(tableItem, rowIndex);
        return {
          ...derived,
          style: { paddingLeft: 12 * tableItem.indexPath.length, ...derived?.style },
          className: hasEditableCells ? cn.afterButton : "",
        };
      }
    : onCell;
};

const getRenderer = (
  column: Column,
  hasEditableCells: boolean,
  tableOptions?: TableOptions<unknown> | undefined,
  customRenders?: Record<string, (value: number | boolean | null | undefined, row: GenericTableRow) => ReactNode>
) => {
  if (customRenders && customRenders[column.key!]) {
    return (value: any, row: GenericTableRow) => customRenders[column.key!](value, row);
  }

  const editable = {
    number: (v: number | null | undefined, row: TableItem<GenericTableRow>) => {
      const isRowEditable = tableOptions?.isRowEditable?.(row, row.indexPath[0]) ?? true;
      return row.update && isRowEditable ? (
        <LazyInputNumber
          onUpdate={(n: number | null) => {
            row.update!(column.dataKey, n);
          }}
          value={v ?? null}
        />
      ) : (
        <Format>{v ?? null}</Format>
      );
    },
    string: (v: string, row: TableItem<GenericTableRow>) => {
      const isRowEditable = tableOptions?.isRowEditable?.(row, row.indexPath[0]) ?? true;
      return row.update && isRowEditable ? (
        <LazyInput
          bordered={false}
          onChange={(n: string | null) => {
            row.update!(column.dataKey, n);
          }}
          value={v ?? null}
        />
      ) : (
        <Format>{v}</Format>
      );
    },
    set: (v: number | boolean | null | undefined, row: { update?: (key: any, newValue: any) => void; indexPath: number[] }) => {
      return row.update ? (
        typeof v === "boolean" ? (
          <Switch
            checked={v ?? false}
            checkedChildren="Да"
            unCheckedChildren="Нет"
            onChange={(checked: boolean) => {
              row.update!(column.dataKey, checked);
            }}
          />
        ) : (
          <LazyInputNumber
            onUpdate={(n: number | null) => {
              row.update!(column.dataKey, n);
            }}
            value={v ?? null}
          />
        )
      ) : (
        <Format>{v}</Format>
      );
    },
    select: (
      v: [id: number | null, title: string | undefined] | string | number,
      row: { update?: (key: any, newValue: any) => void; indexPath: number[] }
    ) => {
      if (column.type === "select") {
        if (!row.update) {
          return <Format>{Array.isArray(v) ? v[0] ?? v[1] ?? null : v || null}</Format>;
        }
        if (Array.isArray(v)) {
          return (
            <SelectStorable values={v} store={column.options as SelectStoreType} setValues={(n: number | null) => row.update?.(column.dataKey, n)} />
          );
        }
        return (
          <Select
            value={v}
            options={column.options as SelectProps["options"]}
            variant="borderless"
            onChange={(n) => row.update?.(column.dataKey, n)}
          />
        );
      }
    },
    boolean: (v: boolean) => <Format>{v}</Format>,
    date: (v: number | string | Date | Dayjs) => {
      if (column.type === "date") {
        const renderFormat = column.renderFormat || "date";
        return <Format renderFormat={renderFormat}>{v}</Format>;
      }
    },
  } as const;
  const notEditable = {
    number: (v: number) => <Format>{v ?? null}</Format>,
    set: (v: number) => <Format>{v ?? null}</Format>,
    string: (v: string, row: TableItem<GenericTableRow>, index: number, widths?: number[]) => {
      const isRowEditable = tableOptions?.isRowEditable?.(row, row.indexPath[0]) ?? true;
      const notEditableString = row?.expand?.status;
      const showEditRowBtn = hasEditableCells && column.showEditRowBtn && !notEditableString && isRowEditable;

      return (
        <>
          <Format ellipsisLimit={widths![index] / (column.factor ?? 8)}>{v ?? null}</Format>
          {showEditRowBtn && <EditRowButton row={row} />}
        </>
      );
    },
    select: undefined,
    boolean: (v: boolean) => <Format>{v}</Format>,
    date: (v: number | string | Date | Dayjs) => {
      if (column.type === "date") {
        const renderFormat = column.renderFormat || "date";
        return (
          <Format renderFormat={renderFormat} disabled>
            {v}
          </Format>
        );
      }
    },
  } as const;

  if (column.editable) {
    return editable[column.type];
  } else {
    return notEditable[column.type];
  }
};

class GenericTableData extends TableNode<GenericTableRow, GenericTableData> {}

type Column = Omit<ColumnRaw<GenericTableRow>, "render" | "title" | "width"> &
  (
    | {
        type: "number" | "string";
      }
    | {
        type: "set";
      }
    | {
        type: "boolean";
        yes?: string;
        no?: string;
      }
    | {
        type: "select";
        options: SelectStoreType | SelectProps["options"];
      }
    | {
        type: "date";
        renderFormat?: "date" | "moment" | "month" | "year";
      }
  ) & {
    title?: string | ReactNode;
    width?: ColumnRaw<GenericTableRow>["width"];
    editable?: boolean;
    factor?: number;
    showEditRowBtn?: boolean;
  };

type SimpleTableProps = PropsWithChildren<{
  data: TableNode<GenericTableRow, GenericTableData> | undefined;
  columns: Column[];
  exportFileName: string;
  hideExpandColumn?: boolean;
  tableOptions?: TableOptions<any> | undefined;
  showIndexColumn?: boolean;
  showSelectColumn?: boolean;
  theme?: Partial<Theme>;
  customRenders?: Record<string, (value: any, row: GenericTableRow) => ReactNode>;
  topExportIcon?: number;
  tableSettingsId?: string;
}>;

const formatDataOptions: FormatDataAdditionalProps = { unit: "locale_C" };

const SimpleTableContext: FC<SimpleTableProps> = observer(
  ({
    exportFileName,
    columns,
    data,
    children,
    hideExpandColumn: alwaysExpanded = false,
    tableOptions,
    showIndexColumn = true,
    theme,
    showSelectColumn,
    customRenders,
    topExportIcon,
    tableSettingsId,
  }) => {
    const hasEditableCells = columns.some((column) => column.editable);
    const hasRemoveFunc = ((data?.children ?? []) as { remove: () => void }[]).some((el) => "remove" in el && typeof el.remove === "function");

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const tableFilter = useMemo(() => new SimpleTableFilter(formatDataOptions), [columns]);

    const model = useMemo(() => {
      if (!data) {
        return undefined;
      }

      return new TableModel<GenericTableRow>(
        [
          ...conditionallyArr<ColumnRaw<GenericTableRow>>(showIndexColumn, {
            key: "index",
            title: "No.пп",
            isSticky: true,
            width: { min: 54, max: 54, competitiveness: 1 },
            render: (_, { absoluteIndex }) => absoluteIndex ?? <div />,
            onCell: () => ({ style: { justifyContent: "right" } }),
          }),
          ...conditionallyArr<ColumnRaw<GenericTableRow>>(!alwaysExpanded, {
            key: "expand",
            title: null,
            isSticky: true,
            width: { min: 32, max: 32, competitiveness: 1 },
            render: (_: any, tableItem: any) =>
              tableItem.expand !== undefined && tableItem.expand.status !== undefined && <ExpandButton expand={tableItem.expand} />,
            isExported: false,
          }),
          ...conditionallyArr<ColumnRaw<GenericTableRow>>(!!showSelectColumn, {
            key: "select",
            title: null,
            isSticky: true,
            width: { min: 32, max: 32, competitiveness: 1 },
            render: (_: any, tableItem: any) => {
              return (
                tableItem.value.select && (
                  <Tooltip title={tableItem.value.select.tooltip}>
                    <Checkbox
                      className={cn["exclude-checkbox"]}
                      checked={tableItem.value.select.checked}
                      onChange={tableItem.value.select.onSelect}
                    />
                  </Tooltip>
                )
              );
            },
            isExported: false,
          }),
          ...columns.map((column, columnIndex) => {
            const renderFunc = getRenderer(column, hasEditableCells, tableOptions, customRenders);

            return {
              ...column,
              title: (
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                  {column.title ?? column.key}
                  <ColumnFilter tableData={data} tableFilter={tableFilter} column={column} />
                </div>
              ),
              onCell: withFirstColumnPadding(columnIndex, column.onCell, hasEditableCells),
              render: renderFunc,
              renderToString: (v: string | number): string => formatData(v, formatDataOptions),
              width: column.width ?? { competitiveness: 1, min: 100, max: 200 },
            };
          }),
          ...conditionallyArr<ColumnRaw<GenericTableRow>>(hasRemoveFunc, {
            dataKey: "remove",
            title: null,
            isSticky: true,
            width: { min: 48, max: 48, competitiveness: 1 },
            render: (_: any, tableItem: any) => {
              if (!tableItem.value.remove) {
                return null;
              }
              if (tableItem.value.removeConfirm) {
                return (
                  <Popconfirm {...tableItem.value.removeConfirm} onConfirm={tableItem.value.remove}>
                    <DeleteButton />
                  </Popconfirm>
                );
              }
              return <DeleteButton onClick={tableItem.value.remove} />;
            },
            isExported: false,
          }),
        ],
        data,
        tableOptions,
        theme
      );
    }, [
      data,
      showIndexColumn,
      alwaysExpanded,
      showSelectColumn,
      columns,
      hasRemoveFunc,
      tableOptions,
      theme,
      hasEditableCells,
      customRenders,
      tableFilter,
    ]);

    if (model === undefined) {
      return <>{children}</>;
    }

    useTableSettings(model, tableSettingsId);

    return (
      <TableContextProvider value={model}>
        <CsvSaver filename={exportFileName} exportArray={() => model.export()} top={topExportIcon}>
          {children}
        </CsvSaver>
      </TableContextProvider>
    );
  }
);

export type { Column };
export { type GenericTableData, SimpleTableContext };
