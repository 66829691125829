import { type FC, MouseEvent, type ReactNode, useRef } from "react";
import { observer } from "mobx-react";

import { useTooltipContext } from "../abstractTooltip";

import { WrapTooltipModel } from "./wrapTooltipModel";

type TooltipProps = {
  id: number;
  children: ReactNode;
  title: ReactNode | (() => ReactNode);
  placement?: "rightBottom";
  trigger?: "click" | "hover" | "focus";
};

// WrapTooltip используется внутри svg, вешает на объект onMouseEnter, onMouseLeave, они отправляют в модель текущую информацию для отображения, по клику замораживается обновление контента. Снаружи svg добавляется div, который должен находится внутри контекста и из модели брать информацию для отображения.

const WrapTooltip: FC<TooltipProps> = observer(({ id, children, title, placement = "rightBottom", trigger = "hover" }) => {
  const wrapRef = useRef<SVGGElement>(null);
  const wrapTooltip = useTooltipContext();

  if (!(wrapTooltip instanceof WrapTooltipModel)) {
    console.error("В WrapTooltip передается экземпляр не того класса");
    return <></>;
  }
  const handleClick = (event: MouseEvent) => {
    event.stopPropagation();
    if (trigger === "click") {
      wrapTooltip?.onClickUpdate(id, typeof title === "function" ? title() : title, [
        ["right", 10],
        ["bottom", 200],
      ]);
    }
    if (trigger === "hover") {
      wrapTooltip?.lockUpdates();
    }
  };

  return (
    <g
      ref={wrapRef}
      onMouseEnter={() => {
        return trigger === "hover" && wrapTooltip !== null
          ? wrapTooltip.onContentUpdate(typeof title === "function" ? title() : title, [
              ["right", 10],
              ["bottom", 200],
            ])
          : undefined;
      }}
      onMouseLeave={() => {
        return trigger === "hover" && wrapTooltip !== null ? wrapTooltip.onContentUpdate(null) : undefined;
      }}
      onClick={handleClick}
    >
      {children}
    </g>
  );
});

export { WrapTooltip };
export type { TooltipProps };
