import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { Dayjs } from "dayjs";
import { computed, makeObservable, reaction, runInAction, transaction, when } from "mobx";

import { ForecastMethod } from "features/techForecast/models/well/methods";
import { SettingsSave } from "features/techForecast/models/well/wellTechForecastSettings";
import { TECH_PREDICTION_DEBET_FILTER_MAP, TECH_PREDICTION_DEBET_STATIC_FILTERS } from "features/techPrediction/debet";
import { FilterManager } from "features/techPrediction/filters/manager";
import { StratumData } from "models/project/fact/production/stratumData";
import { ProducingObject } from "models/project/producingObject/producingObject";
import { Stratum } from "models/project/stratum/stratum";
import { TreeRoot } from "models/tree/tree";

import { aggregateByDate, DateDataSeries } from "../../production/aggregate";
import { sumUp } from "../../production/aggregateFunctions";
import { ProductionDatum } from "../../production/production";
import { Well } from "../../well/well";
import { Forecast } from "../forecast";
import { Intervention } from "../interventions/intervention";

type FilterPredicate<T> = (child: T) => boolean;
function compileFilters<T>(filters: Array<FilterPredicate<T>>): FilterPredicate<T> {
  return (child) => {
    for (const filter of filters) {
      if (!filter(child)) {
        return false;
      }
    }
    return true;
  };
}

function accumOil(periods: StratumData[], prodPeriodsOnly: boolean = false): number {
  let result = 0;
  if (prodPeriodsOnly) {
    periods = periods.filter((p) => p.status === "prod");
  }
  for (const period of periods) {
    for (const [, datum] of period.byMonth()) {
      result += datum.oil_prod ?? 0;
    }
  }
  return result;
}

const STATUS_NAME = {
  prod: "Добывающая",
  inj: "Нагнетательная",
  idle: "Прочего назначения",
  noData: "Нет истории добычи",
} as const;

type WellStatus = (typeof STATUS_NAME)[keyof typeof STATUS_NAME];

type ByStratums = {
  oilRate: number | null;
  liquidRate: number | null;
  waterCut: number | null;
  recoverableResources: number | null;
};

type StopCriterion = {
  label: string;
  value: number;
  measure: string;
};

type DRow = {
  wellId?: number; // debug column
  gtmId?: number; // debug column
  wellTitle: string;
  eventTitle?: string;
  date?: Dayjs | null | undefined;
  wellStatus?: WellStatus;
  wellType?: string;
  wellPad?: string;
  fond?: string;
  licenseRegion?: string;
  producingObject?: string;
  stratum?: string;

  operationCoef?: number | null;

  liquidRate?: number | null;
  oilRate?: number | null;
  waterCut?: number | null;

  accumLiquid?: number | null;
  accumOil?: number | null;

  recoverableResourcesStart?: number | null;
  recoverableResourcesEnd?: number | null;
  recoverableResourcesRatio?: number | null;

  liquidDebitMethod?: ForecastMethod | null;
  oilDebitMethod?: ForecastMethod | null;

  stopCriterion?: StopCriterion;

  factIdleMonths?: number | null;

  absoluteIndex?: number;
};

class Debet extends TableNode<DRow, WellNode> {
  public readonly filterManager = new FilterManager(
    TECH_PREDICTION_DEBET_FILTER_MAP,
    TECH_PREDICTION_DEBET_STATIC_FILTERS,
    "well_prediction"
  );

  constructor(public readonly forecast: Forecast, private readonly tree: TreeRoot<Well>) {
    super(null, { mutable: false });
    makeObservable(this, {
      selectedEvents: computed,
    });

    runInAction(() => {
      this.childrenStore = new ChildrenStoreArray(
        this,
        forecast.wells.allWells
          .map((well) => [well, forecast.interventions.getInterventionsByWellId(well.id)] as const)
          .filter(([well, interventions]) => well.data.stratumId !== null || interventions.length > 0)
          .map(([well, interventions]) => new WellNode(this, forecast, well, interventions))
      );
    });

    reaction(
      () => tree.selectedItems,
      () => this.applyFilters()
    );

    when(
      () => {
        if (this.filterManager && this.filterManager.persistantManager) {
          return this.filterManager.persistantManager.isPersisting;
        } else {
          return false;
        }
      },
      () => {
        this.applyFilters();
      }
    );
  }

  public get selectedEvents(): EventNode[] {
    return [...TableNode.selectedNodes(this)].filter((node) => node.children === null) as EventNode[];
  }

  public applyFilters = () => {
    const selectedWellsIds = new Set(this.tree.selectedItems.map((node) => node.item!.id));
    const predicate = compileFilters<EventNode>(this.filterManager.predicates);
    transaction(() => {
      for (const child of this.childrenStore?.children ?? []) {
        child.childrenStore?.filter(predicate);
      }
      this.childrenStore?.filter(
        (node) => selectedWellsIds.has(node.well.id) && node.childrenStore?.visibleChildrenLength !== 0
      );
    });
  };

  public selectByPredicate = (predicate: (event: EventNode) => boolean) => {
    transaction(() => {
      for (const well of this.childrenStore?.children ?? []) {
        for (const event of well.childrenStore?.children ?? []) {
          if (predicate(event)) {
            event.selectManager?.propagateSelected();
          } else {
            event.selectManager?.propagateDeselected();
          }
        }
      }
    });
  };
}

class WellNode extends TableNode<DRow, EventNode> {
  public asDRow(): DRow {
    return {
      wellTitle: this.well.title,
    };
  }

  constructor(parentNode: Debet, forecast: Forecast, public readonly well: Well, interventions: Intervention[]) {
    super(parentNode, { mutable: false, isExpandedChildren: true });

    const wellEvents =
      well.fond === "New"
        ? [new EventNode(this, forecast, well, null)]
        : well.data.stratumIds.map((stratumId) => new EventNode(this, forecast, well, null, stratumId));
    runInAction(() => {
      this.childrenStore = new ChildrenStoreArray(this, [
        ...wellEvents,
        ...interventions.map((intervention) => new EventNode(this, forecast, well, intervention)),
      ]);
    });
  }
}

class EventNode extends TableNode<DRow> {
  public asDRow(): DRow {
    return {
      ...this.forecastSettingsFields,
      ...this.factualProductionFields,
      ...this.forecastProductionFields,
      ...this.recoverableResources,

      wellId: this.well.id,
      gtmId: this.intervention?.id,
      wellTitle: this.well.title,
      eventTitle:
        this.intervention?.typeName ?? (this.well.fond === "New" ? "Эксплуатационное бурение" : "Базовая добыча"),
      date: (this.intervention ?? this.well).date,
      wellStatus: this.wellStatus,
      wellType: this.well.type ?? undefined,
      wellPad: this.well.pad?.title,
      fond: this.well.fond === "Base" ? "Базовый" : "Новый",
      licenseRegion: this.well.licenseRegion?.title,
      stratum: this.stratum?.title,
      producingObject: this.producingObject?.title,
      stopCriterion: undefined,

      oilRate: this.byStratums.oilRate ?? null,
      liquidRate: this.byStratums.liquidRate ?? null,
      waterCut: this.byStratums.waterCut ?? null,
    };
  }

  public get date() {
    return (this.intervention ?? this.well).date;
  }

  public readonly stratumId: number | null = null;

  constructor(
    parentNode: WellNode,
    public readonly forecast: Forecast,
    public readonly well: Well,
    public readonly intervention: Intervention | null,
    wellStratumId?: number // if base fond well, stratumId for recoverableResources
  ) {
    super(parentNode, { mutable: false });

    if (well.fond === "Base" && intervention === null) {
      console.assert(
        wellStratumId !== undefined,
        "wellStratumId should be provided only for base production event",
        well,
        intervention
      );
    }

    if (intervention !== null) {
      this.stratumId = intervention.data.stratumId;
    } else if (well.fond === "New") {
      this.stratumId = well.data.stratumId;
    } else if (wellStratumId !== undefined) {
      console.assert(
        intervention === null,
        "wellStratumId should be provided only for base production event (no intervention)"
      );
      this.stratumId = wellStratumId;
    }

    makeObservable(this, {
      forecastSettings: computed,
      factProduction: computed,
      forecastProduction: computed,
      wellStatus: computed,
      stratum: computed,
      producingObject: computed,
      byStratums: computed,
    });

    runInAction(() => (this.childrenStore = null));
  }

  public get stratum(): Stratum | null | undefined {
    if (this.stratumId === null) {
      return null;
    }
    return this.forecast.stratums.at(this.stratumId);
  }

  public get producingObject(): ProducingObject | null | undefined {
    const stratum = this.stratum;
    if (!stratum) {
      return stratum;
    }
    return this.forecast.stratums.getProducingObject(stratum);
  }

  public get byStratums(): ByStratums {
    const getter = ((): ByStratums => {
      if (this.intervention !== null) {
        return this.intervention.data;
      }
      if (this.well.fond === "New") {
        return this.well.data;
      }
      if (this.stratumId === null) {
        return {
          oilRate: null,
          liquidRate: null,
          waterCut: null,
          recoverableResources: null,
        };
      }
      return this.well.data.byStratums[this.stratumId];
    })();
    const { oilRate, liquidRate, waterCut, recoverableResources } = getter;
    return {
      oilRate,
      liquidRate,
      waterCut,
      recoverableResources,
    };
  }

  public get forecastSettings(): SettingsSave | null | undefined {
    const [wellId, gtmId, stratumId] = [this.well.id, this.intervention?.id ?? null, this.stratumId];
    return this.forecast.techForecastSettings.get({ wellId, gtmId, stratumId });
  }

  public get shouldHaveFactProduction(): boolean {
    return this.intervention === null && this.well.fond === "Base";
  }

  public get factProduction(): StratumData[] | null | undefined {
    if (this.stratumId === null) {
      return null;
    }

    if (!this.shouldHaveFactProduction) {
      return null;
    }

    if (this.forecast.fact.production.isLoading) {
      return undefined;
    }

    const factProd = this.forecast.fact.production.wellData(this.well.id);
    const stratumsData = factProd?.dataByStratums.get(this.stratumId); // stratumId not null garanted by `shouldHaveFactProduction`

    if (stratumsData === undefined || stratumsData.length === 0) {
      return null; // no data for well->stratum
    }

    return stratumsData;
  }

  public get forecastProduction(): StratumData[] | null | undefined {
    if (!this.stratumId) {
      return null;
    }
    if (this.forecast.production.isLoading) {
      return undefined;
    }
    const forecastData = this.forecast.production
      .get(this.well.id, this.intervention?.id)
      ?.dataByStratums.get(this.stratumId);
    if (!forecastData || forecastData.length === 0) {
      return null;
    }

    return forecastData;
  }

  public get factualProductionFields(): Pick<DRow, "factIdleMonths"> {
    const factProduction = this.factProduction;
    if (!factProduction) {
      return {
        factIdleMonths: factProduction,
      };
    }

    const lastPeriod = factProduction[factProduction.length - 1];
    const factIdleMonths = lastPeriod.status === "idle" ? lastPeriod.range.monthDuration : 0;

    return {
      factIdleMonths,
    };
  }

  public get forecastProductionFields(): Pick<DRow, "accumOil" | "accumLiquid"> {
    const fcProduction = this.forecastProduction;
    if (!fcProduction) {
      return {
        accumOil: fcProduction,
        accumLiquid: fcProduction,
      };
    }

    const prod = fcProduction.map((d) => d.byYear());

    let accumOil: number | null = null;
    let accumLiquid: number | null = null;
    if (prod) {
      let byYear: DateDataSeries<ProductionDatum>;
      if (prod.length === 1) {
        byYear = prod[0];
      } else {
        byYear = aggregateByDate(sumUp, prod);
      }

      for (const [, datum] of byYear) {
        accumOil = (accumOil ?? 0) + (datum.oil_prod ?? 0);
        accumLiquid = (accumLiquid ?? 0) + (datum.liquid_prod ?? 0);
      }
    }

    return {
      accumOil,
      accumLiquid,
    };
  }

  public get forecastSettingsFields(): Pick<DRow, "liquidDebitMethod" | "oilDebitMethod" | "operationCoef"> {
    const fcSettings = this.forecastSettings;
    if (!fcSettings) {
      return {
        liquidDebitMethod: fcSettings,
        oilDebitMethod: fcSettings,
        operationCoef: fcSettings,
      };
    }
    return {
      liquidDebitMethod: fcSettings.liquid.method,
      oilDebitMethod: fcSettings.oil.method,
      operationCoef: fcSettings.prodTimeRatio,
    };
  }

  public get wellStatus(): WellStatus | undefined {
    if (this.stratumId === null) {
      return STATUS_NAME.noData;
    }
    if (!this.shouldHaveFactProduction) {
      return STATUS_NAME.prod;
    }
    const prod = this.factProduction;
    if (prod === undefined) {
      return undefined;
    }
    if (prod === null) {
      return STATUS_NAME.noData;
    }
    let currentPeriod = prod.length - 1;
    let status: WellStatus = STATUS_NAME.idle;
    while (currentPeriod >= 0) {
      const currentStatus = prod[currentPeriod].status;
      if (currentStatus !== "idle") {
        status = STATUS_NAME[currentStatus];
        break;
      }
      currentPeriod--;
    }
    return status;
  }

  public get recoverableResources(): Pick<
    DRow,
    "recoverableResourcesStart" | "recoverableResourcesEnd" | "recoverableResourcesRatio"
  > {
    const { recoverableResources } = this.byStratums;
    if (!recoverableResources) {
      return {
        recoverableResourcesStart: recoverableResources,
        recoverableResourcesEnd: recoverableResources,
        recoverableResourcesRatio: recoverableResources,
      };
    }

    const factProduction = this.factProduction;
    if (this.shouldHaveFactProduction && !factProduction) {
      return {
        recoverableResourcesStart: factProduction,
        recoverableResourcesEnd: factProduction,
        recoverableResourcesRatio: factProduction,
      };
    }
    const accumOilBase = factProduction ? accumOil(factProduction) : 0;
    const recoverableResourcesStart = recoverableResources + accumOilBase;

    const forecastProduction = this.forecastProduction;
    if (!forecastProduction) {
      return {
        recoverableResourcesStart,
        recoverableResourcesEnd: forecastProduction,
        recoverableResourcesRatio: forecastProduction,
      };
    }
    const accumOilForecast = accumOil(forecastProduction);
    const recoverableResourcesEnd = recoverableResourcesStart - accumOilForecast - accumOilBase; // = recoverableResources - accumOilForecast
    const recoverableResourcesRatio = (recoverableResourcesEnd / recoverableResourcesStart) * 100; // в %
    return {
      recoverableResourcesStart,
      recoverableResourcesEnd,
      recoverableResourcesRatio,
    };
  }
}

export type { DRow };
export { Debet, EventNode };
