import { FC } from "react";
import { observer } from "mobx-react";

import { Icon } from "elements/icon/icon";
import { MODE_ICONS } from "elements/modeSelector/modeSelector";

import { ReactComponent as StopIcon } from "../icons/stop.svg";
import { useTechForecastModel } from "../useTechForecastModel";

import { ValidationInput } from "./legacyInputs/validationInput";
import { Method } from "./methods/method";
import { Param, Params } from "./param";
import { SettingsFrame } from "./settingsFrame";

import cn from "./wellTechForecastSettings.module.less";

const WellTechForecastSettings: FC = observer(() => {
  const model = useTechForecastModel();
  const fc = model.currentForecast;
  const { settings } = fc;
  return (
    <div className={cn.layout}>
      {settings.metricForCompute !== "liquid" && (
        <SettingsFrame icon={MODE_ICONS.liquidRate} title="Темп падения жидкости" fitting={fc.getFitting("liquid")} className={cn.liquid}>
          <Method mode="liquid" />
        </SettingsFrame>
      )}
      {settings.metricForCompute !== "waterCut" && (
        <SettingsFrame icon={MODE_ICONS.waterCut} title="Прогноз обводнённости" fitting={fc.getFitting("waterCut")} className={cn.oil}>
          <Method mode="waterCut" />
        </SettingsFrame>
      )}
      {settings.metricForCompute !== "oil" && (
        <SettingsFrame icon={MODE_ICONS.oilRate} title="Темп падения нефти" fitting={fc.getFitting("oil")} className={cn.waterCut}>
          <Method mode="oil" />
        </SettingsFrame>
      )}
      <SettingsFrame icon={<Icon viewBox="0 0 16 16" content={<StopIcon />} />} stopCriterion title="Критерии остановки">
        <Params>
          <Param title="Обводненность, %">
            <ValidationInput
              key={model.currentKey}
              value={settings.stopCriterion.waterCut}
              onSave={settings.stopCriterion.holder("waterCut")}
              min={0}
              max={100}
            />
          </Param>
          <Param title="Дебит нефти, т/сут">
            <ValidationInput
              key={model.currentKey}
              value={settings.stopCriterion.oilRate}
              onSave={settings.stopCriterion.holder("oilRate")}
              min={0}
              max={10000}
            />
          </Param>
          <Param title="Период отработки на нефть, мес">
            <ValidationInput
              key={model.currentKey}
              value={settings.stopCriterion.oilProdMonthDuration}
              onSave={settings.stopCriterion.holder("oilProdMonthDuration")}
              min={1}
              max={2400}
            />
          </Param>
          <Param title="Мин. темп падения дебита жидкости, %/мес">
            <ValidationInput
              key={model.currentKey}
              value={settings.stopCriterion.oilDropRate}
              onSave={settings.stopCriterion.holder("oilDropRate")}
              min={0}
              max={100}
              disabled
            />
          </Param>
          <Param title="Мин. темп падения дебита нефти, %/мес">
            <ValidationInput
              key={model.currentKey}
              value={settings.stopCriterion.liquidDropRate}
              onSave={settings.stopCriterion.holder("liquidDropRate")}
              min={0}
              max={100}
              disabled
            />
          </Param>
          <Param title="Макс. отбор от НИЗ, д.ед.">
            <ValidationInput
              key={model.currentKey}
              value={settings.stopCriterion.recoverableResources}
              onSave={settings.stopCriterion.holder("recoverableResources")}
              min={0}
              max={1}
            />
          </Param>
          <Param title="Макс. накопленная добыча нефти, тыс. т">
            <ValidationInput
              key={model.currentKey}
              value={settings.stopCriterion.accumOilProd}
              onSave={settings.stopCriterion.holder("accumOilProd")}
              min={0}
              max={100000}
            />
          </Param>
          <Param title={<b>Коэффициент эксплуатации</b>}>
            <ValidationInput
              key={model.currentKey}
              required
              value={settings.prodTimeRatio.prodTimeRatio}
              onSave={settings.prodTimeRatio.holder}
              min={0}
              max={5}
            />
          </Param>
        </Params>
      </SettingsFrame>
    </div>
  );
});

export { WellTechForecastSettings };
