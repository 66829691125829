import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { makeAutoObservable, reaction } from "mobx";

import { User } from "models/user";
import { BackendStorageMock } from "services/back/backendStorage";

import { Project } from "./project/project";
import { global } from "./global";

type LogNote = {
  date: string;
  user: User;
  title: string;
  scenarioId?: number | null;
  scenarioTitle?: string | null;
};

class Logger {
  private logger?: BackendStorageMock<void, LogNote[]>;
  private logs: LogNote[] = [];
  private globalLogs: Record<number, LogNote[]> = {};
  private project?: Project;
  private scenarioId?: number;
  public isLoadingLog = false;

  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.project,
      async (newProject) => {
        this.logger = new BackendStorageMock<void, LogNote[]>("log", newProject?.id!, undefined, false);
        if (this.globalLogs[newProject?.id!]) {
          this.logs = this.globalLogs[newProject?.id!];
        } else {
          this.logs = (await this.logger.getItem()) ?? [];
          this.isLoadingLog = false;
        }
      }
    );
  }

  addNote = async (key: string) => {
    if (this.logger && global.user) {
      const logs = (await this.logger.getItem()) ?? [];
      const newLogs = await this.logger.setItem([
        ...logs,
        { date: new Date().toString(), title: key, user: global.user, scenarioId: this.scenarioId ?? null },
      ]);
      this.logs = newLogs ?? [];
    } else {
      console.assert("Логирование может быть произведено только в рамках проекта");
    }
  };

  setProject = (newProject: Project) => {
    this.project = newProject;
    if (!this.globalLogs[newProject?.id]) {
      this.isLoadingLog = true;
    }
  };

  setScenarioId = (newScenarioId: number) => {
    this.scenarioId = newScenarioId;
  };

  get currentProjectLog(): LogNote[] {
    return this.logs;
  }
}

class LoggerStore extends TableNode<LogNote, LogNode> {
  constructor(private logs: LogNote[], project: Project) {
    super();
    this.childrenStore = new ChildrenStoreArray(
      this,
      logs.map((log) => new LogNode(this, log, project))
    );
  }

  public get getLogs() {
    return this.logs;
  }
}

class LogNode extends TableNode<LogNote> {
  public asDRow(): LogNote {
    return {
      title: this.logNote.title,
      user: this.logNote.user,
      date: this.logNote.date,
      scenarioTitle: this.project?.scenarios.find(({ id }) => id === this.logNote.scenarioId)?.title,
    };
  }
  constructor(parentNode: LoggerStore, private logNote: LogNote, private project: Project) {
    super(parentNode);
  }
}

export { Logger, LoggerStore };
export type { LogNote };
