import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { action, computed, makeObservable, observable, reaction, transaction, when } from "mobx";

import { UOM } from "elements/uom";
import { SearchParamsStorage } from "elements/useSearchParamsStorage";
import { global } from "models/global";
import { Fact } from "models/project/fact/fact";
import { Forecast } from "models/project/fact/forecast/forecast";
import { byPairs } from "utils/itertools";

const FACTOR_ANALYSIS_STORAGE_KEY = "factor_analysis" as const;

type DRow = {
  scenarioId: number | null;
  ecyId: number;
  factor: [number | null, string?] | undefined;

  factorValue: number | null;
  factorValueMeasure: string | null;
  factorDeltaValue: number | null;
  factorDeltaRelativeValue: number | null;

  npv: number | null | undefined;
  delta: number | null | undefined;
  deltaRelative: number | null | undefined;
  prev: FactorAnalysisRow | null;
  next: FactorAnalysisRow | null;
  move: (move: "up" | "down") => void;
  remove: () => void;
};

type RowData = {
  scenarioId: number;
  ecyId: number;
  factor: [number | null, string?];
};

function swapLinks(r1: FactorAnalysisRow, r2: FactorAnalysisRow) {
  console.assert(r1.next === r2, r2.prev === r1);
  const prev = r1.prev;
  const next = r2.next;
  r1.prev = r2;
  r2.next = r1;
  r1.next = next;
  r2.prev = prev;
  if (prev !== null) {
    prev.next = r2;
  }
  if (next !== null) {
    next.prev = r1;
  }
}

class FactorAnalysisTableStore extends TableNode<DRow, FactorAnalysisRow> {
  public range: "forecast" | "full" = "full";
  public lastRow: FactorAnalysisRow | null = null;
  public firstRow: FactorAnalysisRow | null = null;
  public isMoved: boolean = false;

  constructor(public readonly fact: Fact, private readonly storage: SearchParamsStorage) {
    super();

    makeObservable(this, {
      isMoved: observable,
      range: observable,
      firstRow: observable,
      lastRow: observable,
      onChangeRange: action,
      addScenario: action,
      ecyOptions: computed,
    });

    when(
      () => this.ecyStore.isLoading === false,
      () => this.init()
    );

    reaction(
      () => [
        ...Array.from(this.childrenStore?.children ?? []).map((row) => row.factor),
        ...Array.from(this.childrenStore?.children ?? []).map((row) => row.ecyId),
        ...Array.from(this.childrenStore?.children ?? []).map((row) => row.scenarioId),
        ...Array.from(this.childrenStore?.children ?? []).map((row) => row.npv),
      ],
      () => {
        Array.from(this.childrenStore?.children ?? []).forEach((row) => {
          row.factorValue = null;
          row.factorValueMeasure = null;
          row.factorDeltaValue = null;
          row.factorDeltaRelativeValue = null;
        });
        Array.from(this.childrenStore?.children ?? []).forEach((row) => {
          if (row.factor[0] === 1) {
            row.factorValue = row.forecast?.cached?.[row.ecyId]?.factorValues[this.range][row.factor[0]] ?? null;
            row.factorValueMeasure = new UOM(5, 4, global.uomResolver).unit;

            if (
              row.prev !== null &&
              row.factorValue !== null &&
              row.prev.forecast?.cached?.[row.ecyId]?.factorValues[this.range][row.factor[0]] !== null &&
              row.prev.forecast?.cached?.[row.ecyId]?.factorValues[this.range][row.factor[0]] !== undefined
            ) {
              row.factorDeltaValue =
                row.factorValue - row.prev.forecast?.cached?.[row.ecyId]?.factorValues[this.range][row.factor[0]]!;
              row.factorDeltaRelativeValue =
                ((row.factorValue - row.prev.forecast?.cached?.[row.ecyId]?.factorValues[this.range][row.factor[0]]!) /
                  Math.abs(row.prev.forecast?.cached?.[row.ecyId]?.factorValues[this.range][row.factor[0]]!)) *
                100;
            }
          }
          if (row.factor[0] === 2) {
            row.factorValue = row.forecast?.cached?.[row.ecyId]?.factorValues[this.range][row.factor[0]] ?? null;
            row.factorValueMeasure = new UOM(12, 1, global.uomResolver).unit;
            if (
              row.prev !== null &&
              row.factorValue !== null &&
              row.prev.forecast?.cached?.[row.ecyId]?.factorValues[this.range][row.factor[0]] !== null &&
              row.prev.forecast?.cached?.[row.ecyId]?.factorValues[this.range][row.factor[0]] !== undefined
            ) {
              row.factorDeltaValue =
                row.factorValue - row.prev.forecast?.cached?.[row.ecyId]?.factorValues[this.range][row.factor[0]]!;
              row.factorDeltaRelativeValue =
                ((row.factorValue - row.prev.forecast?.cached?.[row.ecyId]?.factorValues[this.range][row.factor[0]]!) /
                  Math.abs(row.prev.forecast?.cached?.[row.ecyId]?.factorValues[this.range][row.factor[0]]!)) *
                100;
            }
          }
          if (row.factor[0] === 3) {
            row.factorValue = row.forecast?.cached?.[row.ecyId]?.factorValues[this.range][row.factor[0]] ?? null;
            row.factorValueMeasure = new UOM(2, 3, global.uomResolver).unit;
            if (
              row.prev !== null &&
              row.factorValue !== null &&
              row.prev.forecast?.cached?.[row.ecyId]?.factorValues[this.range][row.factor[0]] !== null &&
              row.prev.forecast?.cached?.[row.ecyId]?.factorValues[this.range][row.factor[0]] !== undefined
            ) {
              row.factorDeltaValue =
                row.factorValue - row.prev.forecast?.cached?.[row.ecyId]?.factorValues[this.range][row.factor[0]]!;
              row.factorDeltaRelativeValue =
                ((row.factorValue - row.prev.forecast?.cached?.[row.ecyId]?.factorValues[this.range][row.factor[0]]!) /
                  Math.abs(row.prev.forecast?.cached?.[row.ecyId]?.factorValues[this.range][row.factor[0]]!)) *
                100;
            }
          }
          if (row.factor[0] === 4) {
            row.factorValue = row.forecast?.cached?.[row.ecyId]?.factorValues[this.range][row.factor[0]] ?? null;
            if (row.factorValue != null) {
              row.factorValue *= -1;
            }
            row.factorValueMeasure = new UOM(2, 3, global.uomResolver).unit;
            if (
              row.prev !== null &&
              row.factorValue !== null &&
              row.prev.forecast?.cached?.[row.ecyId]?.factorValues[this.range][row.factor[0]] !== null &&
              row.prev.forecast?.cached?.[row.ecyId]?.factorValues[this.range][row.factor[0]] !== undefined
            ) {
              row.factorDeltaValue =
                row.factorValue - row.prev.forecast?.cached?.[row.ecyId]?.factorValues[this.range][row.factor[0]]! * -1;
              row.factorDeltaRelativeValue =
                ((row.factorValue -
                  row.prev.forecast?.cached?.[row.ecyId]?.factorValues[this.range][row.factor[0]]! * -1) /
                  Math.abs(row.prev.forecast?.cached?.[row.ecyId]?.factorValues[this.range][row.factor[0]]!)) *
                100;
            }
          }
          if (row.factor[0] === 5) {
            row.factorValue = row.forecast?.cached?.[row.ecyId]?.factorValues[this.range][row.factor[0]] ?? null;
            row.factorValueMeasure = new UOM(2, 3, global.uomResolver).unit;
            if (
              row.prev !== null &&
              row.factorValue !== null &&
              row.prev.forecast?.cached?.[row.ecyId]?.factorValues[this.range][row.factor[0]] !== null &&
              row.prev.forecast?.cached?.[row.ecyId]?.factorValues[this.range][row.factor[0]] !== undefined
            ) {
              row.factorDeltaValue =
                row.factorValue - row.prev.forecast?.cached?.[row.ecyId]?.factorValues[this.range][row.factor[0]]!;
              row.factorDeltaRelativeValue =
                ((row.factorValue - row.prev.forecast?.cached?.[row.ecyId]?.factorValues[this.range][row.factor[0]]!) /
                  Math.abs(row.prev.forecast?.cached?.[row.ecyId]?.factorValues[this.range][row.factor[0]]!)) *
                100;
            }
          }
        });
      }
    );
  }

  public init() {
    this.isMoved = false;
    const rawRows = this.storage.getItem<RowData[]>(FACTOR_ANALYSIS_STORAGE_KEY);
    const rows = rawRows?.map(
      ({ ecyId, scenarioId, factor }) => new FactorAnalysisRow(this, ecyId, scenarioId, factor)
    );
    for (const [prev, next] of byPairs(rows ?? [])) {
      prev.next = next;
      next.prev = prev;
    }
    this.firstRow = rows?.at(0) ?? null;
    this.lastRow = rows?.at(-1) ?? null;
    this.childrenStore = new ChildrenStoreArray(this, rows ?? []);
  }

  public save = () => {
    if (!this.children) {
      return;
    }
    const toSave = [...this.children].map((c) => c.data).filter((d): d is RowData => d !== undefined);
    if (toSave.length === 0) {
      this.storage.removeItem(FACTOR_ANALYSIS_STORAGE_KEY);
    } else {
      this.storage.setItem<RowData[]>(FACTOR_ANALYSIS_STORAGE_KEY, toSave);
    }
    this.mutationsManager?.dropMutations();
    this.init();
  };

  get isUpdated(): boolean {
    if (super.isUpdated) {
      return true;
    }
    return this.isMoved;
  }

  public get ecyStore() {
    return this.fact.ecyStore;
  }

  public forecastById(id: number): Forecast | null {
    console.assert(this.fact.forecasts.isLoading === false);
    return this.fact.forecasts.at(id) ?? null;
  }

  get ecyOptions() {
    return this.fact.ecyStore.selectItems;
  }

  public addScenario = () => {
    if (this.ecyStore.isLoading) {
      return;
    }
    const ecyId = this.ecyStore.first!.id;
    const newRow = new FactorAnalysisRow(this, ecyId, null, [null, undefined]);
    if (this.lastRow !== null) {
      this.lastRow.next = newRow;
      newRow.prev = this.lastRow;
    }
    if (this.firstRow === null) {
      this.firstRow = newRow;
    }
    this.lastRow = newRow;
    this.childrenStore?.push(newRow);
  };

  public onChangeRange = (value: "forecast" | "full") => {
    this.range = value;
  };
}

class FactorAnalysisRow extends TableNode<DRow> {
  public asDRow = (): DRow => ({
    scenarioId: this.scenarioId,
    ecyId: this.ecyId,
    factor: this.factor,
    prev: this.prev,
    next: this.next,

    factorValue: this.factorValue,
    factorValueMeasure: this.factorValueMeasure,
    factorDeltaValue: this.factorDeltaValue,
    factorDeltaRelativeValue: this.factorDeltaRelativeValue,

    npv: this.npv,
    delta: this.delta,
    deltaRelative: this.deltaRelative,

    move: this.move,
    remove: this.remove,
  });

  public prev: FactorAnalysisRow | null = null;
  public next: FactorAnalysisRow | null = null;
  public factorValue: number | null = null;
  public factorValueMeasure: string | null = null;
  public factorDeltaValue: number | null = null;
  public factorDeltaRelativeValue: number | null = null;

  constructor(
    private root: FactorAnalysisTableStore,
    public ecyId: number,
    public scenarioId: number | null = null,
    public factor: [number | null, string?]
  ) {
    super(root);
    makeObservable<FactorAnalysisRow, "swapLinks" | "move" | "remove">(this, {
      scenarioId: observable,
      ecyId: observable,
      factor: observable,
      prev: observable.ref,
      next: observable.ref,

      factorValue: observable,
      factorValueMeasure: observable,
      factorDeltaValue: observable,
      factorDeltaRelativeValue: observable,

      forecast: computed,
      data: computed,
      npv: computed,
      delta: computed,
      deltaRelative: computed,
      ecyTitle: computed,

      updateValue: action,
      remove: action,
      move: action,
      swapLinks: action,
    });

    this.childrenStore = null;
  }

  public get data(): RowData | undefined {
    if (this.scenarioId === null) {
      return undefined;
    }
    return {
      scenarioId: this.scenarioId,
      ecyId: this.ecyId,
      factor: this.factor,
    };
  }

  public get scenarioTitle() {
    return this.forecast?.title;
  }

  public get ecyTitle() {
    return this.forecast?.fact.ecyStore.selectItems
      .map((item) => item.options)
      .flat()
      .find((option) => option.value === this.ecyId)?.text;
  }

  public get forecast(): Forecast | null {
    if (this.scenarioId === null) {
      return null;
    }
    return this.root.forecastById(this.scenarioId);
  }

  public get npv(): number | null | undefined {
    if (!this.forecast) {
      return null;
    }
    const result = this.forecast.cached?.[this.ecyId];
    if (!result) {
      return result;
    }
    return result.comparisonValues?.[this.root.range === "forecast" ? "NPV за прогнозный период" : "NPV"];
  }

  public get delta(): number | null | undefined {
    if (!this.prev) {
      return null; // first row
    }
    const [npv, prevNpv] = [this.npv, this.prev.npv];
    if (npv === null || npv === undefined) {
      return npv;
    }
    if (prevNpv === null || prevNpv === undefined) {
      return prevNpv;
    }
    return npv - prevNpv;
  }

  public get deltaRelative(): number | null | undefined {
    if (!this.prev) {
      return null; // first row
    }
    const [npv, prevNpv] = [this.npv, this.prev.npv];
    if (npv === null || npv === undefined) {
      return npv;
    }
    if (prevNpv === null || prevNpv === undefined) {
      return prevNpv;
    }
    return ((npv - prevNpv) / Math.abs(prevNpv)) * 100;
  }

  private swapLinks(direction: "up" | "down") {
    if (direction === "up") {
      if (this.prev === null) {
        console.error("move up when prev null");
        return;
      }
      const prev = this.prev;
      swapLinks(prev, this);
      if (prev.next === null) {
        this.root.lastRow = prev;
      }

      if (this.prev === null) {
        this.root.firstRow = this;
        this.root.firstRow.factor = [null];
      }

      return;
    }
    if (direction === "down") {
      if (this.next === null) {
        console.error("move down when next null");
        return;
      }
      const next = this.next;
      swapLinks(this, next);
      if (this.next === null) {
        this.root.lastRow = this;
      }

      if (next.prev === null) {
        this.root.firstRow = next;
        this.root.firstRow.factor = [null];
      }
    }
  }

  private move = (direction: "up" | "down") => {
    if (this.index === undefined) {
      console.error("attempt to move row w/o index");
      return;
    }
    const delta = direction === "up" ? -1 : 1;
    const resultIndex = this.index + delta;
    if (resultIndex < 0 || resultIndex > (this.root.childrenStore?.size ?? 0) - 1) {
      return;
    }
    transaction(() => {
      this.root.isMoved = true;
      this.swapLinks(direction);
      this.parentNode!.childrenStore?.splice(this.index!, 1);
      this.parentNode!.childrenStore?.splice(resultIndex, 0, this);
    });
  };

  private remove = () => {
    if (this.index === undefined) {
      console.error("attempt to remove row w/o index");
      return;
    }
    if (this.root.lastRow === this) {
      this.root.lastRow = this.prev;
    }
    if (this.prev) {
      this.prev.next = this.next;
    }
    if (this.next) {
      this.next.prev = this.prev;
    }
    this.parentNode?.childrenStore?.splice(this.index, 1);
  };

  updateValue(key: any, newValue: any): [prevValue: any, currValue: any] {
    if (!FactorAnalysisRow.isEditableField(key)) {
      console.error(`not editable field: ${key}`);
      return [undefined, undefined];
    }
    const prev = this[key];
    (this[key] as any) = newValue;
    return [prev, newValue];
  }

  static isEditableField(key: any): key is "scenarioId" | "ecyId" | "factor" {
    return ["scenarioId", "ecyId", "factor"].includes(key);
  }
}

export type { DRow };
export { FactorAnalysisRow, FactorAnalysisTableStore };
