import type { FC } from "react";
import { DatePicker, Select } from "antd";
import { observer } from "mobx-react";

import { ValidationInput } from "elements/inputs/validationInput/validationInput";
import { useTechForecastModel } from "features/techForecast/useTechForecastModel";
import { useProject } from "models/project/project";
import { BINDING_SELECTOR, ForecastMode } from "services/back/techForecast/request";

import { ApplyForGroupButton } from "../applyForAllButton";
import { Param, Params } from "../param";

import { MethodParams } from "./methodParams";

import cn from "./method.module.less";

type MethodProps = {
  mode: ForecastMode;
};

const Method: FC<MethodProps> = observer(({ mode }) => {
  const project = useProject()!;
  const { currentForecast, currentKey } = useTechForecastModel();
  const modeSettings = currentForecast.settings[mode];
  const miningTitle = mode === "waterCut" ? "Стартовое значение" : "Стартовый дебит";
  return (
    <Params>
      {currentForecast.group === "base" && (
        <Param title="Период аппроксимации">
          <div className={cn.selectors}>
            <DatePicker.RangePicker
              disabledDate={currentForecast.disallowedMothPredicate(mode)}
              picker="month"
              size="small"
              style={{ width: 240 }}
              value={currentForecast.factDateRange(mode)}
              onChange={currentForecast.factDateRangeHolder(mode)}
            />
          </div>
        </Param>
      )}
      <Param title="Дата начала прогноза">
        <DatePicker size="small" picker="month" disabled value={project.actualStateDate} />
      </Param>
      {currentForecast.group === "base" && (
        <Param title={<b>{miningTitle}</b>}>
          <div className={cn.selectors}>
            <Select
              style={{ width: 240 }}
              options={BINDING_SELECTOR}
              value={modeSettings.bindingMode}
              onChange={modeSettings.bindingModeHolder}
              size="small"
            />
          </div>
        </Param>
      )}
      {modeSettings.bindingMode === "manual" && (
        <Param title={currentForecast.group === "base" ? <span /> : <b>{miningTitle}</b>}>
          <div className={cn.selectors}>
            <ValidationInput
              key={currentKey}
              value={modeSettings.bindingValue}
              onSave={modeSettings.bindingValueHolder}
              min={0}
              max={Infinity}
              size="small"
            />
          </div>
        </Param>
      )}
      <Param title={<b>Способ расчета</b>}>
        <div className={cn.selectors}>
          <ApplyForGroupButton className={cn.applyGroup} group={mode} />
          <Select
            style={{ width: 210 }}
            options={currentForecast.settings.methodSelector(mode)}
            value={modeSettings.method}
            onChange={modeSettings.methodSelectionHolder}
            size="small"
          />
        </div>
      </Param>
      <MethodParams mode={mode} method={modeSettings.method} />
    </Params>
  );
});

export { Method };
