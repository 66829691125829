import { WellTechProduction } from "services/back/techForecast/request";
import { WellFactProduction } from "services/back/techForecast/techForecast";

const KNOWN_GROUPS: Record<string, { title: string; items: Set<string> }> = {
  liquid: {
    title: "Жидкость",
    items: new Set(["liquidRateM3", "liquidRateT"]),
  },
  oil: {
    title: "Нефть",
    items: new Set(["oilRateM3", "oilRateT"]),
  },
  waterCut: {
    title: "Обводнённость",
    items: new Set(["waterCutVol", "waterCutMass"]),
  },
};

const FORECAST_MODES = ["oil", "liquid", "waterCut"] as const;

/**
 * Скрытые для отображения на временной оси прааметры, расчитаны для другой связи осей:
 * 1. y = lnVnfVol, x = accumOilProdT
 * 2. y = waterCutVol, x = recoveryRate
 * тут надо будет выбирать интервал, выкалывать точки
 *
 * три классических вида:
 *  х - время
 *  y - waterCutVol/oilRateT/liquidRateM3
 */
const HIDDEN = new Set(["lnVnfVol", "months", "years", "steps", "daysInMonth", "normalizedSteps"]);

const KNOWN_AXIS_ENDINGS = {
  M3: "м³",
  RateM3: "м³/сут",
  T: "т",
  RateT: "т/сут",
  Ratio: "ед",
};

const AXIS_KEYS = new Set([...Object.values(KNOWN_AXIS_ENDINGS), "%", "д.ед.", ""]);

const axisInference = (channelKey: string): string =>
  channelKey.startsWith("normalized")
    ? "д.ед."
    : META[channelKey]?.axis ??
      Object.entries(KNOWN_AXIS_ENDINGS)
        // сортируем по длине чтобы эмитировать подбор по специфичности. RateM3 будет проверен раньше чем M3
        .sort(([keyA], [keyB]) => keyB.length - keyA.length)
        .find(([key]) => channelKey.endsWith(key))?.[1] ??
      "";

const META: Record<
  string,
  {
    title: string;
    axis: string;
    color: string | null;
    visibleByDefault?: true;
  }
> = {
  liquidRateM3: {
    title: "Дебит жидкости",
    axis: "м³/сут",
    color: "#0040c1",
    visibleByDefault: true,
  },
  liquidRateT: {
    title: "Дебит жидкости",
    color: "#528bff",
    axis: "т/сут",
  },
  oilRateM3: {
    title: "Дебит нефти",
    axis: "м³/сут",
    color: "#8c564b",
    visibleByDefault: true,
  },
  oilRateT: {
    title: "Дебит нефти",
    axis: "т/сут",
    color: "#ac8944",
  },
  waterCutVol: {
    title: "Объемная обводнённость",
    axis: "%",
    color: "#06aed4",
    visibleByDefault: true,
  },
  waterCutMass: {
    title: "Весовая обводнённость",
    axis: "%",
    color: "#67e3f9",
  },
  liquidProdM3: {
    title: "Добыча жидкости",
    axis: "м³",
    color: null,
  },
  liquidProdT: {
    title: "Добыча жидкости",
    axis: "т",
    color: null,
  },
  oilProdM3: {
    title: "Добыча нефти",
    axis: "м³",
    color: null,
  },
  oilProdT: {
    title: "Добыча нефти",
    axis: "т",
    color: null,
  },
  waterProdT: {
    title: "Добыча воды",
    axis: "т",
    color: null,
  },
  waterProdM3: {
    title: "Добыча воды",
    axis: "м³",
    color: null,
  },
  apgProdM3: {
    title: "Добыча нефтяного газа",
    axis: "м³",
    color: "#06aed4",
  },
  prodTimeRatio: {
    title: "Коэффициент эксплуатации",
    axis: "ед",
    color: null,
  },
  accumLiquidProdM3: {
    title: "Накопленная добыча жидкости",
    axis: "м³",
    color: null,
  },
  accumOilProdT: {
    title: "Накопленная добыча нефти",
    axis: "т",
    color: null,
  },
  accumWaterProdM3: {
    title: "Накопленная добыча воды",
    axis: "м³",
    color: null,
  },
  recoveryRate: {
    title: "Отбор от НИЗ",
    axis: "д.ед.",
    color: null,
  },
  densityOil: {
    title: "Плотность нефти",
    axis: "кг/м³",
    color: null,
  },
  lnVnfVol: {
    title: "ln(ВНФ)",
    axis: "lnVnfVol",
    color: null,
  },
  gasOilRatio: {
    title: "Газовый фактор",
    axis: "ед",
    color: null,
  },
  densityWater: {
    title: "Плотность воды",
    axis: "кг/м³",
    color: null,
  },
  normalizedOilRateT: {
    title: "Нормированный суточный дебит нефти",
    axis: "т",
    color: null,
  },
  normalizedLiquidRateM3: {
    title: "Нормированный суточный дебит жидкости",
    axis: "м³/сут",
    color: null,
  },
};

const channelKeys = (channels: WellTechProduction | WellFactProduction): string[] =>
  Object.entries(channels.factProduction ?? channels.forecastProduction!)
    .filter(
      ([key, value]) =>
        Array.isArray(value) &&
        (typeof (value as (number | null | string)[]).find((v) => v !== null) === "number" ||
          ((channels as any).forecastProduction &&
            typeof (((channels as any).forecastProduction as any)[key] as (number | null | string)[]).find(
              (v) => v !== null
            ) === "number")) &&
        !HIDDEN.has(key)
    )
    .map(([key]) => key);

export { AXIS_KEYS, axisInference, channelKeys, FORECAST_MODES, HIDDEN, KNOWN_GROUPS, META };
