import { Comparison } from "pages/project/comparison";
import { EcyParameters } from "pages/project/finance/ecyParameters";
import { InvestParameters } from "pages/project/finance/investParameters";
import { OperatingParameters } from "pages/project/finance/operatingParameters";
import { Reference } from "pages/project/finance/reference";
import { Cashflow } from "pages/project/finance/results/cashflow";
import { InvestReport } from "pages/project/finance/results/investReport";
import { OperatingReport } from "pages/project/finance/results/operatingReport";
import { SummaryForecast } from "pages/project/finance/results/summaryForecast";
import { WellsReport } from "pages/project/finance/results/wells";
import { TaxParams } from "pages/project/finance/taxParams";
import { LicenseRegions } from "pages/project/general/licenseRegions";
import { LogsPage } from "pages/project/general/logs";
import { ProducingObjects } from "pages/project/general/producingObjects";
import { Project } from "pages/project/general/project";
import { Infrastructure } from "pages/project/Infrastructure/infrastructure";
import { ParticipantsPage } from "pages/project/participantsPage";
import { Debet } from "pages/project/tech/debet/debet";
import { InjectionPrediction } from "pages/project/tech/injPrediction";
import { WellInterventions } from "pages/project/tech/intervention";
import { OperatingGTM } from "pages/project/tech/operatingGTM";
import { ProducingObjectsParams } from "pages/project/tech/producingObjectsParams";
import { Summary } from "pages/project/tech/summary";
import { Tech } from "pages/project/tech/tech";
import { WellsList } from "pages/project/tech/wellsList";
import { Ranking } from "pages/ranking/rankingPage";
import { RCPResults } from "pages/ranking/results";
import { Sequential } from "pages/ranking/sequential";
import { Settings } from "pages/ranking/settings";
import { ApgStatePlan } from "pages/report/apgStatePlan";
import { ModelReport } from "pages/report/modelReport";
import { OilStatePlan } from "pages/report/oilStatePlan";
import { TepReport } from "pages/report/tepReport";

import { ReactComponent as BookIcon } from "./1.book.svg";
import { ReactComponent as TeamIcon } from "./2.team.svg";
import { ReactComponent as DevelopmentIcon } from "./3.development.svg";
import { ReactComponent as Economy } from "./4.economy.svg";
import { ReactComponent as Calendar } from "./5.calendar.svg";
import { ReactComponent as InfrastructureIcon } from "./6.infrastructure.svg";
import { ReactComponent as Compare } from "./7.compare.svg";
import { ReactComponent as Report } from "./8.report.svg";

type Page = {
  key: string;
  title: string;
  element: React.ReactNode;
};

type ProjectFolderWithSubmenu = {
  // в этм случае ключ входит в адресную строку но через item, открывая его по дефолту
  key: string;
  title: React.ReactNode;
  icon: React.ReactNode;
  children: Page[];
};

type ProjectFolderWithoutSubmenu = {
  // этот ключ входит в адресную строку
  key: string;
  title: React.ReactNode;
  icon: React.ReactNode;
  element: React.ReactNode;
};

type ProjectFolder = ProjectFolderWithSubmenu | ProjectFolderWithoutSubmenu;

const DEFAULT_PROJECT_PAGE = "general";

const GENERAL: ProjectFolder = {
  key: "general",
  title: "Данные проекта",
  icon: <TeamIcon />,
  children: [
    {
      key: "general",
      title: "Основные параметры проекта",
      element: <Project />,
    },
    {
      key: "license",
      title: "Лицензионные участки",
      element: <LicenseRegions />,
    },
    {
      key: "scenario",
      title: "Перечень сценариев",
      element: null,
    },
    {
      key: "producing_objects",
      title: "Объекты разработки",
      element: <ProducingObjects />,
    },
    {
      key: "logs",
      title: "Логирование",
      element: <LogsPage />,
    },
  ],
};

const MANAGEMENT: ProjectFolder = {
  key: "users",
  title: "Участники проекта",
  element: <ParticipantsPage />,
  icon: <BookIcon />,
};

const IS_APRIL_FOOLS_DAY = new Date().toISOString().slice(5, 10) === "04-01";

const SCENARIO: ProjectFolder[] = [
  {
    key: "wells",
    title: "Технологические показатели",
    icon: <DevelopmentIcon />,
    children: [
      {
        key: "wells",
        title: "Список скважин",
        element: <WellsList />,
      },
      {
        key: "interventions",
        title: "Программа ГТМ",
        element: <WellInterventions />,
      },
      {
        key: "prediction",
        title: "Прогноз добычи",
        element: <Debet />,
      },
      {
        key: "injection",
        title: "Прогноз закачки",
        element: <InjectionPrediction />,
      },
      {
        key: "interventions_op",
        title: "Операционные ГТМ",
        element: <OperatingGTM />,
      },
      {
        key: "development",
        title: "Параметры объектов разработки",
        element: <ProducingObjectsParams />,
      },
      {
        key: "balance",
        title: "Материальный баланс",
        element: null,
      },
      {
        key: "tech",
        title: "Данные по скважинам",
        element: <Tech />,
      },
      {
        key: "summary",
        title: "Сводка",
        element: <Summary />,
      },
    ],
  },
  {
    key: "ecy",
    title: "Экономические показатели",
    icon: <Economy />,
    children: [
      {
        key: "ecy",
        title: "Единые сценарные условия",
        element: <EcyParameters />,
      },
      {
        key: "reference",
        title: "Справочные параметры",
        element: <Reference />,
      },
      {
        key: "tax",
        title: "Налоговое окружение",
        element: <TaxParams />,
      },
      {
        key: "invest",
        title: IS_APRIL_FOOLS_DAY ? "Параметры инвестиционки" : "Параметры инвестиционной деятельности",
        element: <InvestParameters />,
      },
      {
        key: "operating",
        title: IS_APRIL_FOOLS_DAY ? "Параметры операционки" : "Параметры операционной деятельности",
        element: <OperatingParameters />,
      },
      {
        key: "wells-report",
        title: "Поскважинный расчет",
        element: <WellsReport />,
      },
      {
        key: "cashflow",
        title: "Денежные потоки",
        element: <Cashflow />,
      },
      {
        key: "invest-report",
        title: IS_APRIL_FOOLS_DAY ? "Инвестиционка" : "Инвестиционная деятельность",
        element: <InvestReport />,
      },
      {
        key: "operating-report",
        title: IS_APRIL_FOOLS_DAY ? "Операционка" : "Операционная деятельность",
        element: <OperatingReport />,
      },
      {
        key: "money",
        title: "Сводка по прогнозу",
        element: <SummaryForecast />,
      },
    ],
  },
  {
    key: "ranking",
    title: "Ресурсно-календарное планирование",
    icon: <Calendar />,
    children: [
      {
        key: "ranking",
        title: "Ранжирование ГТМ",
        element: <Ranking />,
      },
      {
        key: "queue",
        title: "Очередность проведения ГТМ",
        element: <Sequential />,
      },
      {
        key: "settings",
        title: "Настройки планирования",
        element: <Settings />,
      },
      {
        key: "schedule",
        title: "Результаты планирования",
        element: <RCPResults />,
      },
    ],
  },
  {
    key: "infrastructure",
    title: "Инфраструктура",
    icon: <InfrastructureIcon />,
    element: <Infrastructure />,
  },
];

const COMPARE: ProjectFolder = {
  key: "comparison",
  title: "Сравнение",
  icon: <Compare />,
  element: <Comparison />,
};

const REPORTS: ProjectFolder = {
  key: "reports",
  title: "Отчеты",
  icon: <Report />,
  children: [
    {
      key: "report-oil-state-plan",
      title: "ГОСПЛАН-нефть",
      element: <OilStatePlan />,
    },
    {
      key: "report-apg-state-plan",
      title: "ГОСПЛАН-газ",
      element: <ApgStatePlan />,
    },
    {
      key: "report-model",
      title: "Модель",
      element: <ModelReport />,
    },
    {
      key: "report-tep",
      title: "ТЭП ",
      element: <TepReport />,
    },
  ],
};

export { COMPARE, DEFAULT_PROJECT_PAGE, GENERAL, MANAGEMENT, REPORTS, SCENARIO };
export type { Page, ProjectFolder, ProjectFolderWithoutSubmenu, ProjectFolderWithSubmenu };
