import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { action, computed, makeObservable, observable, reaction } from "mobx";

import { UOM } from "elements/uom";
import { calculateDistance } from "features/infrastructure/infrastructureMap/utils";
import { global } from "models/global";
import { ECYStore } from "models/project/fact/ecyStore/ecyStore";
import { Forecast } from "models/project/fact/forecast/forecast";
import { Infrastructure } from "models/project/fact/infrastructure/infrastructure";
import { PipeType } from "models/project/fact/infrastructure/pipes";
import { TreeRoot } from "models/tree/tree";
import { MetricTree } from "services/finance/utils";

import { COMPARISON_TABLE_METRICS } from "./tableLayout";

type MetricsByForecast = {
  [forecastId: number]: {
    value: number | null;
    delta: number | null;
  };
};
type DRow = MetricsByForecast & {
  param: string;
  measure: string | null;
};

const segmentLength = (infrastructure: Infrastructure, data: PipeType) => {
  const getNode = (uuid: string) => infrastructure.nodes.at(uuid);
  const from = { x: getNode(data.from)?.x || 0, y: getNode(data.from)?.y || 0 };
  const to = { x: getNode(data.to)?.x || 0, y: getNode(data.to)?.y || 0 };
  return calculateDistance({ from, to, unit: "km" });
};

class CompareTableStore extends TableNode<DRow, Datum> {
  public readonly root = this;
  public selectedForecasts: Forecast[] = [];

  public currentUSCId: number;

  public favoriteScenario: number | null = null;

  constructor(public readonly tree: TreeRoot<Forecast>, public readonly forecasts: Forecast[], uscStore: ECYStore) {
    super();
    this.childrenStore = new ChildrenStoreArray(
      this,
      COMPARISON_TABLE_METRICS.map((row) => new Datum(this, row))
    );

    console.assert(uscStore.isLoading === false);
    this.currentUSCId = uscStore.first?.id ?? -1;

    makeObservable<CompareTableStore, "onTreeSelect">(this, {
      selectedForecasts: observable.ref,
      currentUSCId: observable,
      favoriteScenario: observable,

      onTreeSelect: action,
      setCurrentUSCId: action,
      onFavoriteSelect: action,

      pipesLength: computed,
      countStations: computed,
      power: computed,
    });

    reaction(
      () => tree.selectedItems,
      (selectedForecasts) => {
        this.onTreeSelect(selectedForecasts.map((node) => node.item).filter((id): id is Forecast => id !== null));
        if (
          this.favoriteScenario !== null &&
          this.selectedForecasts.find((fc) => fc.id === this.favoriteScenario) === undefined
        ) {
          this.favoriteScenario = null;
        }
      },
      { fireImmediately: true }
    );
  }

  public get countStations() {
    const countStations: Map<number, number | null | undefined> = new Map(); // <forecastId, count>

    this.forecasts.forEach((fc) => {
      const infrastructure = fc.infrastructure;
      if (infrastructure.isLoading) {
        countStations.set(fc.id, undefined);
        return;
      }

      countStations.set(fc.id, fc.infrastructure.nodes.data?.filter((node) => node.stationType === "НС").length);
    });
    return countStations;
  }

  public get pipesLength() {
    const pipesLength: Map<number, number | null | undefined> = new Map(); // <forecastId, sum>

    this.forecasts.forEach((fc) => {
      const infrastructure = fc.infrastructure;
      const pipes = infrastructure.pipes.data;
      if (pipes === undefined) {
        pipesLength.set(fc.id, undefined);
        return;
      }
      const newPipes = pipes.filter(({ category }) => category === "Новый объект");
      const data = newPipes.filter(({ segmentType }) => segmentType === "prod");
      const sum = data.map((data) => segmentLength(infrastructure, data)).reduce((prev, curr) => prev + curr, 0);
      pipesLength.set(fc.id, sum);
    });
    return pipesLength;
  }

  public get power() {
    const power: Map<number, number | null | undefined> = new Map(); // <forecastId, sum>

    this.forecasts.forEach((fc) => {
      const infrastructure = fc.infrastructure;
      if (infrastructure.isLoading) {
        power.set(fc.id, undefined);
        return;
      }
      const sum = fc.infrastructure.nodes.data
        ?.filter((node) => node.stationType === "НС")
        .map((data) => data.power)
        .reduce((prev, curr) => (prev ?? 0) + (curr ?? 0), 0);

      power.set(fc.id, sum);
    });
    return power;
  }

  public onFavoriteSelect = (id: number) => {
    if (this.favoriteScenario === id) {
      this.favoriteScenario = null;
    } else {
      this.favoriteScenario = id;
    }
  };

  private onTreeSelect(selectedForecastsIds: Forecast[]) {
    this.selectedForecasts = selectedForecastsIds;
  }

  public setCurrentUSCId = (uscId: number) => {
    this.currentUSCId = uscId;
  };
}

class Datum extends TableNode<DRow, Datum> {
  public get root(): CompareTableStore {
    return this.parent.root;
  }

  public readonly uom: UOM;

  constructor(private readonly parent: Datum | CompareTableStore, private readonly row: MetricTree) {
    super(parent, { isExpandedChildren: true });
    this.uom = new UOM(row.unit?.quantity ?? 0, row.unit?.measure ?? 0, global.uomResolver);

    makeObservable(this, {
      currentUSCId: computed,
      delta: computed,
      measure: computed,
    });

    if (row.children) {
      this.childrenStore = new ChildrenStoreArray(
        this,
        row.children?.map((row) => new Datum(this, row))
      );
    } else {
      this.childrenStore = null;
    }
  }

  get delta(): Map<number, number | null> {
    if (this.root.favoriteScenario !== null) {
      const favoriteResults = this.root.forecasts.find((forecast) => forecast.id === this.root.favoriteScenario)
        ?.cached?.[this.currentUSCId];
      if (favoriteResults === undefined) {
        return new Map();
      }
      return new Map(
        this.root.selectedForecasts.map((fc) => {
          if (fc.id === this.root.favoriteScenario || this.root.favoriteScenario === null) {
            return [fc.id, null];
          }

          if (this.row.title === "Общая протяженность новых трубопроводов") {
            if (
              this.root.pipesLength.get(fc.id) !== null &&
              this.root.pipesLength.get(this.root.favoriteScenario) !== null &&
              this.root.pipesLength.get(fc.id) !== undefined &&
              this.root.pipesLength.get(this.root.favoriteScenario) !== undefined
            ) {
              return [
                fc.id,
                this.root.pipesLength.get(fc.id)! - this.root.pipesLength.get(this.root.favoriteScenario)!,
              ];
            }
            return [fc.id, null];
          }
          if (this.row.title === "Количество новых насосных станций") {
            if (
              this.root.countStations.get(fc.id) !== null &&
              this.root.countStations.get(this.root.favoriteScenario) !== null &&
              this.root.countStations.get(fc.id) !== undefined &&
              this.root.countStations.get(this.root.favoriteScenario) !== undefined
            ) {
              return [
                fc.id,
                this.root.countStations.get(fc.id)! - this.root.countStations.get(this.root.favoriteScenario)!,
              ];
            }
            return [fc.id, null];
          }
          if (this.row.title === "Общая мощность новых насосных станций") {
            if (
              this.root.power.get(fc.id) !== null &&
              this.root.power.get(this.root.favoriteScenario) !== null &&
              this.root.power.get(fc.id) !== undefined &&
              this.root.power.get(this.root.favoriteScenario) !== undefined
            ) {
              return [fc.id, this.root.power.get(fc.id)! - this.root.power.get(this.root.favoriteScenario)!];
            }
            return [fc.id, null];
          }

          const result = fc.cached?.[this.currentUSCId];
          if (
            !result ||
            result.comparisonValues[this.row.title] === null ||
            favoriteResults.comparisonValues[this.row.title] === null
          ) {
            return [fc.id, null];
          }
          const delta = result.comparisonValues[this.row.title]! - favoriteResults.comparisonValues[this.row.title]!;
          return [fc.id, delta === 0 ? null : delta];
        })
      );
    }
    return new Map();
  }

  get currentUSCId(): number {
    return this.root.currentUSCId;
  }

  get measure(): string | null {
    if (this.row.title === "Количество новых насосных станций") {
      return "шт.";
    }
    return this.uom.unit;
  }

  asDRow = (): DRow => ({
    param: this.row.title,
    measure: this.measure,
    ...Object.fromEntries(
      this.root.selectedForecasts.map((fc) => {
        if (this.row.title === "Общая протяженность новых трубопроводов") {
          return [fc.id, { value: this.root.pipesLength.get(fc.id), delta: this.delta.get(fc.id) }];
        }
        if (this.row.title === "Количество новых насосных станций") {
          return [fc.id, { value: this.root.countStations.get(fc.id), delta: this.delta.get(fc.id) }];
        }
        if (this.row.title === "Общая мощность новых насосных станций") {
          return [fc.id, { value: this.root.power.get(fc.id), delta: this.delta.get(fc.id) }];
        }
        const result = fc.cached?.[this.currentUSCId];
        if (!result) {
          return [fc.id, { value: result, delta: null }];
        }
        return [fc.id, { value: result.comparisonValues[this.row.title] ?? null, delta: this.delta.get(fc.id) }];
      })
    ),
  });
}

export { CompareTableStore };
export type { DRow };
