import { action, computed, makeAutoObservable, observable, runInAction, when } from "mobx";

import { aggregateByDate } from "models/project/fact/production/aggregate";
import { sumUp } from "models/project/fact/production/aggregateFunctions";
import { ProductionDatum } from "models/project/fact/production/production";
import { BackendStorageMock } from "services/back/backendStorage";
import { getOperatingGTM, operatingTitleResolver } from "services/back/operatingGTM";
import { OperatingGTMDump } from "services/operatingGTM";
import { Range } from "utils/range";

import { Forecast } from "../forecast";

const operationGTMSource = (key: { fact: number; forecast?: number }) => {
  return new BackendStorageMock<void, OperatingGTMDump>(
    `OperatingGTMSource_${key.fact}_${key.forecast}`,
    key.fact,
    key.forecast
  );
};

class OperatingGTMParam {
  public title: string;
  public dataKey: string;
  public measure: string | null;
  public accuracy: number | null;
  public data: Map<number, number | null> | null;
  public children: Map<string, OperatingGTMParam> | null;
  constructor(
    title: string,
    dataKey: string,
    measure: string | null,
    accuracy: number | null,
    children: Map<string, OperatingGTMParam> | null,
    data: Map<number, number | null> | null
  ) {
    makeAutoObservable<OperatingGTMParam>(this);
    this.title = title;
    this.dataKey = dataKey;
    this.measure = measure;
    this.accuracy = accuracy;
    this.children = children;
    this.data = data;
  }

  get dump(): {
    dataKey: string;
    measure: string | null;
    accuracy: number | null;
    data: [number, number | null][] | null;
  } {
    return {
      dataKey: this.dataKey,
      measure: this.measure,
      accuracy: this.accuracy,
      data: this.data ? Array.from(this.data.entries()) : null,
    };
  }
}

class OperatingGTM {
  params: Map<string, OperatingGTMParam> = new Map();
  source: BackendStorageMock<void, OperatingGTMDump, OperatingGTMDump> | undefined;
  range: Range | undefined;
  isLoading: boolean = true;
  constructor(private forecast: Forecast) {
    makeAutoObservable<OperatingGTM, "init">(this, {
      range: observable,
      init: action,
      createTemplate: action,
      miningWells: computed,
      basedMining: computed,
      basicMining: computed,
    });
    this.range = new Range(this.forecast.range.from + 1, this.forecast.range.to);

    when(
      () => forecast.production.isLoading === false && forecast.fact.production.isLoading === false,
      () => this.init()
    );
  }

  private init = async () => {
    this.source = operationGTMSource(this.forecast.storageKey);

    await this.createTemplate(this.range ?? new Range(this.forecast.range.from + 1, this.forecast.range.to));
    await this.source.getItem().then((results) => {
      runInAction(() => {
        if (results && results.data.length > 0) {
          try {
            this.params.forEach((param) => {
              param.children?.forEach((child) => {
                if (child.dataKey !== "miningWells-1" && child.dataKey !== "pumpingWells-1") {
                  const resultData = new Map(
                    results.data
                      .find((resultParam) => resultParam.dataKey === param.dataKey)
                      ?.children.find((childParam) => childParam.dataKey === child.dataKey)?.data
                  );
                  if (resultData) {
                    Array.from(child.data?.keys() ?? []).forEach((year) => {
                      child.data?.set(year, resultData.get(year) ?? null);
                    });
                  }

                  child.children?.forEach((subChild) => {
                    const resultData = new Map(
                      results.data
                        .find((resultParam) => resultParam.dataKey === param.dataKey)
                        ?.children.find((childParam) => childParam.dataKey === child.dataKey)
                        ?.children?.find((subChildParam) => subChild.dataKey === subChildParam.dataKey)?.data
                    );
                    if (resultData) {
                      Array.from(subChild.data?.keys() ?? []).forEach((year) => {
                        subChild.data?.set(year, resultData.get(year) ?? null);
                      });
                    }
                  });
                }
              });
            });
          } catch {
            return;
          }
        } else {
          return;
        }
      });
    });
    this.isLoading = false;
  };

  public setData = (
    parentKey: string,
    paramKey: string,
    subParamKey: string,
    key: number | "accuracy",
    value: number | null
  ) => {
    if (parentKey === "otherGTM") {
      if (key === "accuracy") {
        try {
          this.params.get(parentKey)!.children!.get(paramKey)!.children!.get(subParamKey)!.accuracy = value;
        } catch {}
      } else {
        this.params.get(parentKey)?.children?.get(paramKey)?.children?.get(subParamKey)?.data?.set(key, value);
      }
    } else {
      if (key === "accuracy") {
        try {
          this.params.get(paramKey)!.children!.get(subParamKey)!.accuracy = value;
        } catch {}
      } else {
        this.params.get(paramKey)?.children?.get(subParamKey)?.data?.set(key, value);
      }
    }
  };

  public clear = async () => {
    await this.createTemplate(this.range ?? new Range(this.forecast.range.from + 1, this.forecast.range.to));
    await this.save();
    await this.init();
  };

  public async save() {
    if (this.source) {
      this.source.setItem({
        data: Array.from(this.params.values()).map((param) => ({
          measure: param.measure,
          dataKey: param.dataKey,
          data: null,
          children:
            param.dataKey === "otherGTM"
              ? Array.from(param.children!.values()).map((child) => ({
                  measure: child.measure,
                  dataKey: child.dataKey,
                  data: null,
                  children: Array.from(child.children!.values()).map((subChild) => subChild.dump),
                }))
              : Array.from(param.children!.values()).map((child) => child.dump),
        })),
      });
    }
  }

  public async createTemplate(range: Range) {
    await getOperatingGTM().then((results) => {
      runInAction(() => {
        if (results && results.length > 0) {
          this.params = new Map(
            results.map((param) => {
              return [
                param.dataKey,
                new OperatingGTMParam(
                  operatingTitleResolver(param.dataKey),
                  param.dataKey,
                  null,
                  null,
                  new Map(
                    param.children.map((child) => [
                      child.dataKey,
                      child.children
                        ? new OperatingGTMParam(
                            operatingTitleResolver(param.dataKey, child.dataKey),
                            child.dataKey,
                            null,
                            child?.accuracy ?? 3,
                            new Map(
                              child.children.map((subChildren) => [
                                subChildren.dataKey,
                                new OperatingGTMParam(
                                  operatingTitleResolver(param.dataKey, child.dataKey, subChildren.dataKey),
                                  subChildren.dataKey,
                                  subChildren.measure ?? null,
                                  subChildren?.accuracy ?? 3,
                                  null,
                                  new Map(
                                    new Array(range.length).fill(null).map((_, index) => [range.from + index, null])
                                  )
                                ),
                              ])
                            ),
                            null
                          )
                        : new OperatingGTMParam(
                            operatingTitleResolver(param.dataKey, child.dataKey),
                            child.dataKey,
                            child.measure ?? null,
                            child?.accuracy ?? 3,
                            null,
                            new Map(new Array(range.length).fill(null).map((_, index) => [range.from + index, null]))
                          ),
                    ])
                  ),
                  null
                ),
              ];
            })
          );
          const miningWells = this.params.get("miningWells")?.children?.get("miningWells-1")?.data;
          Array.from(miningWells?.keys() ?? []).forEach((year) => {
            miningWells?.set(year, this.miningWells.get(year)?.miningFond ?? null);
          });
          const pumpingWells = this.params.get("pumpingWells")?.children?.get("pumpingWells-1")?.data;
          Array.from(pumpingWells?.keys() ?? []).forEach((year) => {
            pumpingWells?.set(year, this.miningWells.get(year)?.injectionFond ?? null);
          });
        } else {
          console.error("Нет данных", results);
        }
      });
    });
  }

  get basicMining() {
    const params = ["optimization", "rir", "grp", "perforation", "otherOPZ", "otherGTM", "physic"];
    const dataSets: Map<number, number | null>[] = [];

    this.params.forEach((parentParam) => {
      if (params.includes(parentParam.dataKey)) {
        if (parentParam.dataKey === "otherGTM") {
          for (let [, param] of parentParam.children!.get("otherGTM-i")!.children!) {
            if (param.title === "Доп. добыча за год проведения ГТМ") {
              dataSets.push(param.data!);
            }
          }
        } else {
          if (parentParam.dataKey === "physic") {
            for (let [, param] of parentParam.children!) {
              if (param.title === "Доп. добыча за год проведения ФХ МУН") {
                dataSets.push(param.data!);
              }
            }
          } else {
            for (let [, param] of parentParam.children!) {
              if (param.title === "Доп. добыча за год проведения ГТМ") {
                dataSets.push(param.data!);
              }
            }
          }
        }
      }
    });

    return new Map(
      Array.from(this.basedMining.entries()).map(([year, record]) => {
        let value = record;
        if (value === null) {
          return [year, null];
        }
        dataSets.forEach((data) => {
          if (data.get(year) !== undefined && data.get(year) !== null) {
            value! -= data.get(year)!;
          }
        });
        return [year, Math.max(0, value)];
      })
    );
  }

  get basedMining(): Map<number, number | null> {
    const production = this.forecast?.production;
    const toAggregate = this.forecast.wells.allWells.map(({ id }) => production?.wholeWellData(id) ?? []);

    const sumByYear = aggregateByDate(sumUp, toAggregate);
    const result = Array.from(sumByYear, ([year, datum]) => ({ year: year.year(), value: datum.oil_prod })).filter(
      (record) => record.year >= this.forecast.range.from
    );
    return new Map(result.map((value) => [value.year, value.value]));
  }

  get miningWells() {
    const production = this.forecast?.production;
    const toAggregate = this.forecast.wells.allWells.map(({ id }) => production?.wholeWellData(id) ?? []);
    const aggFunc = (datums: ProductionDatum[]): { miningFond: number; injectionFond: number } => {
      const result = { miningFond: 0, injectionFond: 0 };

      for (const datum of datums) {
        if ((datum.inj_days ?? 0) > 0) {
          ++result.injectionFond;
        } else {
          result.miningFond += +((datum.prod_days ?? 0) > 0);
        }
      }

      return result;
    };
    const fond = aggregateByDate(aggFunc, toAggregate);
    return new Map([...fond].map((value) => [value[0].year(), value[1]]));
  }
}
export { OperatingGTM, OperatingGTMParam };
