import dayjs from "dayjs";

import { PRODUCTION_FIELDS, ProductionFields, StratumData as StratumDataRaw } from "services/back/production";
import { DateRange } from "utils/dateRange";

import { DateDataRow, DateDataSeries } from "./aggregate";
import { sumUp } from "./aggregateFunctions";
import type { ProductionDatum as Datum } from "./production";

type Status = "prod" | "inj" | "idle";

class StratumData {
  public readonly stratumId: number;
  public readonly range: DateRange;

  public readonly status: Status;
  private readonly data: Record<ProductionFields, (number | null)[]>;

  constructor(data: StratumDataRaw) {
    const { stratumId, status, start, end, ...rest } = data;
    this.status = status;
    this.stratumId = stratumId;
    this.range = extractRange(data);
    this.data = rest;
  }

  public *byMonth(): DateDataSeries<Datum> {
    let i = 0;
    for (const date of this.range.monthDates()) {
      yield [date, this.sliceByIndex(i)];
      ++i;
    }
  }

  public *byYear(): DateDataSeries<Datum> {
    const toSumUp = [];
    let currentYear = this.range.from.year();
    for (const [date, datum] of this.byMonth()) {
      if (date.year() !== currentYear) {
        yield [dayjs(`${currentYear}-01-01`), sumUp(toSumUp)];
        toSumUp.length = 0; // clear array
      }
      toSumUp.push(datum);
      currentYear = date.year();
    }
    yield [dayjs(`${currentYear}-01-01`), sumUp(toSumUp)]; // last year
  }

  private sliceByIndex(index: number): Datum {
    const result = {} as Datum;
    for (const field of PRODUCTION_FIELDS) {
      result[field] = this.data[field][index];
    }
    return result;
  }

  public get lastMonth(): DateDataRow<Datum> {
    const date = this.range.to;
    const datum = this.sliceByIndex(this.range.monthDuration - 1);
    return [date, datum];
  }

  public getForMonth(year: number, month: number): Datum | undefined {
    const index = this.range.index(year, month - 1); // TODO: fix months to 0-11 instead of 1-12
    if (index === undefined) {
      return undefined;
    }
    return this.sliceByIndex(index);
  }
}

function extractRange({ start, end }: StratumDataRaw): DateRange {
  const firstYear = start[0];
  const firstMonth = start[1];
  const lastYear = end[0];
  const lastMonth = end[1];
  const from = dayjs(`${firstYear}-${firstMonth}-01`);
  const to = dayjs(`${lastYear}-${lastMonth}-01`);
  return new DateRange(from, to);
}

export { StratumData };
