import type { FC } from "react";
import { observer } from "mobx-react";

import { Loader } from "elements/loader";

import { useTechForecastModel } from "../../../useTechForecastModel";

import { ChartScene } from "./chartScene";
import { Legend } from "./legend";

import cn from "./resultChart.module.less";

const ResultChart: FC = observer(() => {
  const model = useTechForecastModel();

  const { channels } = model.currentForecast;

  if (channels === null) {
    return <>there is not a base fond forecast</>;
  }
  return (
    <Loader wrapperClassName={cn.loader} tip={model.currentForecast.loaderTip} spinning={channels === undefined}>
      <div className={cn.layout}>
        <ChartScene className={cn.scene} />
        <Legend />
      </div>
    </Loader>
  );
});

export { ResultChart };
