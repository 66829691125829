import { FC } from "react";
import { ChartContext } from "@okopok/axes_context";
import { observer } from "mobx-react";

import { useTechForecastModel } from "features/techForecast/useTechForecastModel";

import { Line } from "./elements/line";
import { BoundsGroup } from "./boundsGroup";

const ChartScene: FC<{ className?: string }> = observer(({ className }) => {
  const model = useTechForecastModel();
  const chart = model.currentForecast.chart;

  return (
    <ChartContext className={className} axes={chart.axes}>
      {chart.lines.map((line) => (
        <Line key={line.key} lineInfo={line} />
      ))}
      <BoundsGroup />
    </ChartContext>
  );
});

export { ChartScene };
