import { FC, useMemo, useState } from "react";
import { TableContextProvider, Widget } from "@okopok/components/Table";
import { Button, DatePicker, Divider, InputProps, Radio, RadioChangeEvent, Tooltip } from "antd";
import locale from "antd/es/date-picker/locale/ru_RU";
import classNames from "classnames";
import dayjs from "dayjs";
import { observer } from "mobx-react";
import { SubmitButton } from "routing/outlines/submitButton/submitButton";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { FullScreenEmpty } from "elements/fullScreen/fullScreen";
import { Icon } from "elements/icon/icon";
import { Select } from "elements/inputs/select";
import { ValidationInput } from "elements/inputs/validationInput/validationInput";
import { Loader } from "elements/loader";
import { useForecast } from "models/project/fact/forecast/forecast";
import { CalculateStore } from "models/project/fact/infrastructure/calculateStore";
import { useProject } from "models/project/project";
import { useTableSettings } from "models/tableSettings";
import { getUserPermission } from "services/back/roles";

import "dayjs/locale/ru";

import { CalculateButton } from "../calculateButton/calculateButton";
import { useInfrastructure } from "../useInfrastructure";

import { PipesModel } from "./dinamicTable/tableManager/pipes";
import { useDinamicTableManager } from "./dinamicTable/tableManager/useDinamicTableManager";
import { useInfrastructureTableModel } from "./staticTable/infrastructureTableManager/useInfrastructureTableModel";
import { ReactComponent as ResetIcon } from "./reset.svg";
import { ReactComponent as WarningIcon } from "./warning.svg";

import cn from "./infrastructureTable.module.less";

const { RangePicker } = DatePicker;

type InputCustomType = {
  title?: string;
  value?: number | null;
  max?: number;
  min?: number;
  onUpdate: (value: number | null) => void;
} & Omit<InputProps, "value" | "min" | "max">;

const InputCustom: FC<InputCustomType> = ({ title, value, max = 100000, min = 0, onUpdate, ...props }) => (
  <div className={cn.inputCustomWrapper}>
    {!!title && <div>{title}</div>}
    <ValidationInput {...props} value={value} onSave={onUpdate} min={min} max={max} forceValueUpdate />
  </div>
);

const SubFrameTitle: FC<{ store: CalculateStore }> = observer(({ store }) => {
  if (store.hydraulicDataCollection === null) {
    return <></>;
  }
  return (
    <div className={cn.subFrameTitle}>
      <RangePicker
        className={cn.date}
        locale={locale}
        placeholder={["Начало", "Конец"]}
        picker="month"
        value={store.userFilterParams?.date}
        onChange={([start, end]) => store.setUserFilterParams({ date: [dayjs(start), dayjs(end)] })}
      />
      <Button icon={<Icon width="16px" height="16px" viewBox="0 0 16 16" content={<ResetIcon />} />} onClick={store.resetUserFiterDate} />
      <Divider type="vertical" />
      <InputCustom
        title="Макс. градиент давления"
        className={cn.inputMax}
        placeholder="500 МПа/км"
        type="number"
        value={store.userFilterParams?.pressureGradient ?? undefined}
        onUpdate={(value) => store.setUserFilterParams({ pressureGradient: value })}
      />
      <InputCustom
        title="Макс. скорость потока"
        className={cn.inputMax}
        placeholder="1 м/сек"
        value={store.userFilterParams?.velocity ?? undefined}
        onUpdate={(value) => store.setUserFilterParams({ velocity: value })}
      />
      <Button icon={<Icon width="16px" height="16px" viewBox="0 0 16 16" content={<ResetIcon />} />} onClick={store.resetUserFiterInputs} />
    </div>
  );
});

const usePipeTypeOptions = (regime: "params" | "results") =>
  useMemo(
    () => [
      { label: "Нефтепровод", value: "prod" },
      { label: "Водовод", value: "inj" },
      { label: "Кустовые площадки", value: "mines" },
      { label: "НС/УПН/УПСВ/УКПГ", value: "stations", disabled: regime === "results" },
      { label: "Стоки", value: "drain" },
      { label: "Источники", value: "source" },
    ],
    [regime]
  );

const regimeOptions = [
  { label: "Параметры", value: "params" },
  { label: "Результаты", value: "results" },
];

const InfrastructureTable = observer(() => {
  const [mode, setMode] = useState("prod");
  const [regime, setRegime] = useState<"params" | "results">("params");

  const infrastructure = useInfrastructure();
  const forecast = useForecast();
  const { store: staticStore, model: staticModel } = useInfrastructureTableModel(mode);
  const { store: dinamicStore, model: dinamicModel } = useDinamicTableManager(mode);
  const pipeTypeOptions = usePipeTypeOptions(regime);

  const [store, model] = regime === "params" ? [staticStore, staticModel] : [dinamicStore, dinamicModel];

  const handleSelect = (value: string) => setMode(value);

  const handleRegime = (event: RadioChangeEvent) => setRegime(event?.target.value);

  const project = useProject()!;
  const edit = getUserPermission(project)["infrastructure"];

  if (store.isLoading) {
    return <Loader />;
  }

  useTableSettings(model, `infrastructure_table_${regime}`);

  return (
    <TableContextProvider value={model}>
      <PageFrameTitlePortal model={model}>
        <div className={cn.actionButtons}>
          {dinamicStore instanceof PipesModel && regime === "results" && (
            <Tooltip title="Показывать только проблемные результаты расчета">
              <Button
                className={classNames(cn.warning, dinamicStore.hasProblemDataFilter && cn.warningProblem)}
                onClick={dinamicStore.toggleProblemPipesFilter}
                icon={<Icon width="20px" height="20px" viewBox="0 0 20 20" content={<WarningIcon />} />}
              />
            </Tooltip>
          )}
          <Select value={mode} options={pipeTypeOptions} onChange={handleSelect} />
          <Radio.Group value={regime} options={regimeOptions} buttonStyle="solid" optionType="button" onChange={handleRegime} className={cn.regime} />
          {forecast && <CalculateButton fullWidth />}
          {regime === "params" && <SubmitButton model={store} onSubmit={infrastructure.save} edit={edit} />}
        </div>
      </PageFrameTitlePortal>
      {regime === "results" && <SubFrameTitle store={infrastructure.calculateStore} />}
      {store.childrenStore && store.childrenStore.size === 0 ? (
        <FullScreenEmpty>Нет данных для отображения</FullScreenEmpty>
      ) : (
        <Widget headerClassName={cn.tableHeader} />
      )}
    </TableContextProvider>
  );
});

export { InfrastructureTable };
