import { useCallback, useRef } from "react";

import { useModal } from "elements/modal/modal";
import { Analogs } from "features/techForecast/models/well/analogs";

import { WellsAnalogsModal } from "./wellsAnalogsModal";

const useAnalogSelector = (analogs: Analogs) => {
  const showModal = useModal<void, Analogs>();
  const dataRef = useRef<Analogs>(analogs);
  dataRef.current = analogs;

  return useCallback(
    () => showModal(WellsAnalogsModal, dataRef, "Выбор аналогов", "Применить", "Отмена", "80vw", undefined, { padding: 0 }),
    [showModal]
  );
};

export { useAnalogSelector };
