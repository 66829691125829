import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { computed, makeObservable } from "mobx";

import { Forecast } from "models/project/fact/forecast/forecast";
import { Well } from "models/project/fact/well/well";
import { VoronoiGrid } from "services/back/injectionPrediction/voronoi";

type DRow = {
  id?: number; // debug column
  prodWellTitle?: string;
  prodMineTitle?: string;
  prodProducingObjectTitle?: string;
  liquidRate?: number;
  oilRate?: number;

  injWellTitle?: string;
  injMineTitle?: string;
  injCapacity?: number;

  oilCoeff?: number;
};

class InjNode extends TableNode<DRow> {
  asDRow(): DRow {
    if (!this.well) {
      return {};
    }
    return {
      id: this.well.id,
      injWellTitle: this.well.title,
      injMineTitle: this.mineTitle,
      injCapacity: this.injCapacity,
      oilCoeff: this.oilCoeff,
    };
  }

  public readonly well: Well | null;
  public readonly mineTitle?: string;
  public readonly injCapacity?: number;

  constructor(
    public readonly parent: ProductionNode,
    public readonly wellId: number,
    public readonly oilCoeff: number
  ) {
    super(parent);
    this.childrenStore = null;

    this.well = this.parent.parent.forecast.wells.at(this.wellId);

    if (this.well) {
      this.mineTitle = this.well.pad?.title;
      const prodDatum = this.parent.parent.forecast.production.wellData(this.well.id)?.getForMonth(...this.currentDate);
      this.injCapacity =
        (prodDatum?.inj_days && ((prodDatum?.water_inj ?? 0) / prodDatum.inj_days) * 1000) ?? undefined;
    }
  }

  public get currentDate(): [year: number, month: number] {
    return this.parent.currentDate;
  }
}

class ProductionNode extends TableNode<DRow, InjNode> {
  asDRow(): DRow {
    if (!this.well) {
      return {};
    }
    return {
      id: this.well.id,
      prodWellTitle: this.well.title,
      prodMineTitle: this.mineTitle,
      prodProducingObjectTitle: this.producingObjectTitle,
      oilRate: this.oilRate, // tonn/day
      liquidRate: this.liquidRate, // m3/day
    };
  }

  public injNodes: InjNode[];

  public readonly well: Well | null;
  public readonly mineTitle?: string;
  public readonly producingObjectTitle?: string;
  public readonly oilRate?: number;
  public readonly liquidRate?: number;

  constructor(
    public readonly parent: ProductionSlice,
    public readonly wellId: number,
    public readonly injWells: Array<[id: number, coef: number]>
  ) {
    super(parent, { isExpandedChildren: true });
    this.injNodes = injWells
      .map(([id, coef]) => new InjNode(this, id, coef))
      .filter((node) => node.injCapacity !== undefined);
    this.childrenStore = new ChildrenStoreArray(this, this.injNodes);

    this.well = this.parent.forecast.wells.at(this.wellId);

    if (this.well) {
      this.mineTitle = this.well.pad?.title;
      this.producingObjectTitle = this.well.producingObject?.title;
      const prodDatum = this.parent.forecast.production.wellData(this.well.id)?.getForMonth(...this.currentDate);
      if (prodDatum?.prod_days) {
        this.oilRate = ((prodDatum.oil_prod ?? 0) / prodDatum.prod_days) * 1000;
        this.liquidRate = ((prodDatum.liquid_prod ?? 0) / prodDatum.prod_days) * 1000;
      }
    }
  }

  public get currentDate(): [year: number, month: number] {
    return this.parent.currentDate;
  }
}

class ProductionSlice extends TableNode<DRow, ProductionNode> {
  public get currentDate(): [year: number, month: number] {
    const date = this.forecast.injectionResults.currentDate!;
    const month = date.month() + 1;
    const year = date.year();
    return [year, month];
  }

  public prodNodes: ProductionNode[];

  constructor(public readonly forecast: Forecast, private voronoi: VoronoiGrid) {
    super();
    makeObservable(this, {
      currentDate: computed,
    });

    let { coefMap } = voronoi;
    coefMap = invertCoeffsMap(coefMap);
    this.prodNodes = [...Object.entries(coefMap)].map(
      ([prodId, injs]) =>
        new ProductionNode(
          this,
          parseInt(prodId),
          [...Object.entries(injs)].map(([prodId, coeffs]): [number, number] => [parseInt(prodId), coeffs.inj])
        )
    );
    this.childrenStore = new ChildrenStoreArray(this, this.prodNodes);
    this.childrenStore.sort((a, b) =>
      (a.well?.title ?? "").localeCompare(b.well?.title ?? "", undefined, { numeric: true, sensitivity: "base" })
    );
  }
}

type CoefMap = {
  [baseId: number]: {
    [relatedId: number]: {
      prod: number;
      inj: number;
    };
  };
};

function invertCoeffsMap(coefMap: CoefMap): CoefMap {
  const result: CoefMap = {};
  for (const [baseId, related] of Object.entries(coefMap)) {
    for (const [relatedId, coefs] of Object.entries(related)) {
      if (result[relatedId as any] === undefined) {
        result[relatedId as any] = {};
      }
      result[relatedId as any][baseId as any] = coefs;
    }
  }
  return result;
}

export { type DRow, ProductionSlice };
